// CreditCardFields
//
//  desc:
//    groups of fields required for CC processing
//
//  props:
//    formdata:             (required) form data
//      ccnum:                    (optional) value for the cc component
//      isCCValid:                (optional) passed into the cc component to set error state
//      exp:                      (optional) value for the exp component
//      isExpValid:               (optional) passed into the exp component to set error state
//      securityCode:             (optional) value for the security code component
//      isSecurityCodeValid:      (optional) passed into the secuirty code component to set error state
//    onDataChange:         (optional) a callback function to update model for form data
//    onValidate:           (optional) a callback function to validate the form data
//

import React, { Component } from 'react';
import CreditCard, {CreditCardSmartIcon, CCValidator, GetCardType, GetCardTypeName} from '../../CreditCard';
import ExpirationDateInput,
        { futureDate, validateFutureDate, validateExpirationDate } from '../../ExpirationDateInput';
import SecurityCodeInput, 
        { validateSecurityCode } from '../../SecurityCodeInput';
import {Validation, Rule} from '../../Utility';
import { PropTypes } from 'prop-types';
import FormInput from '../../FormInput';


class CreditCardFields extends Component {
  constructor(props){
    super(props);
    this.state = {
      editing: false,
      isCCValidVisited: false,
      isExpDateValidVisited: false,
      isSecurityCodeValidVisited: false,
    }

    this.onBillingCreditCardNumberChange = this.onBillingCreditCardNumberChange.bind(this);
    this.onBillingCreditCardExpChange = this.onBillingCreditCardExpChange.bind(this);
    this.onBillingCreditCardSecurityCodeChange = this.onBillingCreditCardSecurityCodeChange.bind(this);

    //this.validateExpirationDate = this.validateExpirationDate.bind(this);
    //this.validateCardNumber = this.validateCardNumber.bind(this);
    this.validateSecurityCode = this.validateSecurityCode.bind(this);
  }

  onBillingCreditCardNumberChange(value) {
    if (this.props.onBillingCreditCardNumberChange) {
      this.props.onBillingCreditCardNumberChange(value);
    }
  }
  
  onBillingCreditCardExpChange(value) {
    if (this.props.onBillingCreditCardExpChange) {
      this.props.onBillingCreditCardExpChange(value);
    }
  }

  onBillingCreditCardSecurityCodeChange(value) {
    if (this.props.onBillingCreditCardSecurityCodeChange) {
      this.props.onBillingCreditCardSecurityCodeChange(value);
    }
  }

  // just for UI updating validation on blur
  validateSecurityCode() {
    this.setState({
      isSecurityCodeValidVisited: true
    })
  }
  
  renderCreditCardField() {
    const autoFocus = this.props.autoFocus;
    const ccnum = this.props.formdata.cc;

    var transformCCTypes = {
      'VS': 'VISA',
      'MC': 'MASTERCARD',
      'DC': 'DINERS-CLUB',
      'DS': 'DISCOVER',
      'JCB': 'JCB',
      'AM': 'AMERICAN-EXPRESS',
    }
    // credit card types merchant is allowed to process.
    var allowedCCTypes = [
      transformCCTypes[this.props.formdata['CT1']],
      transformCCTypes[this.props.formdata['CT2']],
      transformCCTypes[this.props.formdata['CT3']],
      transformCCTypes[this.props.formdata['CT4']],
      transformCCTypes[this.props.formdata['CT5']],
      transformCCTypes[this.props.formdata['CT6']],
    ].filter(Boolean);

    return (
      <CreditCard
                name='cc'
                validations={ {
                  isCCValid: function(values, value) {
                    return CCValidator.valid(value);
                  },
                  isCCTypeAllowed: function(values, value) {
                    const brains = GetCardType(value).toUpperCase().trim();
                    return allowedCCTypes.includes(brains); 
                  }
                } }
                validationErrors={ {
                  'isCCValid': "Invalid credit card number",
                  'isCCTypeAllowed': "Merchant does not allow this credit card type",
                  "isDefaultRequiredValue":"This field is required",
                } }
                required
                maxLength={19}
                  autoFocus={ autoFocus }
                  id="cc"
                  value={ ccnum }
                  //isValid={ this.props.isCCValid || !this.state.isCCValidVisited }
                  onChange={ this.onBillingCreditCardNumberChange }
                  //onValidate={ this.validateCardNumber }
      /> 
    )
  }
  
  renderExpirationField() {
    const autoFocus = this.props.autoFocus;
    const exp = this.props.formdata.exp;

    return (
      <ExpirationDateInput
            name="exp"
            validations={ {
              isExpDateValid: function(values, value) {
                return validateExpirationDate(value);
              }
            } }
            validationErrors={ {
              'isExpDateValid': 'Invalid expiration date',
              "isDefaultRequiredValue":"This field is required"
            } }
            required
                id="exp"
                value={ exp || '' }
                //isValid={ this.props.isExpDateValid || !this.state.isExpDateValidVisited }
                onChange={ this.onBillingCreditCardExpChange }
                //onValidate={ this.validateExpirationDate }
                title="Expiration Date"
                maxLength="5"
                tooltipText="CreditCard expiration date" 
      />
    )
  }

  // just for UI updating validation on blur
  //validateExpirationDate(validation) {
  //  this.setState({
  //    isExpDateValidVisited: true
  //  })
  //}
  
  // just for UI updating validation on blur
  //validateCardNumber(validation) {
  //  this.setState({
  //    isCCValidVisited: true
  //  })
  //}
  
  render() {

    const securityCode = this.props.formdata.securityCode;

    return (
      <div>
      { 
        this.props.editable ? <div className="input-group-append" style={{float: 'right'}}>
          <a onClick={()=>this.setState({editing: !this.state.editing})}>
            {this.state.editing ? "cancel" : "edit"}
          </a>
        </div> : null
      }

      {
        this.renderCreditCardField()
      }

        <div className='side-by-side' style={{clear:'both'}}>
          <div className='control'>

          {
            this.renderExpirationField()
          }

          </div>
          <div className='control'>
          { 
            this.props.hideSecurityCode ? null
              : <SecurityCodeInput
                        name='securityCode'
                        validations={ {
                          validateSecurityCode: function(values, value) {
                            return validateSecurityCode(value);
                          }
                        } }
                        validationErrors={ {
                          'validateSecurityCode': 'Invalid security code',
                          "isDefaultRequiredValue":"This field is required"
                        } }
                            id="securityCode"
                            value={ securityCode || '' }
                            required
                            //isValid={ this.props.isSecurityCodeValid || !this.state.isSecurityCodeValidVisited }
                            onChange={ this.onBillingCreditCardSecurityCodeChange }
                            //onVisited={ this.validateSecurityCode }
                            title="Security Code"
                            placeholderText= ""
                            textBoxWidth="100%"
                            tooltipText="3-4 digit code usually found on the back of your card" />
          }
          </div>
        </div>

      </div>
    );
  }
}

CreditCardFields.propTypes = {
  // when set will make the input field for name visible to update for update profile form
  nameoncard: PropTypes.string,
  // when set to true will make component a update profile form
  editable: PropTypes.bool,
  // just what it says
  hideSecurityCode: PropTypes.bool,
  formdata: PropTypes.object.isRequired,
}
export default CreditCardFields;
