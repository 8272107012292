import React, { Component } from 'react';
import Formsy from 'formsy-react';
import PropTypes from 'prop-types';


// Wrapper component for the formsy library.
// formsy component handles validating the input field values.
// It also maintains the form data in its state but because
// of how the app is designed the form data is not kept in memory
// when navigating from one route to another.
// TODO: We should fix the app to work this way.
// we could also store the state in this wrapper component for example
// as implemented in the onChange method. For now not implementing that. 
// Just using the validation portion of the library.
// Except for updating customer profile. It uses the form correctly.
class Form extends Component {
  constructor(props){
    super(props);
    this.state = {};
    this.form = React.createRef();
    // hacky to initialize global hash to store references to form fields
    // so we can scroll to and focus on fields with errors. There should be a better way but watever.
    window.formFields = {};
    this.onChange =this.onChange.bind(this);
    this.onValidSubmit = this.onValidSubmit.bind(this);
  }

  onSubmit(model, resetFn, updateInputsWithErrorFn) {
  }

  onValidSubmit(model, resetFn, updateInputsWithErrorFn) {
    // note if buttons are in the form and click event fires
    // and event bubbling is enabled, this gets called.
    if (this.props.onValidSubmit) {
      this.props.onValidSubmit(model);
    }
  }

  // Currently the form data is stored in top level payment link component.
  // It should not be there. For now also storing the copy of the data in
  // this form component. TODO we should change it so the form is submitted from here.
  onChange(currentValues, isChanged) {
    if (isChanged) {
      const newItem = Object.assign({}, this.state, currentValues);
      this.setState(newItem);
      // console.log("new model", newItem)
    }
  }

  render() {
    return (
      <Formsy ref={this.form}
              onChange={this.onChange}
              onSubmit={this.onSubmit} 
              onValidSubmit={this.onValidSubmit}
              onValid={this.props.onValid}
              onInvalid={this.props.onInvalid}
              noValidate={true}
      >
        {this.props.children}
      </Formsy>
    );
  }
}

Form.propTypes = {
  onValidSubmit: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
  onInvalid: PropTypes.func.isRequired,
}

export default Form;
