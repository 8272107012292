/*
  Name:
  Footer

  Description:
  The footer
*/
import React, { Component } from 'react';

import './footer.css';


class Footer extends Component {
  constructor(props){
    super(props);
  }
  
  render() {
    let currentYear = new Date().getFullYear();
    // no print not implemented right. good enough for now.
    return (
      <div className='paymentform-footer no-print row justify-content-center'>
        <ul className='nav'>
          <li className='nav-item'>
            <div className='footer-nav-link'>
              Copyright 2004-{currentYear} PayTrace, Inc. All Rights Reserved.
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

export default Footer;
