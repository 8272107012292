// ZipCodeInput
//
//  desc:
//    provides a title and a zip code input box (with optional placeholder text)          
//
//  props:
//    data:                   (required) form data
//      customFrequencyCount:       (optional) bill every # (days, months, years, weeks)
//      durationLimit:              (optional) value to run recurring how many times
//    onDataChange:           (optional) a callback to update data model
//    onValidate:             (optional) call back to do validation

import React, { Component } from 'react';
import FormInput from '../../FormInput';
import SectionText from '../../SectionText';
import {ListItem, default as FrequencyDropDownList} from '../../DropDown/list';
import DurationInput from '../../DurationInput';


// note start data has to happen after the frequency to be valid
export const FrequencyList = {
  '1': 'Annually',
  '8': 'Semi-Annually',
  'A': 'Trimestery',
  '2': 'Quarterly',
  '9': 'Bi-Monthly',
  '3': 'Monthly',
  '4': 'Bi-Weekly',
  '7': '1st and 15th', // start date would have to be the 1st or 15th for this one.
  '5': 'Weekly',
  '6': 'Daily',
}


class RecurringPayment extends Component {
  constructor(props){
    super(props);
    
    this.state = {
      isValidFrequency: true,
      numberOfPaymentsVisited: false,
    }
    this.onNumberOfPaymentsVisited =  this.onNumberOfPaymentsVisited.bind(this);
    this.onDurationChange = this.onDurationChange.bind(this);
    this.onFrequencyChange = this.onFrequencyChange.bind(this);
    this.onCustomFrequencyChange = this.onCustomFrequencyChange.bind(this);
    this.onNumberOfPaymentsChange = this.onNumberOfPaymentsChange.bind(this);
    
    this.validateNumberOfPayments = this.validateNumberOfPayments.bind(this);
    this.validateFrequency = this.validateFrequency.bind(this);
    this.validateDuration = this.validateDuration.bind(this);
  }

  validateNumberOfPayments(val) {
    return (parseInt(val) > 0 && parseInt(val) < 999) || !this.state.numberOfPaymentsVisited;
  }

  validateFrequency() {
  }

  validateDuration() {
  }

  onNumberOfPaymentsVisited() {
    this.setState({
      numberOfPaymentsVisited: true,
    });
  }

  onFrequencyChange(index) {
    if (this.props.onFrequencyChange) {
      this.props.onFrequencyChange(index);
    }
  }

  onDurationChange(duration) {
    if (this.props.onDurationChange) {
      this.props.onDurationChange(duration);
      // reset number of payment value to empty if toggled to continual.
      this.props.onNumberOfPaymentsChange(null);
    }
  }

  onNumberOfPaymentsChange(value) {
    if (this.props.onNumberOfPaymentsChange) {
      this.props.onNumberOfPaymentsChange(value);
    }
  }
  
  renderFrequency() {
    return (
      <FrequencyDropDownList 
            name='frequency'
            validations={ {
              validateFrequency: function(values, value) {
                return true;
              }
            } }
            validationErrors={ {
              'validateFrequency': 'Invalid frequency',
              "isDefaultRequiredValue":"This field is required"
            } }
            required
            // isValid={ this.state.isValidFrequency }
            // onValidate={ this.validateFrequency } 
            onDataChange={ this.onFrequencyChange }
            items={FrequencyList} value={this.props.data.frequency}
      />
    )
  }

  renderDurationFields() {
    if (!this.props.data.frequency) return null;

    const durationText = 'Continued payments will be billed until canceled by the customer';
    
    return (
      <div className="row">
        <div className="col-12">
          <DurationInput
            name='duration'
            value={this.props.data.duration}
            onDurationChange={this.onDurationChange}
            label={<SectionText title="Duration" content={ durationText }/>}
          />
        </div>
      </div>
    )
  }

  renderNumberOfPaymentFields() {
  
    if (this.props.data.duration == 'limited') {
      const isDurationLimitValid = this.validateNumberOfPayments(this.props.data.numberOfPayments);
  
      const numOfPaymentText = "The Pay On date you have selected above will be the first payment of the cycle";
      return (
        <div>
          <FormInput
            name='numberOfPayments'
            inputType='number'
            label={<SectionText title="# of Payments" content={ numOfPaymentText }/>}
            validations={{
              greaterThanZero: function(values, value) {
                return value > 0 && value < 999;
              }
            }}
            validationErrors={{
              'greaterThanZero': 'Invalid value',
              "isDefaultRequiredValue":"This field is required"
            }}
            inputRestricted={(val) => {
              var numbersOnly = new RegExp('^\\d+$');
              return (parseInt(val) < 0 || parseInt(val) > 998 ||  (val.length > 0 && !numbersOnly.test(val)) );  
            }}
            required
            //isValid={ isDurationLimitValid }
            placeholder="ex. 12"
            onChange={ this.onNumberOfPaymentsChange }
            //onBlur={ this.onNumberOfPaymentsVisited }
            value={ this.props.data.numberOfPayments || '' } />
          <label>If you would like over 998 payments, please select Duration of Continual above.</label>
        </div>
      )
    }
    return null;
  }

  // custom frequency not used yet
  renderCustomFrequencyFields(frequency, period, validation) {
    // frequency 11 is custom so show custom frequency fields
    // don't like how its hard code 11.
    if (frequency == '11') {
      const isPaymentCustomFrequencyCountValid = validation.valid('PaymentCustomFrequencyCount')
      // frequencyCountValid = this.props.data.customFrequencyCount.valid;
  
      return (
        <div>
          <div style={{width: '50%', marginTop: '25px', paddingRight:'10px'}}>
            <FormInput
                name='recur_frequency'
                inputType='number'
                isValid={ isPaymentCustomFrequencyCountValid }
                placeholder="ex. 1"
                id="payment_customFrequencyCount" 
                name="payment[customFrequencyCount[value]]"
                onChange={ this.onDataChange }
                value={ this.data('customFrequencyCount') }
                label='Bill Every'
            />
          </div>
          <ul className='horizontal' style={{marginTop: '15px'}}>
            <li>
              <button className='flat' onClick={(evt) => this.onCustomFrequencyChange('day')}
                      style={{height: '50px', width: '87px', marginRight: '5px', paddingRight: '20px'}}>
                <div><input readOnly checked={period == 'day'} name='timeperiod' type='radio' style={{margin: '5px'}}/>Day(s)</div>
              </button>
            </li>
            <li>
              <button className='flat' onClick={(evt) => this.onCustomFrequencyChange('week')}
                      style={{height: '50px', width: '87px', marginRight: '5px', paddingRight: '20px'}}>
                <div><input readOnly checked={period == 'week'} name='timeperiod' type='radio' style={{margin: '5px'}}/>Week(s)</div>
              </button>
            </li>
            <li>
              <button className='flat' onClick={(evt) => this.onCustomFrequencyChange('month')}
                      style={{height: '50px', width: '87px', marginRight: '5px', paddingRight: '20px'}}>
                <div><input readOnly checked={period == 'month'} name='timeperiod' type='radio' style={{margin: '5px'}}/>Month(s)</div>
              </button>
            </li>
            <li>
              <button className='flat' onClick={(evt) => this.onCustomFrequencyChange('year')}
                      style={{height: '50px', width: '87px', paddingRight: '20px'}}>
                <div><input readOnly checked={period == 'year'} name='timeperiod' type='radio' style={{margin: '5px'}}/>Year(s)</div>
              </button>
            </li>
          </ul>
        </div>
      )
    }
    return null;
  }

  // toggle between the days, week, months, and year radio buttons.
  // custom frequency not used yet
  onCustomFrequencyChange(frequency) {
    if (this.props.onFrequencyChange) {
      this.props.onFrequencyChange(frequency)
    }
  }

  render() {

    return (
      <div className='row recurring-payment'>

        <div>
        {
          this.renderFrequency()
        }
        </div>

        <div>
        {
          this.renderDurationFields()
        }
        </div>

        <div>
        {
          this.renderNumberOfPaymentFields()
        }
        </div>

      </div>
    );
  }
}

export default RecurringPayment;
