// SecurityCodeInput
//
//  desc:
//    security control component
//
//  props:
//    value:                (optional) security code
//    id:                   (required) used to update the data model
//    name:                 (optional) name of control
//    title:                (optional) label of control
//    placeholderText:      (optional) if provided, will display ghosted placeholder text in the input box. Note: there is no placeholder text by default
//    textBoxWidth:         (optional) width
//    tooltipText:          (optional) tooltip
//    onChange:             (optional) a callback function to update the data model
//    onValidate:           (optional) a callback function to validate input

import React, { Component } from 'react';
import { withFormsy } from 'formsy-react';
import './SecurityCodeInput.css';

  
export const validateSecurityCode = function(securityCode) {
  return /^[0-9]{3,4}$/.test(securityCode) && securityCode !== undefined && securityCode != ''; 
}

class SecurityCodeInput extends React.PureComponent {
  constructor(props)
  {
    super(props);
    this.state = {};
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }
  
  componentWillUnmount()
  {
    if (this.props.onChange) {
     this.props.onChange(this.props.getValue());
    }
  }
  
  getPlaceholderText()
  { //defaults to empty string if no prop provided
    return this.props.placeholderText != null ? this.props.placeholderText : "" ;
  }
  
  getTextBoxWidth()
  { 
    //defaults to 50 if no prop provided
    return this.props.textBoxWidth != null ? this.props.textBoxWidth : "50%" ;
  }

  getTooltipText()
  { //defaults to empty string if no prop provided
   return this.props.tooltipText != null ? this.props.tooltipText : ""
  }
  
  handleChange(event)
  {
    //const number = (event.target.value).trim();
    const number = event.currentTarget.value;
    // input restriction
    if (/^\d{0,4}$/.test(number)) {
      this.props.setValue(number);
    }
  }
   
  handleBlur(event)
  {
    const errorMessage = this.props.getErrorMessage();
    this.setState({
      errorMessage: errorMessage,
    });   
    if (this.props.isValid()) {
      event.currentTarget.setCustomValidity('');
    } else {
      event.currentTarget.setCustomValidity(errorMessage);
    }
  }

  getInputClasses()
  {
    var retClass = 'SecurityCodeInput';
    if (!this.props.isValid )
    {
      retClass += ' SecurityCodeInput_SecurityCodeError'
    }
    return retClass;
  }

  showErrorMessage() {
    return this.state.errorMessage ? 
      <span className='field-error'>({ this.state.errorMessage })</span> :
      null;
  }

  render() {
    const securityCode = this.props.getValue() || '';
    const id = this.props.id;
    const name = this.props.name;
    const requiredColor = this.props.isValid() ? null : { color: 'red' }
    const requiredMark = this.props.required ? <span className='required-mark' style={ requiredColor }>*</span> : null;
    return (
      <div className = {this.getInputClasses()}>
        <div>
          <label htmlFor={id}>{this.props.title}</label>
          {requiredMark}
          {this.showErrorMessage()}
          <span style={{marginLeft:'10px'}}><a href = "https://www.cvvnumber.com/cvv.html" target="_blank"><i className="fas fa-info-circle fa-lg" style={{color: 'grey' }}></i></a></span>
        </div>
        <input
            id={id}
            //name={name}
            style={ { width:this.getTextBoxWidth() } }
            type="text"
            maxLength="4"
            value={securityCode}
            placeholder={this.getPlaceholderText()}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            //name={this.props.name}
            title={this.getTooltipText()}
        />
      </div>
    );
  }
}

export default withFormsy(SecurityCodeInput);
