/*
  PaymentLink

  Layout that renders a composition of components
  to service a merchant's customer, allowing them to submit
  payments.
*/
import "babel-polyfill";
import qs from 'qs';
import 'es6-object-assign/auto';

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";
import PaymentForm from '../../components/common/react-component-library/PaymentForm';
import Footer from '../../components/Footer';
import Disclaimer from '../../components/Disclaimer';
import PaymentSteps from '../../components/common/react-component-library/PaymentSteps';
import HeaderLabel from '../../components/common/react-component-library/HeaderLabel';
import Icon from '../../components/common/react-component-library/Icon';
import TotalPaymentBox from '../../components/common/react-component-library/TotalPaymentBox';
import {PaymentLinkService} from './service';
import UserContextProvider, { UserContext } from '../../components/common/react-component-library/LoginWidget/UserContext.js';
import UpdateStoredProfileForm from '../../components/common/react-component-library/Form/UpdateStoredProfileForm';
import {BackDrop} from '../../components/common/react-component-library/Modal';


import ParamParseLib from '../../components/common/react-component-library/lib/ParamParse/paramParse';
import {GetPaymentLink, GetDiscretionaryData, submitPayment, submitRecurringPayment, getMerchantInfo, getMerchantSurInfo, getSurchargeAmountInfo, NewCustomerAccount, customerLogin} from '../../lib/comms';
import './paymentLink.css';
import './paymentLink.small.css';
import {DEFAULT_PRIMARY, DEFAULT_SECONDARY} from '../../components/common/react-component-library/DefaultProperties';
import {ProcessMethod} from '../../components/common/react-component-library/PaymentForm/PaymentProcessMethod';
import ExceptionHandler from './exceptions';
import { conditionalExpression } from "@babel/types";
import FlashNotification from "../../components/common/react-component-library/FlashNotification";


const CUSTOM_TITLE_DEFAULT_TITLE = "Payment"
const SALE_CEILING_AMOUNT_DEFAULT = 99999999.99
// // TODO: move these into common RCL for aphrodite and comus

// Transaction Type   (component parameters)
//   indicates which transactions the merchant is allowing the cardholder to choose from
//    Type of transactions available should be added as 'true' if the cardholder has access to them
//    otherwise, set to false or, preferably, the key/value pair for the transaction type should be removed from the component 
//
//  keys:
//      type:       indicate the type of transaction. can be: "sale", "auth", or "strFwd"
const ParameterTransactionType =
{
  name: "tranxType",
  keys: {type: "type"},
  type: {sale: "sale", auth: "auth", strFwd: "strFwd"},
}

const ParameterSECCode =
{
  name: "secCode",
  keys: {type: "type"},
  type: {CCD: "CCD", WEB: "WEB"},
}

const ParameterConvenienceFee =
{
  name: "convFee",
  keys: {type: "type", amount: "amount", title: "title"},
  type: {fixed: "fixed", percentage: "percentage"}
}

const ParameterSurchargeFee = {
  name: "surFee"
};

// Sale Ceiling Amount
//    Setting the Sale ceiling amount sets the ceiling amount limit by the merchant to be used on Comus to check the allowed sale amount.
// Keys:
//    amount:   Default value
const ParameterSaleCeilingAmount =
{
  name: "saleCeilingAmount",
  keys: { amount: "amount"}
}

const VALID_SEC_CODES = [ "CCD", "WEB" ]

const TRACE_ACH_PROCESSOR_CODE = "VCI"

class PaymentLink extends Component {
  constructor(props){
    super(props);
    const today = new Date();
    const formattedTodayDate =  today.getMonth()+1 + '/' + today.getDate() + '/' + today.getFullYear()
    // some defaults
    this.state = {
      // TODO printing not fully implemented. certain components will not show up on print media
      // because of classnames. Example the breadcrumbs. but it is better if we have more control over it.
      isUpdatingProfile: false,
      profileSaveResponse: "",
      isPrinting: false, 
      isProcessing: false,
      primaryColor: DEFAULT_PRIMARY,
      secondaryColor: DEFAULT_SECONDARY,
      totalAmount: null,
      surchargeAmount: null,
      omitSurcharge: null,
      internalCustomerId: '',
      allowInvoice:true,
      requireInvoice:false, 
      contactInfo: {
      },
      saleCeilingAmount:SALE_CEILING_AMOUNT_DEFAULT,
      isPaymentLinkEnabled: true,
      formdata: {
        // set defaults. When browser refreshes will use these values
        customer_id: '',
        hasTraceACH: false,
        std_entry_classes: [],
        paymentMethod: 'CC',
        invoice: '',
        specialInstruction:'',
        payon: formattedTodayDate, // is the startdate
        tranxType: 'sale', // supports S,A,sale or auth. defaults to sale
        processingMethod: ProcessMethod.ONE_TIME,
        cc: '',
        exp: '',
        checkingAccount: '',
        routingNumber: '',
        disc: "",      
        // billing info
        name: '',
        country: 'us',
        address: [''],
        zipcode: '',
        city: '',
        state: '',
        phone: '',
        email: '',
        AccountType: '',
        SECCode: '',

        password: '',
        token: '',                    //token for a logged in user 
        duration:'continual',         //Default duration cycle for Recurring payment
        // gets filled out by loading the param library
        // could not figure out best way to keep the formdata separate from the other crap
      },
      message: '',
    }
    this.memoSurchargePaymentAmountChange = this._memoSurchargePaymentAmountChange();

    this.setMessages = this.setMessages.bind(this);
    this.createCustomer = this.createCustomer.bind(this);
    this.onCustomerLoggedIn = this.onCustomerLoggedIn.bind(this);
    this.onCustomerLoggedInError = this.onCustomerLoggedInError.bind(this);
    this.onCreateCustomerAccount = this.onCreateCustomerAccount.bind(this);
    this.onRecurringPaymentCreated = this.onRecurringPaymentCreated.bind(this);
    // NOTE: there must be a better way than pushing up all these
    // events to be handled by the PaymentLink parent component
    // and having payment link component submit the payment. I am
    // having the idea of pushing a Model "component" down the other components
    // and having the submit functionality on that Model "component". I tried to
    // do that with the service "component" but if i remember right I did not push it "down".
    // But then again its not the "react" way right?
    this.onPaymentHasACH = this.onPaymentHasACH.bind(this);
    this.onPaymentSetAchSettings = this.onPaymentSetAchSettings.bind(this)
    this.onTotalAmountChange = this.onTotalAmountChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitted = this.onSubmitted.bind(this);
    this.onRecaptchaChange = this.onRecaptchaChange.bind(this);
    this.onAgreementCheckboxChange = this.onAgreementCheckboxChange.bind(this);
    this.onSurchargeRecurringAckChange = this.onSurchargeRecurringAckChange.bind(this);
    this.onBillingPaymentMethodChange = this.onBillingPaymentMethodChange.bind(this);
    this.onBillingNameChange = this.onBillingNameChange.bind(this);
    this.onBillingCreditCardNumberChange = this.onBillingCreditCardNumberChange.bind(this);
    this.onBillingCreditCardExpChange = this.onBillingCreditCardExpChange.bind(this);
    this.onBillingCreditCardSecurityCodeChange = this.onBillingCreditCardSecurityCodeChange.bind(this);
    this.onBillingCheckingAccountChange = this.onBillingCheckingAccountChange.bind(this);
    this.onBillingRoutingNumberChange = this.onBillingRoutingNumberChange.bind(this);
    this.onSECCodeChange = this.onSECCodeChange.bind(this);
    this.onAccountTypeChange = this.onAccountTypeChange.bind(this);
    this.onCustomerIdChange = this.onCustomerIdChange.bind(this);
    this.onBillingCountryChange = this.onBillingCountryChange.bind(this);
    this.onBillingAddressChange = this.onBillingAddressChange.bind(this);
    this.onBillingZipcodeChange = this.onBillingZipcodeChange.bind(this);
    this.onBillingCityChange = this.onBillingCityChange.bind(this);
    this.onBillingStateChange = this.onBillingStateChange.bind(this);
    this.onBillingPhoneChange = this.onBillingPhoneChange.bind(this);
    this.onBillingEmailChange = this.onBillingEmailChange.bind(this);
    this.onBillingSpecialInstructionChange = this.onBillingSpecialInstructionChange.bind(this);
    this.onBillingPasswordChange = this.onBillingPasswordChange.bind(this);
    this.onPaymentInvoiceChange = this.onPaymentInvoiceChange.bind(this);
    this.onPaymentAmountChange = this.onPaymentAmountChange.bind(this);
    this.onProcessingMethodChange = this.onProcessingMethodChange.bind(this);
    this.onPaymentPayonChange = this.onPaymentPayonChange.bind(this);
    this.onFrequencyChange = this.onFrequencyChange.bind(this);
    this.onDurationChange = this.onDurationChange.bind(this);
    this.onNumberOfPaymentsChange = this.onNumberOfPaymentsChange.bind(this);
    this.onDiscretionaryDataChange = this.onDiscretionaryDataChange.bind(this);

    this.onSetUserToken = this.onSetUserToken.bind(this);
    this.setupPaymentLink = this.setupPaymentLink.bind(this);
    this.handleViewTerms = this.handleViewTerms.bind(this);

    this.onSetFieldsForUserProfile = this.onSetFieldsForUserProfile.bind(this);
    this.onError = this.onError.bind(this);
    this.resetOnBrowserReload();

    this.addRecaptchaTokenToState = this.addRecaptchaTokenToState.bind(this);
  }

  // We need this for the scenario where if they are on the billing or review page
  // and they refresh the browser the data for the form is no longer valid. We need to
  // start them over.
  resetOnBrowserReload() {
    const paths = ['/PAYMENT','/BILLING','/REVIEW'];
    if ( paths.includes(this.props.location.pathname.toUpperCase()) ) {
      if (window.performance) {
        if (performance.navigation !== undefined && performance.navigation.type == 1) {
          console.log("Browser reloaded! Restarting.");
          this.props.location.pathname = "/Payment";
          // this.props.history.replace("/Payment"); //not sure why this doesn't work
        } else {
          // console.clear();
        }
      }
    }
  }

  componentWillUnmount() {
    console.log("Unmounting top level component.")
    sessionStorage.clear();
    this.unlisten();
  }

  componentDidMount() {
    // couldn't figure out why Payment and Billing components unmounting 
    // and bubbling up the even to update the message state did not work!!! so
    // just adding event to route changes to clear messages.
    this.unlisten = this.props.history.listen((location, action) => {
      // console.log("on route change. clear any messages");
      this.setState({message: ""})
    });

    let merchant_id = parseInt(sessionStorage.getItem('m'));
    let amount = parseFloat(sessionStorage.getItem('amount'));
    let invoice = sessionStorage.getItem('invoice');

    let invoiceReadOnly = false
    let amountReadOnly = false
    let omitSurchargeInParams = ''

    const queryParameters = this.props.location.search.replace(/\?/gi, "");
    const queryParamObject = qs.parse(queryParameters);

    let queryStringLink = null;
    if ('i' in queryParamObject) { // previewing
      //?v=1&i=eyJwIjoiIzkyMjBiOSIsInMiOiIjMGZkN2U2IiwiYyI6eyJ0ZXJtc0xpbmsiOnsibGluayI6Imh0dHBzOi8vd3d3LmFtYXpvbi5jb20ifSwiY29tcGFueUluZm8iOnsiZW1haWwiOiJlbWFpbGFkZHJlc3NAY29udGFjdC5jb20iLCJuYW1lIjoiVHN5cyBUZXN0IE1lcmNoYW50In0sInRyYW54VHlwZSI6eyJ0eXBlIjoic2FsZSJ9LCJjdXN0b21lckxvZ2luIjp7InYiOjF9LCJjb252RmVlIjp7InYiOjEsImFtb3VudCI6IjUiLCJ0eXBlIjoiZml4ZWQiLCJ0aXRsZSI6IlRoaXMgaXMgdGhlIGV4dHJhIENvbnZlbmllbmNlIEZlZSJ9fX0%3D
      const previewLinkJson = this.linkParamsJson(queryParamObject);

      // for scenario of merchant id and amount exist in the payment link params
      if (previewLinkJson.hasOwnProperty('m')) {
        merchant_id = previewLinkJson['m'];
      }
      queryStringLink = this.props.location.search;
    }

    if ('m' in queryParamObject) {
      merchant_id = queryParamObject['m'];
    }
    if ('amount' in queryParamObject) {
      amount = parseFloat(queryParamObject['amount'])
    }
    if ('invoice' in queryParamObject){
      invoice = queryParamObject['invoice']
    }
    if ('invoiceReadOnly' in queryParamObject)
    {
      invoiceReadOnly = queryParamObject['invoiceReadOnly'] == 'true' ? true : false
    }    
    if ('amountReadOnly' in queryParamObject)
    {
      amountReadOnly = queryParamObject['amountReadOnly'] == 'true' ? true : false
    }
    if('omitSurcharge' in queryParamObject)
    {
      omitSurchargeInParams = queryParamObject['omitSurcharge'];
    }
    
    sessionStorage.setItem('m', merchant_id);
    if (! ('i' in queryParamObject) ) { // not previewing
      sessionStorage.setItem('amount', amount);
      sessionStorage.setItem('invoice', invoice);
    }
    sessionStorage.setItem('invoiceReadOnly', invoiceReadOnly);
    sessionStorage.setItem('amountReadOnly', amountReadOnly);

    this.setState({invoiceReadOnly: invoiceReadOnly,
                   amountReadOnly: amountReadOnly})
    
    this.setupPaymentLink(merchant_id, amount, invoice, queryStringLink, omitSurchargeInParams);
  }

  handleViewTerms()
  {
    if (this.state.termsLink)
    {
      window.open(this.state.termsLink, "_blank") //to open new page
    }
  }

  setupPaymentLink(merchant_id, amount, invoice, params, omitSurchargeInParams)
  {
    this.setState({
      isProcessing: true
    });
    // async calls
    const merchant = this.merchant(merchant_id)
    const discretionaryData = this.discretionaryData(merchant_id);
    const paymentLink = this.paymentLink(merchant_id);
    const omit_surcharge = "t";
    var merchant_sur;

    if(omitSurchargeInParams){
      merchant_sur = this.merchant_surcharge(merchant_id);
    } else {
      merchant_sur = this.merchant_surcharge(merchant_id, omit_surcharge);
    }
   

    const apiCalls = [merchant, discretionaryData, merchant_sur];
    if (params == null) {
      apiCalls.push(paymentLink);
    }

    // asychronously setup the UI once all the calls are made
    Promise.all(apiCalls)
      .then(function(values) {
        let paymentLink = params;//outer scoped
        if (paymentLink == null) {
          paymentLink = values[3];
        }
        // both preview and non preview will call this.
        this.setupUI(values[0], values[1], values[2], paymentLink, merchant_id, amount, invoice, omitSurchargeInParams);
        this.props.history.push('/Payment');

        //EVRY-254: check if payment link is disabled for the merchant
        const merchantInfo = values[0];
  
        const hasShoppingCart = merchantInfo.hasShoppingCart === 'true' || merchantInfo.hasShoppingCart;
        const isProfessional = merchantInfo.isProfessional === 'true' || merchantInfo.isProfessional;
        const isCashAdvance = merchantInfo.isCashAdvance === 'true' || merchantInfo.isCashAdvance;
        if ((!hasShoppingCart && isProfessional) || isCashAdvance) {
          this.setState({isPaymentLinkEnabled: false});
          throw new Error("This merchant does not have the Payment Link feature enabled. Please reach out to the merchant you are attempting to make a payment to for further assistance.");
        }
      }.bind(this), function() {
        this.onError({message: 'This Payment Link is broken. Please reach out to the merchant you are attempting to make a payment to for further assistance.'});
      }.bind(this))
      .catch(this.onError)
      .finally(function(){
        this.setState({
          isProcessing: false
        });
      }.bind(this));
  }

  merchant(id) {
    return getMerchantInfo(id).then((resp) => {
      return resp.data;
    })
  }

  merchant_surcharge(id, omit_surcharge) {
    return getMerchantSurInfo(id, omit_surcharge).then((resp) => {
      return resp.data;
    })
  }

  paymentLink(merchantID) {
    return GetPaymentLink({ merchant_id: merchantID }).then(function(paymentLinkParams) {
      try 
      {
        var encodedParamString = window.atob(paymentLinkParams.data.LinkParams); // try to convert the base64 string
      } 
      catch(e) 
      { // invalid base64 so try to just deconstructEncodedParameterString
        console.error("Invalid payment link");
      }
      return encodedParamString;
    }.bind(this));
  }

  discretionaryData(merchantID) {
    return GetDiscretionaryData({ merchant_id: merchantID }).then(function(discData)
    {
      return discData.data;
    });
  }
  
  setupUI(merchantInfo, discretionaryData, merchantSurchargeInfo, paymentLink, merchantID, amount, invoice, omitSurchargeInParams) {
    const state = Object.assign({}, this.state);
    console.log("ach available", merchantInfo.recur == 'true' || merchantInfo.recur);
    state.formdata.hasACH = merchantInfo.ach == 'true' || merchantInfo.ach;
    state.recurringEnabled = merchantInfo.recur == 'true' || merchantInfo.recur;
    state.formdata.disc = discretionaryData;
    state.contactInfo.name = merchantInfo.dba;
    state.contactInfo.website = merchantInfo.website;
    state.contactInfo.email = merchantInfo.email;
    // passed as properties to paymentform > billing > creditcardfields 
    // to validate if merchant can process cc type
    state.formdata.CT1 = merchantInfo.CT1;
    state.formdata.CT2 = merchantInfo.CT2;
    state.formdata.CT3 = merchantInfo.CT3;
    state.formdata.CT4 = merchantInfo.CT4;
    state.formdata.CT5 = merchantInfo.CT5;
    state.formdata.CT6 = merchantInfo.CT6;
    // todo combine these 2 state updates
    this.setState(state);
    this.setupPaymentLinkAppState(merchantID, amount, invoice, merchantInfo, merchantSurchargeInfo, paymentLink, omitSurchargeInParams);
  }

  onError(e) {
    if (e.message) {
      this.setMessages(e.message);
    } else {
      console.error(e);
      this.setMessages('Unknown Error. Please try again or contact system adminstration.');
    }
  }
  
  linkParamsJson(params) {
    // really only need to do this once since it is a singleton
    ParamParseLib.deconstructEncodedParameterString(params);
    const decodedParamsJsonString = ParamParseLib.getParamsAsJson();
    return JSON.parse(decodedParamsJsonString);
  }

  // setup up application using values from default payment link params for merchant
  setupPaymentLinkAppState(merchantID, amount, invoice, merchantInfo, merchantSurchargeInfo, paymentLink, omitSurchargeInParams) {
    const jsonObj = this.linkParamsJson(paymentLink);
    const surchargeResponseObj = JSON.parse(merchantSurchargeInfo.response);
    var omitSurchargeFromMerchant;
    if(!omitSurchargeInParams) {
      omitSurchargeFromMerchant = merchantSurchargeInfo.omit_surcharge ? merchantSurchargeInfo.omit_surcharge : '';
    }

    if (isNaN(parseFloat(amount))) {
      amount = '';
    }

    // session storage will make invoice a string value of the word null. Need to also check for that
    // because "null" does not equal null. Assumption "null" will never be valid invoice.
    invoice = invoice == null || invoice == 'null' ? "" : invoice;
    
    let hasACH = false;
    // It could either be empty string or boolean value. But should
    // we not assume that what ever is serializing the boolean value will actually
    // be a boolean value. Checking for all edge cases just incase, and not sure if i like it. ugly.
    if (merchantInfo != undefined &&
      merchantInfo.ach != '' && merchantInfo.ach != undefined) {
      hasACH = merchantInfo.ach.toString().toLowerCase() == 'true';
    }
    let tranxType = ParamParseLib.getComponentKeyValue(ParameterTransactionType.name, ParameterTransactionType.keys.type)
    this.onTranxTypeChange(tranxType);
    this.onPaymentHasACH(hasACH);
    if (hasACH){
      this.onPaymentSetAchSettings(merchantInfo)
    }

    let secCode = ParamParseLib.getComponentKeyValue(ParameterSECCode.name, ParameterSECCode.keys.type)
    this.onSECCodeChange(secCode)

    let termsLink = ParamParseLib.getComponentKeyValue("termsLink", "link");
    let customTitle = ParamParseLib.getComponentKeyValue("customTitle", "customTitle");
    if (!customTitle || customTitle.length < 1)
    {
      customTitle = CUSTOM_TITLE_DEFAULT_TITLE
    }

    let convFeeInfo = null
    if (ParamParseLib.getComponentVisible(ParameterConvenienceFee.name) == true)           //if the component is not visible (set by the merchant) then dont show the convenience fee at all
    {
      let convFeeAmount = ParamParseLib.getComponentKeyValue(ParameterConvenienceFee.name, ParameterConvenienceFee.keys.amount);
      convFeeAmount = Number(convFeeAmount)

      if (convFeeAmount != NaN & convFeeAmount > 0)
      {
        let convFeeTitle = ParamParseLib.getComponentKeyValue(ParameterConvenienceFee.name, ParameterConvenienceFee.keys.title);
        let convFeeType = ParamParseLib.getComponentKeyValue(ParameterConvenienceFee.name, ParameterConvenienceFee.keys.type);
        convFeeInfo = {amount: convFeeAmount, title: convFeeTitle, type: convFeeType}
      }
    }

    let surFeeInfo = null;
    if(
      ParamParseLib.getComponentVisible(ParameterSurchargeFee.name) === true &&
      surchargeResponseObj.is_surcharge_monthly_product &&
      !omitSurchargeInParams
      ) {
      surFeeInfo = { surcharge: true };
    }

    let allowUserLogin = ParamParseLib.getComponentVisible("customerLogin");
    
    let allowInvoice = true ;
    let requireInvoice = false;
    
    if(ParamParseLib.getDecodingVersion() != 1){
      requireInvoice = ParamParseLib.getComponentRequired("invoice") ? true : requireInvoice ;
      allowInvoice = ParamParseLib.getComponentVisible("invoice") ;
    }
    
    //For Sale Ceiling amount setting - PC2-438
    let saleCeilingAmount = SALE_CEILING_AMOUNT_DEFAULT;
        
    if(ParamParseLib.getDecodingVersion() >= 3 ){
      saleCeilingAmount = ParamParseLib.getComponentKeyValue(ParameterSaleCeilingAmount.name, ParameterSaleCeilingAmount.keys.amount ) ;
      saleCeilingAmount = Number(saleCeilingAmount);
    }
    
    
    // NOTE in react there is a danger of state assignment wiping other values out.
    // if we move this above the other state changes it will get wiped out.
    // Need to think through this and how it exactly works and perhaps use a library.
    this.setState({
      merchantID: merchantID,
      primaryColor: jsonObj.p || DEFAULT_PRIMARY,
      secondaryColor: jsonObj.s || DEFAULT_SECONDARY,
      totalAmount: amount,
      termsLink: termsLink,
      convFee: convFeeInfo,
      surFee: surFeeInfo,
      ...(omitSurchargeFromMerchant || omitSurchargeInParams ? { omitSurcharge: omitSurchargeFromMerchant || omitSurchargeInParams } : {}),
      customTitle: customTitle,
      allowUserLogin: allowUserLogin,
      allowInvoice:allowInvoice,
      requireInvoice:requireInvoice,
      saleCeilingAmount: saleCeilingAmount
    });

    this.onPaymentAmountChange(amount);           //set below all the other calls due to propogration of amount, totalAmount, and conveniece fee data
    this.onPaymentInvoiceChange(invoice);
  }

  onSetFieldsForUserProfile(apiResponse) {
    this.onBillingNameChange(apiResponse.BNAME);
    this.onBillingAddressChange([apiResponse.BADDRESS, apiResponse.BADDRESS2]);
    this.onBillingCityChange(apiResponse.BCITY);
    this.onBillingCountryChange(apiResponse.BCOUNTRY);
    this.onBillingStateChange(apiResponse.BSTATE);
    this.onBillingZipcodeChange(apiResponse.BZIP);
    // a bit of a hack. In formsection component we are using this
    // method with the parameters isvalad and second parameter email value
    // instead of changing the formsection component handling the email
    // am just going to assume the onSetFieldsForUserProfile has valid email. PC2-283
    // email field not being set when user logs in.
    this.onBillingEmailChange(true, apiResponse.EMAIL);
    this.onBillingPhoneChange(apiResponse.PHONE);
    this.onSetUserToken(apiResponse.token);
    this.onAccountTypeChange(apiResponse.AccountType)
    this.onSECCodeChange(apiResponse.SECCode)
    this.onCustomerIdChange(apiResponse.InternalCustomerID)
    // Last4CC, Exp, Security Code not implemented since the processing doesn't use it.
    //(Checking Info will need to be implemented on the get customer info)
  }


  onSetUserToken(token)
  {
    //sets the formdata token to relfect the context token of the logged in user
    if (token != null) 
    {
      const state = Object.assign({}, this.state);
      state.formdata.token = token;
      this.setState(state);
    }
  }
  
 
  onPaymentInvoiceChange(invoice) {
    const state = Object.assign({}, this.state);
    state.formdata.invoice = invoice;
    this.setState(state);
  }
  
  onPaymentHasACH(hasACH) {
    const state = Object.assign({}, this.state);
    state.formdata.hasACH = hasACH;
    this.setState(state);
  }

  onPaymentSetAchSettings(merchant_info) {
    const state = Object.assign({}, this.state);
    state.formdata.hasTraceACH = false
    // To enable TraceACH for a merchant, ensure the following:
    // 1. ACH must be enabled for the merchant.
    // 2. The merchant's processor should be TRACE_ACH_PROCESSOR_CODE (defined as VCI).
    // 3. The processor status must be activated (defined as 1).
    if (merchant_info.ach_processor_code === TRACE_ACH_PROCESSOR_CODE && merchant_info.ach_processor_status == 1){
      state.formdata.hasTraceACH = true
      const available_std_entry_classes = VALID_SEC_CODES.filter(element => merchant_info.ach_std_entry_classes.includes(element));
      state.formdata.std_entry_classes = available_std_entry_classes;
    }
    this.setState(state);
  }

  onPaymentAmountChange(amount) {
    const surFeeInfo = this.state.surFee

    if (surFeeInfo !== null && typeof surFeeInfo === 'object'
        && this.state.formdata.paymentMethod === 'CC') {
      this.memoSurchargePaymentAmountChange(amount);
    } else {
      this.onPaymentAmountChangeConvenience(amount);
    }
  }

  onPaymentAmountChangeConvenience(amount) {
    const state = Object.assign({}, this.state);
    state.formdata.amount = amount;
    this.setState(state);

    let convFeeAmount = this.getConvFeeAmount()                       //conv fee can be affected by an amount change (if % based calculation)
    state.formdata.convenienceFee = convFeeAmount;
    this.setState(state);                                             //not ideal to set state twice like this

    let totalAmount = Number(amount) + Number(convFeeAmount);       //total amount needs to include any fee

    this.onTotalAmountChange(totalAmount.toFixed(2));
  }

  // Do not use directly, use the non-prefixed version.
  _memoSurchargePaymentAmountChange = () => {
    let cached = {};
    let inApiCall = false;
    let timeout = null;

    return (amount) => {
      // To ensure the totalAmount is updated as soon as possible,
      // update the state twice: first directly from the form fields,
      // then with the appropriate fee.
      this.updateSurchargeTotal(amount, this.state.formdata.surchargeAmount);

      // Wait a little for any further input.
      timeout = setTimeout(() => {
        // The amount in the form state was updated earlier. If the
        // amount != formstate.amount, wait for another event.
        // If we don't cancel the call now, we will update the surcharge
        // amount for an old value in the amount field
        if (amount !== this.state.formdata.amount) {
          return;
        }

        const formdata = Object.assign({}, this.state.formdata);
        let subtotal = (!isNaN(amount))
            ? Number(amount).toFixed(2)
            : "";

        if (subtotal in cached) {
          this.updateSurchargeTotal(amount, cached[subtotal]);

          return;
        }

        // If we have currentAmount defined, there's an API call being made,
        if (inApiCall) {
          return;
        }

        // I guess we actually have to make the API call
        inApiCall = true;
        this.onPaymentAmountChangeSurcharge(amount).then(
            (surFee) => {
              if (surFee !== undefined) {
                cached[subtotal] = surFee;
                this.updateSurchargeTotal(amount, surFee);
              }
            },
            () => {
              // Don't update cached version on error
              this.setMessages("Unexpected Error. Please try again or contact system administration");
            }
        ).finally(() => {
          inApiCall = false;
          clearTimeout(timeout);
        });
      }, 375);
    }
  }

  async onPaymentAmountChangeSurcharge(amount) {
    const surFeeAmountPromise = this.getSurFeeAmount(amount);

    this.setState({ isProcessing: true });
    try {
      const surFeeAmount = await surFeeAmountPromise;

      if(!isNaN(surFeeAmount)) {
        return Number(surFeeAmount).toFixed(2);
      } else {
        console.error("surFeeAmount is not a valid number");
        this.setMessages("Unexpected Error. Please try again or contact system administration");
      }
    } catch (error) {
      console.error("An error ocurred: ", error);
    } finally {
      this.setState({ isProcessing: false });
    }
  }

  updateSurchargeTotal = (amount, surchargeAmount) => {
    const formdata = Object.assign({}, this.state.formdata);
    formdata.amount = amount;
    formdata.surchargeAmount = Number(surchargeAmount);

    const totalAmount = (!isNaN(surchargeAmount))
        ? (Number(amount) + Number(surchargeAmount)).toFixed(2)
        : Number(amount).toFixed(2);

    this.setState({
      formdata,
      surchargeAmount: Number(surchargeAmount).toFixed(2),
      totalAmount,
    });
  }
  
  // manage state for start date of first recurring payment
  onPaymentPayonChange(payon) {
    const state = Object.assign({}, this.state);
    state.formdata.payon = payon;
    this.setState(state);
  }
  
  onFrequencyChange(frequency) {
    const state = Object.assign({}, this.state);
    state.formdata.frequency = frequency;
    this.setState(state);
  }
  
  onDurationChange(duration) {
    const state = Object.assign({}, this.state);
    state.formdata.duration = duration;
    this.setState(state);
  }
  
  onNumberOfPaymentsChange(number) {
    const state = Object.assign({}, this.state);
    state.formdata.numberOfPayments = number;
    this.setState(state);
  }
  
  // manage state for recurring or a one time payment on payment page
  onProcessingMethodChange(processingMethod) {
    const state = Object.assign({}, this.state);
    state.formdata.processingMethod = processingMethod;
    this.setState(state);
  }
  
  // manage state for sale, authonly, etc...
  onTranxTypeChange(tranxType) {
    const state = Object.assign({}, this.state);
    state.formdata.tranxType = tranxType;
    this.setState(state);
  }

  // manage state for ACH or Credit Card
  onBillingPaymentMethodChange(paymentMethod) {
    const state = Object.assign({}, this.state);

    if(paymentMethod === 'ACH' && state.formdata.surchargeAmount) {
      const updatedFormData = { ...state.formdata };
      delete updatedFormData.surchargeAmount;
      state.formdata = updatedFormData;
    }

    state.formdata.paymentMethod = paymentMethod;
    this.setState(state, this.updateSurchargeFields);
  }

  onBillingNameChange(name) {
    const state = Object.assign({}, this.state);
    state.formdata.name = name;
    this.setState(state);
  }

  onBillingCheckingAccountChange(checkingAccount) {
    const state = Object.assign({}, this.state);
    state.formdata.checkingAccount = checkingAccount;
    this.setState(state);
  }

  onBillingRoutingNumberChange(routingNumber) {
    const state = Object.assign({}, this.state);
    state.formdata.routingNumber = routingNumber;
    this.setState(state);
  }

  onAccountTypeChange(account_type){
    const state = Object.assign({}, this.state);
    state.formdata.AccountType = account_type;
    this.setState(state);
  }

  onSECCodeChange(sec_code){
    const state = Object.assign({}, this.state);
    state.formdata.SECCode = sec_code;
    this.setState(state);
  }

  onCustomerIdChange(customer_id){
    const state = Object.assign({}, this.state);
    state.formdata.customer_id = customer_id;
    this.setState(state);
  }

  onBillingCreditCardNumberChange(cc) {
    const state = Object.assign({}, this.state);
    state.formdata.cc = cc;
    this.setState(state, this.updateSurchargeFields);
  }

  onBillingCreditCardExpChange(exp) {
    const state = Object.assign({}, this.state);
    state.formdata.exp = exp;
    this.setState(state);
  }

  onBillingCreditCardSecurityCodeChange(securityCode) {
    const state = Object.assign({}, this.state);
    state.formdata.securityCode = securityCode;
    this.setState(state);
  }

  onBillingCountryChange(country) {
    const state = Object.assign({}, this.state);
    state.formdata.country = country.toLowerCase();
    this.setState(state);
  }

  onBillingAddressChange(address) {
    const state = Object.assign({}, this.state);
    state.formdata.address = address;
    this.setState(state);
  }

  onBillingZipcodeChange(zipcode) {
    const state = Object.assign({}, this.state);
    state.formdata.zipcode = zipcode;
    this.setState(state);
  }

  onBillingCityChange(city) {
    const state = Object.assign({}, this.state);
    state.formdata.city = city;
    this.setState(state);
  }

  onBillingStateChange(state) {
    const componentState = Object.assign({}, this.state);
    componentState.formdata.state = state;
    this.setState(componentState, this.updateSurchargeFields);
  }

  onBillingPhoneChange(phone) {
    const componentState = Object.assign({}, this.state);
    componentState.formdata.phone = phone;
    this.setState(componentState);
  }

  onBillingEmailChange(isValid, email) {
    const componentState = Object.assign({}, this.state);
    componentState.formdata.email = email;
    this.setState(componentState);
  }
  
  onBillingSpecialInstructionChange(specialInstruction){
    const state = Object.assign({}, this.state);
    state.formdata.specialInstruction = specialInstruction;
    this.setState(state);
  }
  
  onBillingPasswordChange(isValid, password) {
    const componentState = Object.assign({}, this.state);
    // console.log("password", isValid);
    // if password was once valid but then invalid then clear the invalid password
    if (isValid) {
      componentState.formdata.password = password;
    } else {
      componentState.formdata.password = '';
    }
    this.setState(componentState);
  }

  onAgreementCheckboxChange(agreeToTermsChecked) {
    const componentState = Object.assign({}, this.state);
    componentState.formdata.agreeToTermsChecked = agreeToTermsChecked;
    this.setState(componentState);
  }

  onSurchargeRecurringAckChange(agreeToSurchargeRecurAck) {
    const componentState = Object.assign({}, this.state);
    componentState.formdata.agreeToSurchargeRecurAck = agreeToSurchargeRecurAck;
    this.setState(componentState);
  }

  onRecaptchaChange(recaptchaToken, valid) {
    const componentState = Object.assign({}, this.state);
    componentState.formdata.recaptchaToken = recaptchaToken;
    this.setState(componentState);
  }

  addRecaptchaTokenToState(recaptchaToken) {
    const componentState = Object.assign({}, this.state);
    componentState.formdata.recaptchaToken = recaptchaToken;
    this.setState(componentState)
  }


  // note this does a shallow copy of the state. the formdata.disc 
  // is a reference to the parameter passed in. works because 
  // set state makes children render but could create bugs that are hard to find.
  onDiscretionaryDataChange(discretionaryData) 
  {
    const state = Object.assign({}, this.state);
    state.formdata.disc = discretionaryData;
    this.setState(state);
  }


  onTotalAmountChange(totalAmount) {
    this.setState({
      totalAmount: totalAmount
    });
  }

  updateSurchargeFields = () => {
    // Reset memoized surcharge calculations
    this.memoSurchargePaymentAmountChange = this._memoSurchargePaymentAmountChange();

    // To cut down on excess api calls, do a length sanity check on the relevant fields
    const {amount, cc, state} = this.state.formdata;

    if ((!cc || !state) || (cc.length >= 13 && state.length === 2)) {
      this.onPaymentAmountChange(amount);
    }
  }

  handleSurchargeCustomerLogin = (event) => {
    const newInternalCustomerId = event.currentTarget.value;
    this.setState({ internalCustomerId: newInternalCustomerId }, this.updateSurchargeFields);
  }
  
  // Since there is not form submission but a async xhr request
  // Users hitting the back button will go back in the route history.
  // would be nice to some how prevent them or warn them of resending data or something.
  onSubmitted(response) {
    this.setState({
      isProcessing: false
    });
    this.props.history.push({
      pathname: '/Receipt',
      state: response.data,
    });
  }

  onCustomerLoggedInError(error) {
    this.setMessages('Unexpected Error.' + error.message)
  }

  // Since there is not form submit for recurring but a async xhr request
  // Users hitting the back button will go back in the route history.
  // would be nice to some how prevent them or warn them of resending data or something.
  onRecurringPaymentCreated(response) {
    // console.log(response);
    // leviathan returns json with data attribute not axios
    this.setState({
      isProcessing: false
    });
    if (response.data.success && response.status == 200) {

      this.props.history.push(
        {
          pathname: '/Summary',
          state: response.data,
        }
      )
    }
  }

  // a mess. function names are confusing. need to clean it up
  onCustomerLoggedIn(response) {
    // Note this is not stored in the customer context nor is the token in state is set.
    // create customer, login customer and then try to create a recurring payment
    // console.log("customer created and logged in so go create recurring payment", response);
    var numberOfPayments = this.state.formdata.numberOfPayments;
    var amount = this.state.totalAmount;
    var recurPaymentSurchargeFlag = 0;
    const surFeeInfo = this.state.surFee;
    
    if (this.state.formdata.duration == 'continual') {
      numberOfPayments = "999";
    }

    if (surFeeInfo !== null && typeof surFeeInfo === 'object' &&
        this.state.formdata.paymentMethod === 'CC') {
        recurPaymentSurchargeFlag = 1;
        amount = Number(this.state.formdata.amount).toFixed(2); // Amount pre-surcharge. Don't apply surcharge twice
    }

    //note the recurring table will store the total amount that includes convenience fee
    submitRecurringPayment({
      MerchantID: this.state.merchantID,
      token: response.token,
      // PC2-138. Was decided by biz to add convenience fee too all recurring transactions
      Amount: amount,
      AddSurcharge: recurPaymentSurchargeFlag,
      Start: this.state.formdata.payon,
      Frequency: this.state.formdata.frequency,
      TotalCount: numberOfPayments,
      Description: this.state.formdata.specialInstruction,
      // IPAddress set via server
      PaymentType: this.state.formdata.paymentMethod,  //CreditCard or Check
      TranxType: this.state.formdata.tranxType,        // could be S, A, sale or auth
      disc: this.state.formdata.disc,                  //discretionary data
      feeTitle: this.getFeeTitleFromState(),           //retrieval of the fee title (for use in emails/receipts)
      merchantCustomTitle: this.state.customTitle,     //retrieval of the custom title (for use in emails/receipts)
      SECCode: this.state.formdata.SECCode
    })
      .then(this.onRecurringPaymentCreated)
      .catch((err) => {
        // TODO call set message or try to centralize messages
        this.setMessages(err.message);
      });
  }

  onCreateCustomerAccount(resp){
    // console.log("create customer callback response:", resp)
    if (resp !== undefined && resp.data !== undefined && (resp.data.status == 200 || resp.data.status == "200")) {
      
      customerLogin(this.state.formdata.email, this.state.formdata.password, this.state.merchantID, null)
        .then(this.onCustomerLoggedIn)
        .catch(this.onCustomerLoggedInError);
    } else {
      this.setMessages(resp.data.message)
    }
  }

  // copied from formsection component. See if we can extract and reuse this method
  // this gets called first when a recurring payment is submitted
  // and a customer is not logged in. The manual account creation component is on the FormSection.js
  createCustomer() {
    const data = {
      merchant_id: this.state.merchantID, //required
      EMAIL: this.state.formdata.email, //required
      CC: this.state.formdata.cc, //required
      Password: this.state.formdata.password, //there is no check on VT
      DDA: this.state.formdata.checkingAccount, //there is no check on VT
      TR: this.state.formdata.routingNumber, //there is no check on VT
    
      BNAME: this.state.formdata.name,
      BADDRESS: this.state.formdata.address[0],
      BADDRESS2: this.state.formdata.address[1],
      BCITY: this.state.formdata.city,
      BSTATE: this.state.formdata.state,
      BZIP: this.state.formdata.zipcode,
      BCOUNTRY: this.state.formdata.country.toUpperCase(), //system uses 2 char country code uppercase
      PHONE: this.state.formdata.phone,
      Exp: this.state.formdata.exp, //gets sent as month and year to VT
      AccountType: this.state.formdata.AccountType,
      SECCode: this.state.formdata.SECCode,
    }

    return NewCustomerAccount(data).then(function(resp) {
      // TODO CLEANUP DELETE these doesn't seem to be used. Check and I think its ok to delete
      if (resp !== undefined && resp.data !== undefined && (resp.data.status == 200 || resp.data.status == "200")) {
        this.setState({
          accountCreatedSuccess: true,
        });
      } else {
        this.setState({
          accountCreatedSuccess: false,
          accountCreationMessage: resp.data.message.replace("Please use the log in section to log into your stored profile and complete your transaction.<BR />", "Please use your email address and try to log in as a returning user to complete your transaction."),
        });
      }
      // END TODO CLEANUP
      return resp;
    }.bind(this)).catch(function(err) {
      // TODO CLEANUP DELETE these doesn't seem to be used. Check and I think its ok to delete
      if (err.response.status === 404) {
        this.setState({
          accountCreatedSuccess: false,
          accountCreationMessage: 'Network Error. Failed'
        });
      }
      this.setState({
        accountCreatedSuccess: false,
        accountCreationMessage: 'Unexpected Error.'
      });
      // END TODO CLEANUP
    }.bind(this));
  }

  applyTraceACHConfigurations() {
    const paymentMethodAch = this.state.formdata.paymentMethod === "ACH";
    const hasTraceACH = this.state.formdata.hasTraceACH;

    // Limit Country to `US` for Trace ACH merchants (ETRN-227)
    if (paymentMethodAch&&hasTraceACH){
      this.onBillingCountryChange('us')
    }
    
  }

  onSubmit(e, arg) {
    if (this.state.merchantID !== undefined) {
      e.preventDefault();
      
      // one last verification of formdata? or would be nice to check a token? todo? not now? later...
      this.setState({
        isProcessing: true
      });
      
      this.applyTraceACHConfigurations()

      if (this.state.formdata.processingMethod == ProcessMethod.RECURRING) {
        if (this.state.formdata.token.trim() == '') { //user is not logged in yet
          this.createCustomer()
            .then(this.onCreateCustomerAccount)
            .finally(() => {
              this.setState({
                isProcessing: false
              });
            });
        } 
        else { //user is logged in so just go create the recurring payment with token
          var numberOfPayments = this.state.formdata.numberOfPayments;
          var amount = this.state.totalAmount;
          var recurPaymentSurchargeFlag = 0;
          const surFeeInfo = this.state.surFee;

          if (this.state.formdata.duration == 'continual') {
            numberOfPayments = "999";
          }
          
          if (surFeeInfo !== null && typeof surFeeInfo === 'object' &&
              this.state.formdata.paymentMethod === 'CC') {
              recurPaymentSurchargeFlag = 1;
              amount = Number(this.state.formdata.amount).toFixed(2); // Amount pre-surcharge. Don't apply surcharge twice
          }
          
          //note the recurring table will store the total amount that includes convenience fee
          submitRecurringPayment({
            MerchantID: this.state.merchantID,
            token: this.state.formdata.token,
            // PC2-138. Was decided by biz to add convenience fee too all recurring transactions
            Amount:  amount,
            AddSurcharge: recurPaymentSurchargeFlag,
            Start: this.state.formdata.payon,
            Frequency: this.state.formdata.frequency,
            TotalCount: numberOfPayments,
            Description: this.state.formdata.specialInstruction,
            // IPAddress: "192.106.1.10",
            PaymentType: this.state.formdata.paymentMethod,   //CreditCard or Check
            TranxType: this.state.formdata.tranxType,         // ould be S, A, sale or auth
            disc: this.state.formdata.disc,                   //discretionary data
            feeTitle: this.getFeeTitleFromState(),            //retrieval of the fee title (for use in emails/receipts)
            merchantCustomTitle: this.state.customTitle,      //retrieval of the custom title (for use in emails/receipts)
            SECCode: this.state.formdata.SECCode
          })
            .then(this.onRecurringPaymentCreated)
            .catch((err) => {
              this.setMessages(err.message)
            });
        }
      } 
      else {
        // submit one time payment

        //due to the way we are handling items passed to Lev, we need to add the fee title to the formdata. 
        // Ideally this would be handled elsewhere but since the logic between one time and recurring payemts is different,
        // this is how it will be handled for now
        //.  conv fee info is already a part of state, but not a part of formdata. Do not want to pollute internal formdata with items that dont belong with the form
        let tempFormdata = this.state.formdata
        tempFormdata.feeTitle = this.getFeeTitleFromState();
        tempFormdata.merchantCustomTitle = this.state.customTitle;

        if(
          tempFormdata.paymentMethod === 'CC' &&
          tempFormdata.convenienceFee <= 0 &&
          ((ParamParseLib.getComponentVisible(ParameterSurchargeFee.name) && this.state.surFee === null) ||
          (!ParamParseLib.getComponentVisible(ParameterSurchargeFee) && this.state.surFee === null))
        ) {
          tempFormdata.omit_surcharge = this.state.omitSurcharge;
        }
        
        // TODO: fix the scenario that if you switch to recurring payment,
        // add a number of payment value and switch back to one time.
        // you will have errors because there will be fields that leviathan will reject.
        // we should send a clean mapped model over to leviathan. A central dto
        // mapping for any server request actually.
        submitPayment(this.state.merchantID, tempFormdata)
          .then(this.onSubmitted)
          .catch(this.onError)
          .finally(() => {
            this.setState({
              isProcessing: false
            })
          });

      }
    }
  }


  //helper function for retrieval of the fee title/label
  getFeeTitleFromState()
  {
    let title = "Fee"                           //to be the default value if nothing else has been entered by the merchant
    const convFeeInfo = this.state.convFee;
    if (convFeeInfo != null)                    //we only want to display the convenience fee box when there actually is a convenience fee 
    {
      title = convFeeInfo.title ? convFeeInfo.title : "Fee"
    }
    return title;
  }


  // list of on events methods to pass down as properties to payment form.
  // didn't want it to be typed out on the actual PaymentForm component
  // but really adds no benefit besides visually different. The PaymentForm component
  // has some explicitly set so its pretty much half and half. I feel like its ok to just
  // add it explicitly to the component now that i think about it.
  onEvents() {
    return {
      onPaymentInvoiceChange: this.onPaymentInvoiceChange,
      onPaymentAmountChange: this.onPaymentAmountChange,
      onProcessingMethodChange: this.onProcessingMethodChange,
      onBillingPaymentMethodChange: this.onBillingPaymentMethodChange,
      onBillingNameChange: this.onBillingNameChange,
      onBillingCheckingAccountChange: this.onBillingCheckingAccountChange,
      onBillingRoutingNumberChange: this.onBillingRoutingNumberChange,
      onSECCodeChange: this.onSECCodeChange,
      onAccountTypeChange: this.onAccountTypeChange,
      onBillingCreditCardNumberChange: this.onBillingCreditCardNumberChange,
      onBillingCountryChange: this.onBillingCountryChange,
      onBillingAddressChange: this.onBillingAddressChange,
      onBillingZipcodeChange: this.onBillingZipcodeChange,
      onBillingCityChange: this.onBillingCityChange,
      onBillingStateChange: this.onBillingStateChange,
      onBillingPhoneChange: this.onBillingPhoneChange,
      onBillingEmailChange: this.onBillingEmailChange,
      onBillingSpecialInstructionChange: this.onBillingSpecialInstructionChange,
      onBillingPasswordChange: this.onBillingPasswordChange,
      onRecaptchaChange: this.onRecaptchaChange,
      onAgreementCheckboxChange: this.onAgreementCheckboxChange,
      onSurchargeRecurringAckChange: this.onSurchargeRecurringAckChange,
      onBillingCreditCardSecurityCodeChange: this.onBillingCreditCardSecurityCodeChange,
      onBillingCreditCardExpChange: this.onBillingCreditCardExpChange,
      onTotalAmountChange: this.onTotalAmountChange,
      onDiscretionaryDataChange: this.onDiscretionaryDataChange,
      onSubmit: this.onSubmit,
    }
  }

  // TODO redo breadcrumbs to its own component and maintain its own state.
  renderBreadCrumbs() {
    const primaryColor =  this.state.primaryColor
    const color = this.state.secondaryColor;

    const step1 = this.state.customTitle || 'Payment';
    
    if (this.state.formdata.processingMethod == ProcessMethod.ONE_TIME) {
      var stepList = [ step1, 'Billing','Review', 'Receipt'];
    } else {
      var stepList = [ step1, 'Billing','Review', 'Summary'];
    }
    return (
        <PaymentSteps
            color={color}
            primaryColor={primaryColor}
            path={this.props.location.pathname}
            steps={stepList} />
    )
  }

  renderSpinner() {
    if (this.state.isProcessing) {
      return (
        <div style={{textAlign:'center', marginBottom: '25px', height: '25px'}}>
          <h3>Please Wait...</h3>
          <div className="loader">Please Wait...</div>
        </div>
      )
    } else {
      return null;
    }
  }

  renderPageHeader() {

    const title = this.props.history.location.pathname.replace('/','');

    let customHeaderTitle = title == 'Payment'? this.state.customTitle : title
    
    return (
      <div>
        {this.renderSpinner()}
        <HeaderLabel label={{text: customHeaderTitle, size: 25}}>
          <Icon img={title + ".png"} width="36" height="36"/>
        </HeaderLabel>
      </div>
    )
  }


  getConvFeeAmount()
  {
    let convFeeAmount = 0
    const convFeeInfo = this.state.convFee;
    if (convFeeInfo != null)                //we only want to display the convenience fee box when there actually is a convenience fee 
    {
      let convFeeType = convFeeInfo.type
      if (convFeeType == ParameterConvenienceFee.type.percentage)
      {
        if (this.state.formdata.amount)
        {
          let percentage = Number(convFeeInfo.amount)
          convFeeAmount = Number(this.state.formdata.amount) * (percentage / 100)
        }
         
      }
      else if (convFeeType == ParameterConvenienceFee.type.fixed)
      {
        convFeeAmount = convFeeInfo.amount
      }
    }    
    convFeeAmount = convFeeAmount.toFixed(2)
    return convFeeAmount
  }

  async getSurFeeAmount (amount) {
    const customer_id = document.getElementById('internalCustomerId').value;
    const cc = this.state.formdata.cc;
    const state = this.state.formdata.state;

    try {
      const surcharge_amount = await getSurchargeAmountInfo(
          this.state.merchantID,
          amount,
          cc,
          customer_id,
          state
      );

      return Number(surcharge_amount.surcharge_amount).toFixed(2);
    } catch (error) {
      this.onError(error);

      // Return a default value on error;
      return 0;
    }
  }

  renderConvenienceFeeControl() 
  {
    const convFeeInfo = this.state.convFee;
    if (convFeeInfo != null)                //we only want to display the convenience fee box when there actually is a convenience fee 
    {
      let title = convFeeInfo.title ? convFeeInfo.title : "Fee"
      let convFeeAmount = this.getConvFeeAmount()

      //reusing the totalypatmentbox for use by the convenience fee... same layout of information
      return (
        <TotalPaymentBox 
                        totalAmount={ convFeeAmount }
                        placeholderText= ""
                        name="txtConvFee"
                        maxLength="13"
                        textBoxWidth="50%"
                        tooltipText= {title} 
                        >
          <div>{title}</div>
        </TotalPaymentBox>
      )
    }
  }

  renderSurchargeFeeControl() {
    const { surFee, surchargeAmount, formdata } = this.state;
    const processingMethod = formdata.processingMethod;

    const surchargeOneTimeMsg = `
      A surcharge has been added to this transaction. If you
      wish to avoid this fee, please use a different payment
      type (i.e. debit card)
    `;

    const surchargeRecurMsg = `
      A surcharge has been added to this transaction, and will 
      be added to all future transactions within this recurring 
      payment schedule. If you wish to avoid this fee, please use 
      a different payment type (i.e. debit card)
    `;

    const surchargeMessage = (message) => (
      <div className="surcharge-message">
        {message.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            < br />
          </React.Fragment>
        ))}
      </div>
    );

    if (
      surchargeAmount > 0 &&
      this.state.formdata.paymentMethod === 'CC' &&
      surFee !== null &&
      typeof surFee === 'object'
    ) {
      return (
        <div className="surcharge-container">
          <div className="total-payment-box">
            <TotalPaymentBox
                totalAmount= { surchargeAmount }
                placeholderText=""
                name="txtSurFee"
                maxLength="13"
                textBoxWidth="50%"
                tooltipText="Surcharge Amount"
              >
                <div>Surcharge Amount</div>
              </TotalPaymentBox>
          </div>
          {surchargeAmount > 0 && processingMethod === ProcessMethod.ONE_TIME ? (
            surchargeMessage(surchargeOneTimeMsg)
          ) : surchargeAmount > 0 && processingMethod === ProcessMethod.RECURRING &&
              this.props.location.pathname.toUpperCase() !== '/PAYMENT' ? (
            <div>
              {surchargeMessage(surchargeRecurMsg)}
              <div className="surcharge-recur-ack">
                <input 
                  type="checkbox" 
                  className="checkbox-round"
                  onChange={(evt) => this.onSurchargeRecurringAckChange(evt.target.checked)} 
                  checked={this.state.formdata.agreeToSurchargeRecurAck || ''}
                />
                <label htmlFor="surchargeAck">Acknowledge</label>
              </div>
            </div>
          ) : null}
        </div> 
      );
    }
  }


  renderTotalPaymentControl() {
    // don't show total on review and receipt page
    // receipt page shouldn't be a step though. it is for now.
    if (this.props.location.pathname.toUpperCase() == '/REVIEW' 
      || this.props.location.pathname.toUpperCase() == '/RECEIPT'
      || this.props.location.pathname.toUpperCase() == '/SUMMARY'
    ) return null;

    let totalAmount = this.state.totalAmount;
    const surFeeInfo = this.state.surFee;
    let title = 'Total Amount';

    if (surFeeInfo !== null && typeof surFeeInfo === 'object' &&
        this.state.formdata.surchargeAmount > 0 && this.state.formdata.paymentMethod !== 'CC') {
        totalAmount = Number(this.state.formdata.amount).toFixed(2);
    }

    return (
      <div>
        { this.renderConvenienceFeeControl() }
        { this.renderSurchargeFeeControl() }
        <TotalPaymentBox 
                        totalAmount={ totalAmount }
                        placeholderText= ""
                        name="txtTotalPayment"
                        maxLength="13"
                        textBoxWidth="50%"
                        tooltipText="Total Dollar amount of the transaction" 
                        >
          <div>{title}</div>
        </TotalPaymentBox>
      </div>
    )
  }

  setMessages(message) {
    this.setState({
      message: message
    })
  }

  canContinue = (context) => {
    if (this.state.isProcessing) {
      return false;
    }

    // Don't allow going to the next page if surcharge-enabled, and the billing state is missing
    const isSurcharge = this.state.surFee !== null && this.state.formdata.paymentMethod === 'CC';
    const isBillingPage = this.props.location.pathname.toUpperCase() === '/BILLING'
    if (isBillingPage && isSurcharge && context.customer !== null && this.state.formdata.state.length !== 2) {
      if (this.state.message === '') {
        this.setMessages('Please enter a valid Billing State');
      }

      return false;
    }

    if (this.state.message !== '') {
      this.setState({
        message: ''
      });
    }

    return true;
  }

  render() {

    let logoUrl = process.env.API_URL + '/v1/guest/get_merchant_logo?m=' + this.state.merchantID

    return (
      <div className='payment-link container'>

  <Switch>

    <Route path="/Exception" component={ExceptionHandler} />

    <Route path="/" render={
      () => (

        <UserContextProvider>
          <UserContext.Consumer>
          {
            (context) => {
              const internalCustomerId = context.customer ? context.customer.InternalCustomerID : '';

            return (
              <div>
                <div>
                 
                  {this.state.surFee ? (
                    <input
                      type="hidden"
                      id="internalCustomerId"
                      value={ internalCustomerId  }
                      onChange={this.handleSurchargeCustomerLogin}
                    />
                  ) : null }
                </div>
                <div className='row'>
                  <div className='col-md-2'></div>
                  <div className='col-md-8'>
                  {
                    this.state.isUpdatingProfile ? <UpdateStoredProfileForm
                      hasACH={this.state.formdata.hasACH}
                      context={context}
                      parentFormData={this.state.formdata}
                      onSubmit={(newFormdata) => {
                        // Delete extra property from the saved customer data form
                        delete newFormdata.zip;

                        // Merge new customer data with existing
                        // form data, then update surcharge information (if applicable)
                        this.setState((prev) => ({
                          formdata: {
                            ...prev.formdata,
                            ...newFormdata,
                          }
                        }), this.updateSurchargeFields)
                      }}
                      close={(resp) => this.setState({
                        isUpdatingProfile: false,
                        profileSaveResponse: resp,
                      })}
                    /> : null
                  }
                  </div>
                  <div className='col-md-2'></div>

                </div>
                {
                  // EVRY-254: don't show payment form if payment link is disabled for merchant;
                  // display error message instead
                  this.state.isPaymentLinkEnabled ?
                    <PaymentForm
                      message={this.state.message}
                      setMessages={this.setMessages}

                      totalPaymentControl={this.renderTotalPaymentControl()}
                      navigationPageHeader={this.renderPageHeader()}
                      navigationBreadCrumbs={this.renderBreadCrumbs()}

                      recurringEnabled={this.state.recurringEnabled}
                      allowUserLogin={this.state.allowUserLogin}

                      saleCeilingAmount={this.state.saleCeilingAmount}
                      surchargeAmount={this.state.surchargeAmount}
                      formdata={this.state.formdata}
                      allowInvoice={this.state.allowInvoice}
                      requireInvoice={this.state.requireInvoice}
                      logoUrl={logoUrl}
                      contactInfo={this.state.contactInfo}
                      handleViewTerms={this.handleViewTerms}
                      primaryColor={this.state.primaryColor}
                      secondaryColor={this.state.secondaryColor}
                      termsLink={this.state.termsLink}
                      customTitle={this.state.customTitle}
                      isProcessing={this.state.isProcessing}

                      isInvoiceReadOnly={this.state.invoiceReadOnly}
                      isAmountReadOnly={this.state.amountReadOnly}

                      onSetFieldsForUserProfile={this.onSetFieldsForUserProfile}

                      storedCustomer={context}

                      onClearMessage={() => this.setState({profileSaveResponse: ''})}
                      accountSavedMessage={this.state.profileSaveResponse}
                      onUpdatingStoredCustomerProfile={() => this.setState({isUpdatingProfile: true})}
                      onPaymentPayonChange={this.onPaymentPayonChange}
                      onDurationChange={this.onDurationChange}
                      onFrequencyChange={this.onFrequencyChange}
                      onNumberOfPaymentsChange={this.onNumberOfPaymentsChange}
                      disableSubmit={!this.canContinue(context)}
                      addRecaptchaTokenToState={this.addRecaptchaTokenToState}

                      {...this.onEvents()}
                    /> :
                    <FlashNotification
                      type='warning'
                      message={this.state.message}
                      onClose={(evt) => {}}
                    />
                }
              </div>
            );
          }}
          </UserContext.Consumer>
        </UserContextProvider>
          )}/>


  </Switch>

        <Footer/>

      </div>
    );
  }
}


export default PaymentLink;
