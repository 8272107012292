import React, { Component } from 'react';
import {default as RadioButton} from '../../CustomButton';
import Icon from '../../Icon';
import './PaymentProcessMethod.css';

export const ProcessMethod = {
  ONE_TIME: 'OneTime',
  RECURRING: 'Recurring'
}

// Not to be confused with PaymentType credit card or ACH
class PaymentProcessMethod extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
    this.onProcessingMethodChange = this.onProcessingMethodChange.bind(this);
  }
  
  onProcessingMethodChange(value) {
    if (this.props.onProcessingMethodChange) {
      this.props.onProcessingMethodChange(value);
    }
  }

  render() {
    return (
      <div className="row no-margin">
        <div className="col-md-6">
          <RadioButton
                      name='processingMethodChoice'
                      onClick={this.onProcessingMethodChange} 
                      labelText="One-Time"
                      value={ProcessMethod.ONE_TIME}
                      {...this.props.formdata.processingMethod == ProcessMethod.ONE_TIME && {selected:true}}>
            <Icon img='OneTime.svg' height='32px' width='32px' />
          </RadioButton>
        </div>
        <div className="col-md-6" >
          <RadioButton
                      name='processingMethodChoice'
                      onClick={this.onProcessingMethodChange} 
                      labelText="Recurring"
                      value={ProcessMethod.RECURRING}
                      {...this.props.formdata.processingMethod == ProcessMethod.RECURRING && {selected:true}}>
            <Icon img='Recurring.svg' height='32px' width='32px' />
          </RadioButton>
        </div>

      </div>
    );
  }
}

export default PaymentProcessMethod;
