import React, { Component } from 'react';


class TableRow extends Component {
  constructor(props){
    super(props);
  }
  render() {
    const id = this.props.id;
    const value = this.props.value;
    return (
      <tr id={id}>
        <td>{ value['title'] }</td><td>{ value['value'] }</td>
      </tr>
    )
  }
}


class Table extends Component {
  constructor(props){
    super(props);
  }
  render() {
    const id = 'total';
    const rows = this.props.rows;
    const classNames = ['table'];
    return (
      <div>
        <table className={`table-responsive-md`}>
          <tbody>
            {
              this.props.rows.map(function(item, idx) {
                const key = Object.keys(item)[0];
                return (
                  <TableRow id={ key } key={ key } value={ item[key] }/>
                )
              })
            }
          </tbody>
        </table>
      </div>
    );
  }
}

export default Table;
