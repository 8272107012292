import React, { Component } from 'react';
import { withFormsy } from 'formsy-react';

class MultiRadioInput extends Component {
  constructor(props){
    super(props);
    this.state = {
    }
    this.onChange = this.onChange.bind(this);
  }
  
  componentWillUnmount() {
    // console.log(this.props)
    if (this.props.onChange) {
      this.props.onChange(this.props.getValue(), this.props.id);
    }
  }

  onChange(event) {
    const selectedOptions = Array.isArray(this.props.getValue()) ? this.props.getValue() : [];//todo use props types lib to make sure its supplied as an array type
    if (event.currentTarget.checked) {
      // make a new array and push new item because we don't want to set the state with the original value
      var newArray = selectedOptions.slice(0);
      newArray.push(event.currentTarget.value);
    } else {
      // make a new array and remove item because we don't want to set the state with the original value
      var newArray = selectedOptions.filter(function(value, index, arr){
        return value != event.currentTarget.value;
      });
    }
    this.props.setValue(newArray);
  }

  // there doesn't seem to be an onBlur event for react checkboxes.
  // the react hook shouldComponentUpdate might be a place you can do it but might not be able to use pure components.
  // so the required error messages will show initially if nothing is checked initially and required.
  validate() {
    const errorMessage = this.props.getErrorMessage();
    this.setState({
      errorMessage: errorMessage,
    });
    if (this.props.isValid()) {
      // event.currentTarget.setCustomValidity('');
    } else {
      // event.currentTarget.setCustomValidity(errorMessage);
    }
  }

  showErrorMessage() {
    if (this.props.getErrorMessage()) {
      return <span className='field-error'>({ this.props.getErrorMessage() })</span>
    } else {
      return null;
    }
  }

  render(){
    const selectedOptions = Array.isArray(this.props.getValue()) ? this.props.getValue() : [];//todo use props types lib to make sure its supplied as an array type
    const requiredColor = this.props.isValid() ? null : { color: 'red' }
    const requiredMark = this.props.required ? <span className='required-mark' style={ requiredColor }>*</span> : null;
    return (
      <div>
      <label>{this.props.label} {requiredMark} {this.showErrorMessage()}</label>
      {
        this.props.options.map(function(option, idx) 
        {
          return (
            <div key={idx}>
              <input type='checkbox'
                value={option.id}
                data-disc-type={`disc_select_${idx+1}`}
                checked={selectedOptions.includes(option.id)} //option.selected
                onChange={this.onChange}
              />
              <span>{option.display}</span>
            </div>
          )
        }, this)
      }
      </div>
    )
  }
}

export default withFormsy(MultiRadioInput);
