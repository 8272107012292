import Styled, {keyframes, css} from 'styled-components';

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
`;