/*
  Name:
  Login {Widget}

  Description:
  Login thing where user can navigate to login view.
*/
import React, { Component } from 'react';
import UserContextProvider, { UserContext } from './UserContext';
import Styled, {keyframes, css} from 'styled-components';
import Icon from '../Icon';
import {fadeOut} from '../Styles';
import './login.css'


const ButtonLink = Styled.label`
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

export const LoginResult = Styled.div`
  display: ${props => props.animateMessage ? 'inherit' : 'none'}
  height: 200px;
  animation-name: ${fadeOut};
  animation-duration: 2s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  animation-play-state: running;
`;
LoginResult.defaultProps = {
  animateMessage: true,
}

export class LoginWidget extends Component {
  constructor(props){
    super(props);

    this.state = {
      isEnabled: false,
      animateMessage: false,
      username: null,
      password: null,
      message: null,
    }
    
    this.openLogin = this.openLogin.bind(this);
    this.closeLogin = this.closeLogin.bind(this);
    this.login = this.login.bind(this);
    this.onLoginKeyPress = this.onLoginKeyPress.bind(this);
    this.onUserNameChange = this.onUserNameChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onForgotPassword = this.onForgotPassword.bind(this);
    
    this.usernameRef = React.createRef();
    this.passwordRef = React.createRef();
  }
  
  componentDidMount() {
    this.usernameRef.current.focus();
  }
  
  onMessageAnimationEnd(evt) {
    //console.log(evt);
  }
  
  onPasswordChange(evt) {
    this.setState({
      password: evt.target.value,
      message: '',
    })
  }
  
  onUserNameChange(evt) {
    this.setState({
      username: evt.target.value,
      message: '',
    })
  }
  
  openLogin(evt) {
    this.setState({
      isEnabled: true,
      message: '',
    });
  }
  
  closeLogin(evt) {

    this.usernameRef.current.value = "";
    this.passwordRef.current.value = "";
    this.setState({
      isEnabled: false,
      message: '',
    });
  }
  
  onForgotPassword(evt) {
    const username = this.usernameRef.current.value;
    if (username.trim() == "") {
      this.setState({
        message: "Please input your email address.",
      });
    } else {
      const forgotLoginFn = this.context.forgotLogin;
      if (forgotLoginFn) {
        forgotLoginFn(username).then(function(result) {
          this.setState({
            message: result.message,
          });
        }.bind(this)).catch(function(result) {
          this.setState({
            message: "unexpected error. please contact admin.",
          });
        });
      }
    }
  }
  
  onLoginKeyPress(evt) {
    if (evt.key === 'Enter') {
      console.log("logging in")
      this.login(evt);
    }
  }
  
  login(evt){
    const username = this.usernameRef.current.value;
    const password = this.passwordRef.current.value;
    if (username.trim() == "" || password.trim() == "") {
      this.setState({
        message: "enter valid email and password",
      });
    } else {
      const loginFn = this.context.login;// do not like how the scope is outside function
      if (loginFn) {
        loginFn(username, password).then(function(result) {
          if (result.success) {

            if (this.props.onSetFieldsForUserProfile) {
              this.props.onSetFieldsForUserProfile(result);
            }

            this.setState({
              isEnabled: false,
              animateMessage: true,
            });
          } else {
            this.setState({
              message: result.message ? result.message : "Failed Login.",
            });
          }
        }.bind(this));
      }
    }
  }
  
  render() {
    const enabled = this.state.isEnabled ? "enabled" : "disabled";
    const loginClass = `login-popup ${enabled}`
    return (
      <div>
        <UserContext.Consumer>
        {
          // missing context.token means user is not logged in.
          (context) => {
            return (
              (context.token == null) ? (
                <div className='login-widget'>
                  <Icon img="ReturningUser.svg" width='32px' height='32px' />
                  <div className='login-message'>
                    <div>Returning User?</div>
                    <div>Log in to pre-fill your</div>
                    <div>information and submit </div>
                    <div>a payment</div>
                    <div className='login-link' onClick={this.openLogin}>Log In</div>
                  </div>
                </div>
              ) : (
                <LoginResult
                  className='login-widget'
                  animateMessage={this.state.animateMessage}
                  onAnimationEnd={this.onMessageAnimationEnd}>
                  <div>
                    <div></div>
                  </div>
                </LoginResult>
              )
            )
          }
        }
        </UserContext.Consumer>
      
        <UserContext.Consumer>
        {
          (context) => {
            this.context = context;
            return (
              <div className={loginClass}>
                <div>
                  <div>
                    <h1>Returning Users</h1>
                    <span>Log into your account to retrieve your saved information</span>
                  </div>
                  <br/>
                  <div>
                    <label>Email</label>
                    <input type='text' id='username' name='username' ref={this.usernameRef} />
                  </div>
                  <br/>
                  <div>
                    <label>Password</label>
                    <input onKeyPress={this.onLoginKeyPress} type='password' id='password' name='password' ref={this.passwordRef} />
                  </div>
                  <br/>
                  <span>&nbsp;&nbsp;</span>
                  <button className="btn btn-primary" onClick={this.login}>Login</button>
                  <button style={{float:'left'}} className="btn btn-secondary" onClick={this.closeLogin}>Cancel</button>
                  <span style={{color: 'red', paddingLeft: '10px'}}>{this.state.message}</span>
                  <span style={{paddingLeft: '10px'}}><ButtonLink onClick={this.onForgotPassword}>forgot password</ButtonLink></span>
                </div>
              </div>
            )
          }
        }
        </UserContext.Consumer>
      </div>
    )
  }
}

export default LoginWidget;
