// ExpirationDateInput
//
//  desc:
//    expiration control for CC
//
//  props:
//    title:              (optional) label text for exp input box
//    tooltipText:        (optional) tool tip for exp input box
//    value:              (optional) the exp value
//    textBoxWidth:       (optional) with of the input box
//    id:                 (required) used to proprogate changes to the data model
//    name:               (optional) name of the input control
//    maxLength:          (optional) maximum allowed length of input box value
//    onChange:           (optional) a callback function for when the user has changes input to update model
//    onValidate:         (optional) a callback function to valid user input 
//    isValid:            (optional) if true, will highlight red border around input box.
//

import React, { Component } from 'react';
import { DateUtils } from "react-day-picker";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import { Validation, Change, Rule } from '../Utility';
import { withFormsy } from 'formsy-react';
import './ExpirationDateInput.css';


// moved from the PaymentForm component
export const validateExp = function(exp) {
  // regular expression does not text month greater than 12
  return exp !== undefined && /^[0-1]?[0-9]\/(?:[0-9]{2})?[0-9]{2}$/g.test(exp);
}

export const validateExpirationDate = function(value, maxLength)
{
  var tempValue = value;
  // verify the required length first
  if(tempValue.length > maxLength) {
    return false;
  }
  // to check date format mm/yy
  // Also include mm value as 1 or 01
  var regex = /^((0?[1-9])|(1[0-2]))\/(\d{2})$/;
  if (regex.test(tempValue)) {
    return true;
  }
  return false;
}
  
export const validateFutureDate = function(value)
{
  // shit this.futureDate breaks rules. for now just writing it twice
  var currentDate = new Date();
  var currentMonth = currentDate.getMonth() + 1 ;
  var currentYear = parseInt(currentDate.getFullYear().toString().substr(-2));
  
  // Assumption : value will be in valid format - mm/yy
  const tempDate = value.split("/");
  const mm = parseInt(tempDate[0]);
  const yy = parseInt(tempDate[1]);

  // Assumption: value should have valid month between 1 to 12, based on the regex.
  if( (mm >= currentMonth && yy >= currentYear) || (mm <= 12 && yy > currentYear) )
  {
    return true;
  }
  else 
  {
    return false; 
  }
}
  
export const futureDate = function() {
  var currentDate = new Date();
  var currentMonth = currentDate.getMonth() + 1 ;
  var currentYear = currentDate.getFullYear();
  return [currentMonth, currentYear];
}



class ExpirationDateInput extends React.PureComponent {
  constructor(props)
  {
    super(props);
    this.state = {
      errorMessage: null,
      inError: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentWillUnmount() {
    if (this.props.onChange) {
     this.props.onChange(this.props.getValue());
    }
  }

  handleChange(event)
  {
    const number = event.currentTarget.value;
    // input restriction
    if ( /^[0-9]{0,2}\/{0,1}[0-9]{0,2}$/.test(number) ) {
      this.props.setValue(number);
    }
  }
   
  handleBlur(event)
  {
    const errorMessage = this.props.getErrorMessage();
    this.setState({
      errorMessage: errorMessage,
    });   
    if (this.props.isValid()) {
      event.currentTarget.setCustomValidity('');
    } else {
      event.currentTarget.setCustomValidity(errorMessage);
    }
  }
   
  validateExpirationDate(value, maxLength)
  {
    var tempValue = value;
    // verify the required length first
    if(tempValue.length > maxLength) {
      return false;
    }
    // to check date format mm/yy
    // Also include mm value as 1 or 01
    var regex = /^((0?[1-9])|(1[0-2]))\/(\d{2})$/;
    if (regex.test(tempValue)) {
      return true;
    }
    return false;
  }
  
  validateFutureDate(value)
  {
    // shit this.futureDate breaks rules. for now just writing it twice
    var currentDate = new Date();
    var currentMonth = currentDate.getMonth() + 1 ;
    var currentYear = parseInt(currentDate.getFullYear().toString().substr(-2));
    
    // Assumption : value will be in valid format - mm/yy
    const tempDate = value.split("/");
    const mm = parseInt(tempDate[0]);
    const yy = parseInt(tempDate[1]);

    // Assumption: value should have valid month between 1 to 12, based on the regex.
    if( (mm >= currentMonth && yy >= currentYear) || (mm <= 12 && yy > currentYear) )
    {
      return true;
    }
    else 
    {
      return false; 
    }
  }
  
  futureDate() {
    var currentDate = new Date();
    var currentMonth = currentDate.getMonth() + 1 ;
    var currentYear = parseInt(currentDate.getFullYear().toString().substr(-2));
    return [currentMonth, currentYear];
  }

  showErrorMessage() {
    return this.state.errorMessage ? 
      <span className='field-error'>({ this.state.errorMessage })</span> :
      null;
  }

  render() {
    const tooltip = this.props.tooltipText;
    const expValue = this.props.getValue() || '';
    const textboxWidth = this.props.textBoxWidth;
    const placeHolder = this.futureDate().join('/');
    const inputName = this.props.name;
    const maxLength = this.props.maxLength;
    const style = {
      width:textboxWidth,
      border: this.props.isValid ? null : "2px solid red"
    }
    const id = this.props.id;
    const name = this.props.name;
    const requiredColor = this.props.isValid() ? null : { color: 'red' }
    const requiredMark = this.props.required ? <span className='required-mark' style={ requiredColor }>*</span> : null;
    return (
      <div className='expirationDateInput'>
        <div>
          <label htmlFor={id}>
            {this.props.title}
          </label>
          {requiredMark}
          {this.showErrorMessage()}
        </div>
        <input
          id={ id }
          name={ name }
          type="text"
          style={ style }
          value={ expValue }
          placeholder={ placeHolder }
          onChange={ this.handleChange }
          onBlur={ this.handleBlur }
          name={ inputName }
          maxLength={ maxLength }
          title={ tooltip } />
      </div>
    );
  }
}

export default withFormsy(ExpirationDateInput);
