// PasswordNew
//
//  desc:
//    Provide two password input boxes for user.
//    Validates password and ensures they match. 
//    Gives error message to user with invalid password     
//    Calls parent callaback with valid password
//
//  props:
//    isPasswordValidCallback:      This callback should accept a boolean that indicates if the password is valid or not. 
//                                    Until this callback is called, parent should assume that password is not valid
//                                    As the user enters text, this callback could be called multiple times as the valid state of the password changes
//

import React, { Component } from 'react';
import { withFormsy } from 'formsy-react';
import './index.css';
import PasswordInput from '../PasswordInput';


const PasswordNewStatusEnum = {
  none: 0,                      //the none case is meant to handle the situation where the user hasnt started typing any kind of password yet... essentially, the default case
  ok: 1, 
  error_too_short: 2, 
  error_too_long: 3, 
  error_missing_alpha: 4, 
  error_missing_numeric: 5, 
  error_mismatch: 6, 
  properties:{
    0: {value: 1, message: ""},
    1: {value: 1, message: ""},
    2: {value: 2, message: "Password must be at least 8 characters and less than 25"},
    3: {value: 3, message: "Password must be at least 8 characters and less than 25"},
    4: {value: 4, message: "Password must contain at least 1 alpha and 1 numeric"},
    5: {value: 5, message: "Password must contain at least 1 alpha and 1 numeric"},
    6: {value: 6, message: "Passwords do not match"},
  }
};


class PasswordNew extends Component {
  constructor(props)
  {
    super(props)

    this.state = {
      wasValidOnce:   false,             //using this variable to indicate when we should call the parent callback for isvalid. Dont want to spam the parent until we are valid at least once
      password:       null,
      confirm:        null,
      passwordHadFocus:   false,        //by default, the first password field has yet to gain and lose focus
    };

    this.onPasswordTextInput = this.onPasswordTextInput.bind(this);
    this.onConfirmTextInput = this.onConfirmTextInput.bind(this);
    this.myRef = React.createRef();
  }


  onPasswordTextInput(password)
  { 
    this.setState({password: password});

    if (this.state.wasValidOnce == true)
    { //at one point this was valid, so double check validation on every input 
      if (this.areBothPasswordsValid(password, this.state.confirm) == true)
      {
        // there is a reason why not setting the value to the text because of how validation
        // for component is written.
        // Note: So this makes it so the formsy model does not have the password value.
        this.props.setValue(true);
        this.onPasswordValidChange(true);
      }
      else
      {
        this.props.setValue(null);
        this.onPasswordValidChange(false);
      }
    }
  }

  // calls formsy setValue method to trigger validate event
  // Shouldn't need to do this but do not want to refactor this component yet.
  onConfirmTextInput(confirm)
  {
    this.setState({confirm: confirm});
    var status = this.areBothPasswordsValid(this.state.password, confirm)
    if (status == true)
    {
      // just calling this to have formsy trigger validation
      this.props.setValue(true);
      this.onPasswordValidChange(true);
      this.setState({wasValidOnce: true});
    }
    else
    {
      this.props.setValue(null);
      this.onPasswordValidChange(false);
    }
  }

  // Every time both passwords match and each are valid, the registered
  // callback is called to update password in the application state.
  onPasswordValidChange(isValid)
  {
    if (this.props.isPasswordValidCallback)
    {
      this.props.isPasswordValidCallback(isValid, this.state.password)
    }
  }

  areBothPasswordsValid(password, confirm)
  {
    var retVal = false;
    if (this.checkPasswordInputForError(password))
    { //only check to see if the passwords match if the original password is correct to begin with
      var status = this.testPasswordMatch(password, confirm);
      if (status == PasswordNewStatusEnum.ok)
      {
        retVal = true;
      }   
    }
    return retVal;
  }

  testPasswordMatch(password, confirm)
  {
    var status = PasswordNewStatusEnum.ok
    if (password != confirm)
    {
      status = PasswordNewStatusEnum.error_mismatch;
    }
    return status;
  }

  testPasswordPattern(password)
  {
    //tests are broken out individually (as opposed to one big regex) to allow for easy changes and for error messages relating to specific misses 
    if (password == null)
    {
      return PasswordNewStatusEnum.none;        
    }
    if (password.length < 8)
    {
      return PasswordNewStatusEnum.error_too_short;
    }

    if (password.length > 25)
    {
      return PasswordNewStatusEnum.error_too_long;
    }

    //check for alphas (either upper or lower, at least 1)
    var re = /([a-z]|[A-Z])/g
    var foundMatch = password.match(re)
    if (foundMatch == null)
    {
      return PasswordNewStatusEnum.error_missing_alpha;
    }

    //check for numerics 
    var re = /([0-9])/g
    var foundMatch = password.match(re)
    if (foundMatch == null)
    {
      return PasswordNewStatusEnum.error_missing_numeric;
    }

    //TODO: a future check for special characters? does our backend support it? alphas and digits only? 

    //everything seems good! or we are missing a check :) 
    return PasswordNewStatusEnum.ok;

  }

  //return true if there is a error, false if is valid... 
  checkPasswordInputForError(password)
  {
    var status = this.testPasswordPattern(password);  
    if (status == PasswordNewStatusEnum.ok || status == PasswordNewStatusEnum.none)
    {
      return true;
    }   
    return false;
  }

  checkConfirmPasswordInputForError(confirm)
  {
    if (this.state.password)
    {
      var status = this.testPasswordPattern(this.state.password)
      if (status == PasswordNewStatusEnum.ok)
      { //only check to see if the passwords match if the original password is correct to begin with
        status = this.testPasswordMatch(this.state.password, confirm);   
        if (status == PasswordNewStatusEnum.ok)
        {
          return true  //verified that passwords match and there are no errors
        }    
      }
      else
      { //since the main password is in error, do not worry about the validation of this field yet, so return false to indicate that this field is good for now
        return true;
      }
    }
    else 
    {
      return true; //if there is no main password yet, we dont need to worry about a match
    }

    return false
  }


  getStatusMessageDiv()
  {
    var status = this.testPasswordPattern(this.state.password)
    if (status == PasswordNewStatusEnum.ok)
    { //only check to see if the passwords match if the original password is correct to begin with
      status = this.testPasswordMatch(this.state.password, this.state.confirm);   
      if (status == PasswordNewStatusEnum.ok)
      { //can place an ok status message here, if desired 

      }
    }
    var divClasses = 'passwordNew_statusMsg';
    if (status != PasswordNewStatusEnum.ok)
    { //there was an error, so we want to add an error class to the div to highlight the text red
      divClasses += ' ' + 'passwordNew_ErroMsg'   
    }
    return <div className={divClasses}> {PasswordNewStatusEnum.properties[status].message} </div>
  }


  render() {
    return (
      <div className='passwordNew'>
        <div ref={this.myRef} className='field-error' style={{paddingTop: '1px', paddingBottom: '25px', fontSize: '9pt'}}>
          {this.props.getErrorMessage()}
        </div>
        <PasswordInput title="Password" 
              onTextInputCallback={this.onPasswordTextInput} 
              pattern="[0-9a-zA-Z]{4,25}" 
              minLength="8"
              maxLength="25"
              inError={!this.checkPasswordInputForError(this.state.password)}
              password={this.state.password}/>

        <PasswordInput title="Confirm Password" 
              onTextInputCallback={this.onConfirmTextInput}
              pattern="[0-9a-zA-Z]{4,25}" 
              minLength="8"
              maxLength="25"
              inError={!this.checkConfirmPasswordInputForError(this.state.confirm)}
              password={this.state.confirm}/>
        {this.getStatusMessageDiv()}
      </div>
     );
  }
}

export default withFormsy(PasswordNew);
