import React, { Component } from 'react';

import PaymentLink from './SiteTemplate/PaymentLink';
import ScratchPad from './ScratchPad';

import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";


class App extends Component {
  constructor(props){
    super(props);
  }
  render() {
    return (
      <Router>
        <div className='app'>
          <Route path='/' component={ PaymentLink } />
        </div>
      </Router>
    );
  }
}

export default App;
