// Model representing all the data for the payment link application.
// note: not the actual react state of the payment link application.
// When we do clean and break up the payment link state the mapping
// to the state is what we need to update which is done in the service.
// I guess it can be though of as PaymentLinkModel is the bridge between
// the payment link param query fetched from server and the react component state(s)
// controlling the UI components. Could of tied the param query data directly to the
// react component(s) state but trying to keep it abstract. Needs work.

class PaymentLinkModel {
  // merchantID = null;
  // primaryColor = null;
  // secondaryColor = null;
  // companyInfoName = null;
  // companyInfoWebsite = null;
  // companyInfoEmail = null;
  // billingAmount = null;
  // billingCheckingAccount = null;

  constructor(jsonObject) {
    // I don't like this and kinda wacky but best thing i can think of now. 
    // If json object is not null then map it to this model
    // This model is used later
    // to build the state for the payment link. Keeping the
    // model and state separate for now so easier to refactor later.
    if (jsonObject != null) {
      this.merchantID = jsonObject.m;
      this.primaryColor = jsonObject.p;
      this.secondaryColor = jsonObject.s;

      this.termsLink = jsonObject.other.TermsLink;
      this.convenienceFeeAmount = jsonObject.other.ConvenienceFeeAmount;
      this.convenienceFeeLabel = jsonObject.other.ConvenienceFeeLabel;
      this.convenienceFeeType = jsonObject.other.ConvenienceFeeType;
      this.defaultTransactionType = jsonObject.other.DefaultTransactionType;
      this.allowReturningCustomersFlag = jsonObject.other.AllowReturningCustomersFlag === 'true';

      this.companyInfoName = jsonObject.companyInfo.name;
      this.companyInfoWebsite = jsonObject.companyInfo.website
      this.companyInfoEmail = jsonObject.companyInfo.email;
      this.billingAmount = jsonObject.billing.amount;
      this.billingCheckingAccount = jsonObject.billing.checkingAccount;
      this.billingCC = jsonObject.billing.cc;
      this.billingAddress = jsonObject.billing.address;
      this.billingCity = jsonObject.billing.city;
      this.billingState = jsonObject.billing.state;
      this.billingCountry = jsonObject.billing.country;
      this.billingZipcode = jsonObject.billing.zipcode;
    }
  }
}

export default PaymentLinkModel;