// HeaderSection
//
//  desc:
//    main header of payment form
//
//  props:
//    bgcolor:              (optional) background color 
//

import React, { Component } from 'react';
import './FormHeader.css';

class HeaderSection extends Component {
  constructor(props){
    super(props);
  }

  render() {
    return (
      <div className='paymentform-header' style={{backgroundColor: this.props.bgcolor}}>
      </div>
    );
  }
}

export default HeaderSection;

