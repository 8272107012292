// Address
//
//  desc:
//    provides max of 2 address fields (the street part)
//
//  props:
//    values:               (optional) if provided, and array of streets with objects for value and placeholder for AddressInput component. 
//    onchange:             (optional) if provided, will handle change events to update and validate controlled data
//
import React, { Component } from 'react';
import AddressInput from './AddressInput';
import AddressLink from './AddressLink';
import {Change, Validation, Rule} from '../Utility';
import { withFormsy } from 'formsy-react';

class Address extends React.PureComponent {
  constructor(props)
  {
    super(props);
    this.state = {
      count: this.props.getValue().length
    };
    this.handleAddAddress = this.handleAddAddress.bind(this);
    this.handleAddressValidate = this.handleAddressValidate.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
  }
     
  componentWillUnmount() {
    if (this.props.onChange) {
     this.props.onChange(this.props.getValue());
    }
  }

  createAddressInputBox() {
    const addresses = this.props.getValue() || [];
    // return a new array of transformed array of components
    return addresses.map(function(addr, idx) {
      // console.log("create address textbox for value: ", addr);
      
      return <input
          key={idx}
          id={`address_${idx}`}
          index={idx}
          name={`address_${idx}`}
          type = "text"
          required={idx == 0}
          value={addr}
          onChange={this.handleAddressChange}
          onBlur={this.handleAddressValidate}
          placeholder = {this.props.placeholderText}
          maxLength = {this.props.maxLength}
      />
    }.bind(this));
  }

  handleAddressValidate(event){
    const errorMessage = this.props.getErrorMessage();

    this.setState({
      errorMessage: errorMessage,
    });   
    if (this.props.isValid()) {
      event.currentTarget.setCustomValidity('');
    } else {
      event.currentTarget.setCustomValidity(errorMessage);
    }
  }

  handleAddressChange(event) {
    const address = event.currentTarget.value;
    const id = event.currentTarget.id;
    this.updateAddress(address, id);
  }

  handleAddAddress(event) {
    const incrementedCount = this.state.count + 1;
    this.setState({
      count: incrementedCount
    });

    this.addAddress('', incrementedCount)
  }
  
  updateAddress(value, id) {
    const index = id.split('_')[1];
    const addresses = this.props.getValue();
    const addressesCopy = addresses.slice(0);
    addressesCopy[index] = value;
    this.props.setValue(addressesCopy);
  }
  
  addAddress(value) {
    const addresses = this.props.getValue();
    const addressesCopy = addresses.slice(0);
    addressesCopy.push(value);
    this.props.setValue(addressesCopy);
  }

  showErrorMessage() {
    return this.state.errorMessage ? 
      <span className='field-error'>({ this.state.errorMessage })</span> :
      null;
  }

  render() {
    const requiredColor = this.props.isValid() ? null : { color: 'red' }
    const requiredMark = this.props.required ? <span className='required-mark' style={ requiredColor }>*</span> : null
    return (
      <div className="Address">
        <div>
          <label htmlFor='address_0'>{this.props.label}</label>
          {requiredMark}
          {this.showErrorMessage()}
        </div>
        { this.createAddressInputBox() }
        { this.state.count < 2 ? <AddressLink onClick={this.handleAddAddress} /> : null }
      </div>
    );
  }
}

export default withFormsy(Address);

