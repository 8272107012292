import React, { Component } from 'react';
import { withFormsy } from 'formsy-react';


class DurationInput extends Component {
  constructor(props){
    super(props);
  }

  // reset number of payment value to empty if toggled to continual.
  onDurationChange(duration) {
    this.props.setValue(duration);
    if (this.props.onDurationChange) {
      this.props.onDurationChange(duration);
      // this.props.onNumberOfPaymentsChange(null);
    }
  }

  render() {
    const value = this.props.getValue();
    return (
      <div>
        <label>
          {this.props.label}
        </label>
        <button className='flat'
            onClick={(evt) => this.onDurationChange('continual')}
            style={{height: '50px', marginRight: '5px'}}>
          <div>
            <input readOnly
                checked={value == 'continual' || !value}
                value='continual'
                // name='duration'
                type='radio'
                style={{margin: '5px'}}/>
            Continual
          </div>
        </button>

        <button className='flat'
            onClick={(evt) => this.onDurationChange('limited')}
            style={{height: '50px', marginLeft: '5px'}}>
          <div>
            <input readOnly 
              checked={value == 'limited'}
              value='limited'
              // name='duration'
              type='radio'
              style={{margin: '5px'}}/>
            Limited Cycles
          </div>
        </button>
      </div>
    );
  }
}

export default withFormsy(DurationInput);
