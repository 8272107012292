import React, { Component } from 'react';
import './AddressLink.css';


class AddressLink extends Component {
  constructor(props)
  {
    super(props);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick(event){
    event.preventDefault();
    this.props.onClick();
  }
  


  render() {
    return (
      <div className="AddressLink">
        <a href='#' onClick = {this.handleOnClick} >+ Add Apartment or Suite Number</a>
      </div>
    );
  }
}

export default AddressLink;
