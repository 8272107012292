// PasswordInput
//
//  desc:
//    provides a title and a password input box (with optional placeholder text)      
//
//
//  props:
//    title:                (optional) if provided, will display title above the input box
//    placeholderText:      (optional) if provided, will display ghosted placeholder text in the input box. Note: there is no placeholder text by default
//    password:             (optional) if provided, will set the the password text for the input box (will be marked out with ***)
//    inError:              if 'true', will display the password input in red 
//    hoverText:            (optional) text to display to the user when the mouse cursor hovers over the input box. By default, there is none
//    minLength:            (optional) the min length of the password from the user. Defaults to 0 for password input (as opposed to password creation)
//    maxLength:            (optional) the max length of the password from the user. Defaults to 25
//    onTextInputCallback:  a callback function for when the user has entered input
//    onFocusCallback:      (optional) a callback function for when the password input GAINS focus
//    onBlurCallback:       (optional) a callback function for when the password input LOSES focus
//

import React, { Component } from 'react';
import './index.css';


class PasswordInput extends Component {
  constructor(props)
  {
    super(props)

    this.state = {

    };

    this.onTextInput = this.onTextInput.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }


  getPlaceholderText()
  { //defaults to empty string if no prop provided
    return this.props.placeholderText != null ? this.props.placeholderText : ""
  }


  getHoverText()
  { //defaults to empty string if no prop provided
    return this.props.hoverText != null ? this.props.hoverText : ""
  }


  getMinLength()
  { //defaults to 0 if no prop provided
    return this.props.minLength != null ? this.props.minLength : 0
  }


  getMaxLength()
  { //defaults to 25 if no prop provided
    return this.props.minLength != null ? this.props.minLength : 25
  }

  getPassword()
  { //defaults to 25 if no prop provided
    return this.props.password != null ? this.props.password : ""
  }


  getPattern()
  { //defaults to 25 if no prop provided
    return this.props.pattern != null ? this.props.pattern : ""
  }


  getInputClasses()
  {
    var retClass = 'passwordInputBox';
    if (this.props.inError != null && this.props.inError == true)
    {
      retClass += ' passwordInput_passwordError'
    }
    return retClass;
  }
  

  onTextInput(evt)
  {
    let inputText = evt.target.value;
    if (this.props.onTextInputCallback != null)
    {
        this.props.onTextInputCallback(inputText);      
    }
  }


  onFocus()
  {
    if (this.props.onFocusIn != null)
    {
        this.props.onFocusIn();      
    }
  }


  onBlur()
  { 
    if (this.props.onFocusOut != null)
    {
        this.props.onFocusOut();      
    }
  }

  render() {
    return (
      <div className='passwordInput'>
        <div className='passwordInputTitle'>
          {this.props.title}
        </div>
        <input className={this.getInputClasses()} 
                onChange={this.onTextInput}
                title={this.getHoverText()} 
                onKeyPress={this.onKeyPress}
                placeholder={this.getPlaceholderText()}
                value={this.getPassword()}
                type="password" 
                minLength={this.getMinLength()}
                onFocus={this.onFocus()} 
                onBlur={this.onBlur()} />
      </div>
     );
  }
}

export default PasswordInput;
