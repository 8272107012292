// FormInput
//
//  desc:
//    generic input field
//
//  props:
//    value:                (optional) value of the input box
//    tooltipText:          (optional) tooltip of the input box
//    id:                   (required) id of the input box used to update the model
//    name:                 (optional) name of the control
//    maxLength:            (optional) maximum length of the value
//    required:             (optional) makes input box required. just uses html5 validation
//    placeholder:          (optional) placeholder of input box
//    icon:                 (optional) optional icon to place in inputbox
//    isValid:              (optional) if true, will highlight the input box in red
//    inputType:            (optional) can set the type of the input tag
//    width:                (optional) width of the input tag
//    pattern:              (optional) html5 pattern validation
//    {children}:           (optional) optional UI components can be added like labels for the input box.
//    onChange:             (optional) a callback function for when the user has entered input
//    onBlur:               (optional) a callback function for validating user input
//    isReadOnly:           (optional) indicates if the form input field should not be editable by the user
//

import React, { Component } from 'react';
import { Validation, Change } from '../Utility';
import { withFormsy } from 'formsy-react';
import './FormInput.css';

// TODO
// refactor the onchange and onblur events to just return the value.
class FormInput extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {
      //value: this.props.value
    }
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.myRef = React.createRef();
  }
  
  componentWillUnmount() {
    if (this.props.onChange) {
     this.props.onChange(this.props.getValue());
    }
  }
  
  onChange(event)
  {
    if (this.props.inputRestricted && this.props.inputRestricted(event.currentTarget.value))
    {
      return;
    }
    this.props.setValue(event.currentTarget.value);
  }
  
  onBlur(event)
  {
    const errorMessage = this.props.getErrorMessage();
    this.setState({
      errorMessage: errorMessage,
    });
    if (this.props.isValid()) {
      event.currentTarget.setCustomValidity('');
    } else {
      event.currentTarget.setCustomValidity(errorMessage);
    }
  }

  createIconInside() {
    if (this.props.icon) {
      return (
        <span>
          { this.props.icon }
        </span>
      )
    }
  }
  
  showErrorMessage() {
    return this.state.errorMessage ? 
      <span className='field-error'>({ this.state.errorMessage })</span> :
      null;
  }
  
  render() {
    const placeholder = this.props.placeholder
    const style = {};
    if (this.props.width) {
      style.width = this.props.width;
    }
    if (this.props.isValid !== undefined && !this.props.isValid) {
      style.border = "2px solid red";
    }
    
    const value = this.props.getValue() || '';//this.props.value || '';
    const tooltip = this.props.tooltipText;
    //const name = this.props.name;
    const maxLength = this.props.maxLength;
    const id = this.props.id;
    // const required = this.props.required;
    const inputType = this.props.inputType || 'text';
    const requiredColor = this.props.isValid() ? null : { color: 'red' };
    const requiredMark = this.props.required ? <span className='required-mark' style={ requiredColor }>*</span> : null;
    const isReadOnly = this.props.isReadOnly
    return (
      <div className="form-input">
        <div>
          <label htmlFor={id}>
          { this.props.label }
          { 
            // the UI is off a bit when labels are components
            requiredMark
          }
          { this.showErrorMessage() }
          </label>
        </div>
        { this.createIconInside() }
        <input
            ref={this.myRef}
            id={ id }
            type={ inputType }
            style={ style }
            value={ value }
            placeholder={ placeholder }
            //name={ name }
            maxLength={ maxLength }
            title={ tooltip }
            pattern={ this.props.pattern }
            onChange={ this.onChange }
            onBlur={ this.onBlur }
            readOnly= { isReadOnly }
            //required={ required }
        />
      </div>
    );
  }
}

export default withFormsy(FormInput);
