// PaymentSummary
//
//  desc:
//    summary step of the payment as a table.
//
//  props:
//    totalAmount
//    payon
//    paymentInfo
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import {EditButton} from '../Buttons';


class PaymentSummary extends Component {
  constructor(props){
    super(props);
  }
  
  render() {
    const { totalAmount, convenienceFee, surchargeAmount, paymentInfo, paymentMethod, payon } = this.props;
    const amount = totalAmount ? Number(totalAmount) : 0;
    const convFee = convenienceFee ? Number(convenienceFee) : 0;
    const surchargeAmt = surchargeAmount ? Number(surchargeAmount) : 0;
    const totalAmt = amount + convFee + surchargeAmt;
    
    return (
      <table className='table-responsive-md table-bordered table-hover table-sm'>
          <tbody>
            {surchargeAmt && paymentMethod === 'CC' ? (
              <React.Fragment>
                <tr>
                  <td style={{verticalAlign:'middle', textAlign:'left'}}>Transaction Amount</td>
                  <td style={{verticalAlign:'middle', textAlign:'left'}}>{ amount.toFixed(2) }</td>
                  <td style={{verticalAlign:'middle', textAlign:'left'}}>
                    <Link replace={false} to={{ pathname: '/Payment', state: {autoFocus: 'payment_amount'} }}>edit</Link>
                  </td>
                </tr>

                <tr>
                  <td style={{verticalAlign:'middle', textAlign:'left'}}>Surcharge Amount</td>
                  <td style={{verticalAlign:'middle', textAlign:'left'}}>{ surchargeAmt.toFixed(2) }</td>
                  <td style={{verticalAlign:'middle', textAlign:'left'}}/>
                </tr>
              </React.Fragment>
            ) : null}
           
            <tr>
              <td style={{verticalAlign:'middle', textAlign:'left'}}>Total</td>
              <td style={{verticalAlign:'middle', textAlign:'left'}}>{ totalAmt.toFixed(2) }</td>
              {surchargeAmt ? (
                <td style={{verticalAlign:'middle', textAlign:'left'}}/>
              ) : (
                <td style={{verticalAlign:'middle', textAlign:'left'}}>
                  <Link replace={false} to={{ pathname: '/Payment', state: {autoFocus: 'payment_amount'} }}>edit</Link>
                </td>
              )}
            </tr>
  
            <tr>
              <td style={{verticalAlign:'middle', textAlign:'left'}}>Payment Info</td>
              <td style={{verticalAlign:'middle', textAlign:'left'}}>{ paymentInfo }</td>
              <td style={{verticalAlign:'middle', textAlign:'left'}}>
                <Link replace={false} to={{ pathname: '/Billing', state: {autoFocus: 'billing_account'} }}>edit</Link>
              </td>
            </tr>
          </tbody>
        </table>
    )
  }
}

export default PaymentSummary;
