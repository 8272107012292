import React, { Component } from 'react';
import {ProcessMethod as PaymentType} from '../../components/common/react-component-library/PaymentForm/PaymentProcessMethod';
import {DateTimeUtility, Validation} from '../../components/common/react-component-library/Utility';
import {callToEndpoint} from '../../lib/comms/commsHelper';
import {submitPayment, customerLogin, customerLogout, customerLoginStatus, NewCustomerAccount} from '../../lib/comms';
import PaymentLinkModel from './model/PaymentLinkModel';

function validatePaymentAmount(data) {
  return data.amount.value > 0;
}

function validatePaymentPayOn(data) {
  const v = data.payon.value;
  return v != '' && !DateTimeUtility.isDayBeforeToday(v);
}

function validatePaymentFrequency(data) {
  // this.props.data[key].value
  return data.frequency.value > 0;
}

function validatePaymentDurationLimit(data) {
  return data.durationLimit.value > 0;
}

function validatePaymentCustomFrequencyCount(data) {
  return data.customFrequencyCount.value > 0;
}

function validateBillingName(data) {
  return data.name.value.toString().trim() != '';
}

function validateBillingCreditCardNumber(data) {
  return data.cc.value.trim() != '';
}

function validateBillingCreditCardExp(data) {
  let [month, year] = data.exp.value.toString().split('/');
  const today = new Date();
  const expdate = new Date();

  // exp valid until the last calendar day of todays month.
  expdate.setFullYear(year, month, 0);
  return data.exp.value.trim() != '' 
    && (expdate >= today);
}

function validateBillingSecurityCodeValid(data) {
  const value = data.securityCode.value.toString().trim();
  return value.length >= 3 && value.length <= 4
}

function validateBillingCheckingAccountNumber(data) {
  if (data.paymentMethod.value == 'CC') {
    return true;
  }
  const value = data.checkingAccount.value.toString();
  // account numbers can be any number. there is not standard.
  return value.length > 0 && value.length <= 20;
}

function validateBillingCheckingRoutingNumber(data) {
  if (data.paymentMethod.value == 'CC') {
    return true;
  }
  // ABA numbers are always 9
  const value = data.routingNumber.value.toString();
  return value.length > 0 && value.length <= 9;
}

function validateBillingZipCode(data) {
  const value = data.zipcode.value.trim();
  return value.length == 5 && !isNaN(value) && parseInt(value) > 0;
}

function validateBillingAddress(data) {
  const value = data.address.value;
  return Array.isArray(value) 
        && value.length > 0
        && value[0].trim().length > 0;
}

function validateBillingContactCity(data) {
  return data.city.value.trim() != '';
}

function validateBillingContactState(data) {
  return data.state.value.trim() != '';
}

function validateBillingContactPhone(data) {
  return data.phone.value.trim() != '';
}

function validateBillingContactEmail(data) {
  return data.email.value.trim() != '';
}

function validateBillingPassword(data) {
  return data.password.value != '' && data.password.valid;
}

function validateBillingRecurPassword(data) {
  return data.password.value != '';
}

function validateRecaptcha(data) {
  return data.recaptcha.value != null && data.recaptcha.valid;
}

function validateAgreementToTerms(data) {
  return data.agreeToTerms.value;
}

function validatePaymentStep(data) {
  let isCustomFrequencyCountValid = true; 
  if (data.frequency.value == 11) {
    isCustomFrequencyCountValid = validatePaymentCustomFrequencyCount(data);
  }
  let isPaymentDurationLimitValid = true;
  if (data.duration.value != 'continual') {
    isPaymentDurationLimitValid = validatePaymentDurationLimit(data)
  }
  return validatePaymentAmount(data)
    && validatePaymentPayOn(data)
    && validatePaymentFrequency(data)
    && isPaymentDurationLimitValid
    && isCustomFrequencyCountValid;
}

function validateBillingStep(data) {
  return validateBillingName(data)
    && validateBillingCreditCardNumber(data)
    && validateBillingCreditCardExp(data)
    && validateBillingSecurityCodeValid(data)
    && validateBillingCheckingAccountNumber(data)
    && validateBillingCheckingRoutingNumber(data)
    && validateBillingZipCode(data)
    && validateBillingAddress(data)
    && validateBillingContactCity(data)
    && validateBillingContactState(data)
    && validateBillingContactPhone(data)
    && validateBillingContactEmail(data);
}

function validateReviewStep(data) {
  return validateRecaptcha(data)
    && validateAgreementToTerms(data);
}

// Warning 
// Didn't want to refactor too much so made it so
// the event handler was bound to the component that uses it
// so 'this' keyword is the parent component. Also made this is a bad singleton.
// Beware of side effects. Side effects may include nausea and diarrhea.
export const PaymentLinkService = (function(){
  const PRIMARY = '#4696CA';
  const SECONDARY = '#B2B7C3';

  var logo = () => {
    return localStorage.getItem("companylogo") || null;
  }
  
  // this is a reference to the react state in the paymentlink react component. changes to this will result in changing state.
  var model = {
    merchantID: 49456,
    currentPageIndex: 0,
    autoFocus: null,
    secondaryColorChecked: true,
    primaryColor: PRIMARY, // optional - default #B2B7C3
    secondaryColor: SECONDARY, // optional - default #4696C9
    companyLogoUrl: logo(), // optional - default paytrace logo
    companyName: "Acme", // optional
    companyWebsite: "www.website.com", // optional
    companyEmail: "user@email.com", // optional
    amount: { value: '11.99', valid: true, autoFocus: false },
    invoice: { value: 'some invoice' },
    paymentType: { value: PaymentType.ONE_TIME }, // WRONG CONFUSING NAMING BUT THIS FILE NEEDS TO GO AWAY
    frequency: { value: 1, valid: true },
    customFrequency: { value: 'day' },
    customFrequencyCount: { value: '2', valid: true }, // bill every
    duration: { value: 'continual' },
    durationLimit: {value: '1', valid: true },
    payon: { value: null || DateTimeUtility.now(), valid: true, autoFocus: false }, // default to today
    password: { value: "Secret", valid: false },
    checkingAccount: { value: "", autoFocus: false },
    routingNumber: { value: "" },
    paymentMethod: { value: "CC" },
    name: { value: "Bob the Builder", valid: true },
    cc: { value: "5454545454545454", valid: true, autoFocus: false },
    exp: { value: "1/2020", valid: true },
    securityCode: { value: "233", valid: true },
    address: { value: ['50th Ave'] }, 
    city: { value: 'seattle', valid: true },
    state: { value: 'WA', valid: true },
    country: { value: 'us', valid: true },
    zipcode: { value: '98999', valid: true },
    phone: { value: '9993332222', valid: true },
    email: { value: 'me@email.com', valid: true },
    recaptcha: { value: null, valid: false },
    agreeToTerms: { value: false },
  };
  
  var rules = {
    PaymentAmount: { validate: validatePaymentAmount },
    PaymentPayOn: { validate: validatePaymentPayOn },
    PaymentFrequency: { validate: validatePaymentFrequency },
    PaymentDurationLimit: { validate: validatePaymentDurationLimit },
    PaymentCustomFrequencyCount: { validate: validatePaymentCustomFrequencyCount },
    BillingName: { validate: validateBillingName },
    BillingCreditCardNumber: { validate: validateBillingCreditCardNumber },
    BillingCreditCardExp: { validate: validateBillingCreditCardExp },
    BillingCheckingAccountNumber: { validate: validateBillingCheckingAccountNumber },
    BillingCheckingRoutingNumber: { validate: validateBillingCheckingRoutingNumber },
    BillingContactCity: { validate: validateBillingContactCity },
    BillingContactState: { validate: validateBillingContactState },
    BillingContactPhone: { validate: validateBillingContactPhone },
    BillingContactEmail: { validate: validateBillingContactEmail },
    BillingRecurPassword: { validate: validateBillingRecurPassword },
  }

  var createCustomerAccount = function(callback) {
    const data = {
      merchant_id: getMerchantID(), //required
      EMAIL: model.formdata.billing.email.value, //required
      CC: model.formdata.billing.cc.value, //required
      Password: model.formdata.billing.password.value, //there is no check on VT
      DDA: model.formdata.billing.checkingAccount.value, //there is no check on VT
      TR: model.formdata.billing.routingNumber.value, //there is no check on VT

      BNAME: model.formdata.billing.name.value,
      BADDRESS: model.formdata.billing.address.value[0],
      BADDRESS2: model.formdata.billing.address.value[1],
      BCITY: model.formdata.billing.city.value,
      BSTATE: model.formdata.billing.state.value,
      BZIP: model.formdata.billing.zipcode.value,
      // VT legacy system when loading defaults overrides bcountry so not setting it for now
      // BCOUNTRY: model.formdata.billing.country.value.toUpperCase,
      PHONE: model.formdata.billing.phone.value,
      Exp: model.formdata.billing.exp.value, //gets sent as month and year to VT
    }
    NewCustomerAccount(data, callback);
  }
  
  var onSubmitCallback = function(resp) {
    console.log("response not implemented", resp)
  }

  var setMerchantID = function(id) {
    model.merchantID = id;
  }

  var getMerchantID = function() {
    return model.merchantID;
  }
  
  var getPassword = function() {
    return model.formdata.billing.password.value;
  }
  
  // local storage just used for UI state and no other purpose
  // passed in to whatever loggin controller and render UI accordingly.
  var isLoggedIn = function() {
    const session = sessionStorage.getItem("loggedIn");
    if (session) {
      return true;
    } else {
      return false;
    }
  }
    
  return {
    // createCustomerAccount: function(callback) {
    //   createCustomerAccount(callback);
    // },
    // isLoggedIn: isLoggedIn,
    // getPassword: getPassword,
    // getMerchantID: getMerchantID,
    // setMerchantID: setMerchantID,
    // submitPayment: function(data) {
    //   return submitPayment(getMerchantID(), data);
    // },
    // submitFormData: function(callback) {
    //   // passing merchant id in url query string, we want
    //   // could add it to the json payload but right now just doing 
    //   // this way because we don't even have the merchant id. 
    //   // Need to look at leviathan if we change it to the json payload.
    //   var endpoint = {
    //     type: 'POST',
    //     path: '/v1/guest/process_transaction?merchant_id=' + getMerchantID()
    //   }
    // 
    //   if (this.state.formdata.payment.paymentType.value == PaymentType.RECURRING) {
    //     // TODO and all the necessary customer fields are available
    //     createCustomerAccount(function(response){
    //        console.log(response);
    //        console.log("recurring not implemented");
    //     });
    //   } else {
    //     const callbackFn = callback || onSubmitCallback;
    //     // submitPayment
    //     callToEndpoint(endpoint, model.formdata, callbackFn)
    //   }
    // },
    // validation: function(model) {
    //   return {
    //     valid: function(prop) {
    //       if (rules.hasOwnProperty(prop)) {
    //         return rules[prop].validate(model);
    //       } else {
    //         return true; // no rule then valid
    //       }
    //     }
    //   }
    // },
    // validatePayment: function(model) {
    //   return validatePaymentAmount(model);
    // },
    // validateBilling: function(model) {
    //   return validateBillingName(model)
    //     && validateBillingZipCode(model)
    //     && validateBillingAddress(model)
    //     && validateBillingContactCity(model)
    //     && validateBillingContactState(model)
    //     && validateBillingContactPhone(model)
    //     && validateBillingContactEmail(model);
    // },
    // validateCreditCard: function(model) {
    //   return validateBillingCreditCardNumber(model)
    //     && validateBillingCreditCardExp(model)
    //     && validateBillingSecurityCodeValid(model)  
    // },
    // validateChecking: function(model) {
    //   return validateBillingCheckingAccountNumber(model)
    //     && validateBillingCheckingRoutingNumber(model)
    // },
    // validateReview: function(model) {
    //   return validateRecaptcha(model)
    //     && validateAgreementToTerms(model);
    // },
    // isEverythingAwesome: function(state) {
    //   if ( state.currentPageIndex == 0 && validatePaymentStep(state) ) {
    //     return true;
    //   } else if ( state.currentPageIndex == 1 && 
    //         validateBillingStep(state) ) {
    //     if (state.paymentType.value == PaymentType.RECURRING) {
    //       return validateBillingPassword(state);
    //     }
    //     return true;
    //   } else if ( state.currentPageIndex == 2 && validateReviewStep(state) ) {
    //     return true;
    //   } else if ( state.currentPageIndex == 3 ) {
    //     return true;
    //   }
    //   return false;
    // },
    // updatePassword: function(password, valid) {
    //   // TODO bug
    //   // i think this component updating field not on key press so 
    //   // values get out of wack. probably doesn't matter but...its still not right
    //   var formdata = {...this.state};
    //   formdata.password.value = password;
    //   formdata.password.valid = valid;
    //   this.setState(formdata);
    // },
    // updateFormData: function(element) {
    //   // TODO should rethink this
    //   // console.log("updateFormData", element.id(), element.value());
    //   var formdata = {...this.state};
    //   formdata[element.id()].value = element.value();
    //   this.setState(formdata);
    // },
    // state: function() {
    //   // initializes the state on the payment link component
    //   return model;
    // },
    // setState: function(state) {
    //   model = state;
    // },
    // // not used. All this stuff in here is not used i think but need to verify.
    // login: function(userName, password) {
    //   return new Promise(function(resolve, reject) {
    //     customerLogin(userName, password, getMerchantID(), (resp) => {
    //       console.log("status", resp["status"]);
    //       if (resp["status"] == 200 || resp["status"] == "ok") {
    //         sessionStorage.setItem("loggedIn", true);
    //         console.log("resolve", resp);
    //         return resolve(resp);
    //       }
    //     });
    //   });
    // },
    // logout: function() {
    //   return new Promise(function(resolve, reject){
    //     customerLogout(getMerchantID(), (resp) => {
    //       console.warn("log out not implemented", resp);
    //       sessionStorage.removeItem("loggedIn");
    //       return resolve(resp);
    //     });
    //   });
    // 
    // },
    // loginStatus: function(callback) {
    //   customerLoginStatus((resp) => {
    //     // console.log("loggedIn local storage:", sessionStorage.getItem("loggedIn"));
    //     callback(resp);
    //   });
    // },
    // buildPaymentLinkState: function(model) {
    //   // Note this method is going to return a copy of the state that is used to set the react state in memory 
    //   // for the react application. And the paramParse library also keeps values in memory in a different format.
    //   // And for now how its currently implemented, the model in the PaymentLinkService
    //   // is the reference to the application react state in the payment link react component. Look at comment in
    //   // the PaymentLinkService for the model above.
    //   // whats seems wonky is first we go fetch the encoded paramter payment link string
    //   // then parse it into a json string
    //   // then convert it to a json object
    //   // then map/hydrate/fill the values in the payment link model
    //   // then pass the model into this service to build the state for the payment link UI application.
    //   // We could skip creating the PaymentLinkModel and instead build the state with the JSON object
    //   // but for now keeping it separate and the PaymentLinkModel a middle step of the process
    //   // because I feel that we could later figure a better way. For example separating out 
    //   // the ui state for the form and the ui state for the application.
    //   // And might be easier to refactor later if needed.
    //   // When refactoring we also should to keep in mind what if the values are not existent in the payment param link.
    //   // and consider the required states and hidden states which not yet implemented. What is required by ASP system and 
    //   // business wise what is needed for a payment? etc... Also need to consider when we send the form model to leviathan
    //   // and send the leviathan payload over to VT asp system. There is a few "moving" parts right now 
    //   // hard to figure a good way to make it clean and work in in this disparate system. Once it is solid will consider
    //   // refactoring. It's cludgy but seems to me straight forward. To make this part of the system robust and work in all 
    //   // situation would probably end up in a over complicated disaster. 
    //   // This long comment is probably not helpful and going to be confusing later too. And who is really going to read this
    //   // long winded comment...blah blah blah, chicken chicken chicken.
    //   var state = {
    //     termsLink: model.termsLink,
    //     merchantID: model.merchantID,
    //     // currentPageIndex: 0,
    //     // autoFocus: null,
    //     secondaryColorChecked: true,
    //     primaryColor: model.primaryColor,
    //     secondaryColor: model.secondaryColor,
    //     companyInfo: {
    //       logoUrl: null,
    //       name: model.companyInfoName,
    //       website: model.companyInfoWebsite,
    //       email: model.companyInfoEmail,
    //     },
    //     amount: { value: model.billingAmount, valid: true, autoFocus: false },
    //     // invoice: { value: 'some invoice' },
    //     // paymentType: { value: PaymentType.ONE_TIME },
    //     // frequency: { value: 1, valid: true },
    //     // customFrequency: { value: 'day' },
    //     // customFrequencyCount: { value: '2', valid: true }, // bill every
    //     // duration: { value: 'continual' },
    //     // durationLimit: {value: '1', valid: true },
    //     // payon: { value: null, valid: true, autoFocus: false }, // default to today
    //     // password: { value: "Secret", valid: false },
    //     checkingAccount: { value: model.billingCheckingAccount, autoFocus: false },
    //     routingNumber: { value: "" },
    //     paymentMethod: { value: "CC" },
    //     name: { value: "Bob the Builder", valid: true },
    //     cc: { value: model.billingCC, valid: true, autoFocus: false },
    //     exp: { value: "1/2020", valid: true },
    //     securityCode: { value: "233", valid: true },
    //     address: { value: [model.billingAddress] }, 
    //     city: { value: model.billingCity, valid: true },
    //     state: { value: model.billingState, valid: true },
    //     country: { value: model.billingCountry, valid: true },
    //     zipcode: { value: model.billingZipcode, valid: true },
    //     phone: { value: '9993332222', valid: true },
    //     email: { value: 'me@email.com', valid: true },
    //     allowReturningCustomersFlag: model.allowReturningCustomersFlag,
    //     convenienceFeeAmount: model.convenienceFeeAmount,
    //     convenienceFeeLabel: model.convenienceFeeLabel,
    //     convenienceFeeType: model.convenienceFeeType,
    //     defaultTransactionType: model.defaultTransactionType,
    //     recaptcha: { value: null, valid: false },
    //     agreeToTerms: { value: false },
    //   };
    //   return state;
    // },
    // submitPayment: function(data) {
    //   return submitPayment(getMerchantID(), data);
    // },
    // submitFormData: function(callback) {
    //   // passing merchant id in url query string, we want
    //   // could add it to the json payload but right now just doing 
    //   // this way because we don't even have the merchant id. 
    //   // Need to look at leviathan if we change it to the json payload.
    //   var endpoint = {
    //     type: 'POST',
    //     path: '/v1/guest/process_transaction?merchant_id=' + getMerchantID()
    //   }
    // 
    //   if (this.state.formdata.payment.paymentType.value == PaymentType.RECURRING) {
    //     // TODO and all the necessary customer fields are available
    //     createCustomerAccount(function(response){
    //        console.log(response);
    //        console.log("recurring not implemented");
    //     });
    //   } else {
    //     const callbackFn = callback || onSubmitCallback;
    //     // submitPayment
    //     callToEndpoint(endpoint, model.formdata, callbackFn)
    //   }
    // },
    // validation: function(model) {
    //   return {
    //     valid: function(prop) {
    //       if (rules.hasOwnProperty(prop)) {
    //         return rules[prop].validate(model);
    //       } else {
    //         return true; // no rule then valid
    //       }
    //     }
    //   }
    // },
    // validatePayment: function(model) {
    //   return validatePaymentAmount(model);
    // },
    // validateBilling: function(model) {
    //   return validateBillingName(model)
    //     && validateBillingZipCode(model)
    //     && validateBillingAddress(model)
    //     && validateBillingContactCity(model)
    //     && validateBillingContactState(model)
    //     && validateBillingContactPhone(model)
    //     && validateBillingContactEmail(model);
    // },
    // validateCreditCard: function(model) {
    //   return validateBillingCreditCardNumber(model)
    //     && validateBillingCreditCardExp(model)
    //     && validateBillingSecurityCodeValid(model)  
    // },
    // validateChecking: function(model) {
    //   return validateBillingCheckingAccountNumber(model)
    //     && validateBillingCheckingRoutingNumber(model)
    // },
    // validateReview: function(model) {
    //   return validateRecaptcha(model)
    //     && validateAgreementToTerms(model);
    // },
    // isEverythingAwesome: function(state) {
    //   if ( state.currentPageIndex == 0 && validatePaymentStep(state) ) {
    //     return true;
    //   } else if ( state.currentPageIndex == 1 && 
    //         validateBillingStep(state) ) {
    //     if (state.paymentType.value == PaymentType.RECURRING) {
    //       return validateBillingPassword(state);
    //     }
    //     return true;
    //   } else if ( state.currentPageIndex == 2 && validateReviewStep(state) ) {
    //     return true;
    //   } else if ( state.currentPageIndex == 3 ) {
    //     return true;
    //   }
    //   return false;
    // },
    // updatePassword: function(password, valid) {
    //   // TODO bug
    //   // i think this component updating field not on key press so 
    //   // values get out of wack. probably doesn't matter but...its still not right
    //   var formdata = {...this.state};
    //   formdata.password.value = password;
    //   formdata.password.valid = valid;
    //   this.setState(formdata);
    // },
    // updateFormData: function(element) {
    //   // TODO should rethink this
    //   // console.log("updateFormData", element.id(), element.value());
    //   var formdata = {...this.state};
    //   formdata[element.id()].value = element.value();
    //   this.setState(formdata);
    // },
    // state: function() {
    //   // initializes the state on the payment link component
    //   return model;
    // },
    // setState: function(state) {
    //   model = state;
    // },
    // // not used. All this stuff in here is not used i think but need to verify.
    // login: function(userName, password) {
    //   return new Promise(function(resolve, reject) {
    //     customerLogin(userName, password, getMerchantID(), (resp) => {
    //       console.log("status", resp["status"]);
    //       if (resp["status"] == 200 || resp["status"] == "ok") {
    //         sessionStorage.setItem("loggedIn", true);
    //         console.log("resolve", resp);
    //         return resolve(resp);
    //       }
    //     });
    //   });
    // },
    // logout: function() {
    //   return new Promise(function(resolve, reject){
    //     customerLogout(getMerchantID(), (resp) => {
    //       console.warn("log out not implemented", resp);
    //       sessionStorage.removeItem("loggedIn");
    //       return resolve(resp);
    //     });
    //   });
    // 
    // },
    // loginStatus: function(callback) {
    //   customerLoginStatus((resp) => {
    //     // console.log("loggedIn local storage:", sessionStorage.getItem("loggedIn"));
    //     callback(resp);
    //   });
    // },
    // buildPaymentLinkState: function(model) {
    //   // Note this method is going to return a copy of the state that is used to set the react state in memory 
    //   // for the react application. And the paramParse library also keeps values in memory in a different format.
    //   // And for now how its currently implemented, the model in the PaymentLinkService
    //   // is the reference to the application react state in the payment link react component. Look at comment in
    //   // the PaymentLinkService for the model above.
    //   // whats seems wonky is first we go fetch the encoded paramter payment link string
    //   // then parse it into a json string
    //   // then convert it to a json object
    //   // then map/hydrate/fill the values in the payment link model
    //   // then pass the model into this service to build the state for the payment link UI application.
    //   // We could skip creating the PaymentLinkModel and instead build the state with the JSON object
    //   // but for now keeping it separate and the PaymentLinkModel a middle step of the process
    //   // because I feel that we could later figure a better way. For example separating out 
    //   // the ui state for the form and the ui state for the application.
    //   // And might be easier to refactor later if needed.
    //   // When refactoring we also should to keep in mind what if the values are not existent in the payment param link.
    //   // and consider the required states and hidden states which not yet implemented. What is required by ASP system and 
    //   // business wise what is needed for a payment? etc... Also need to consider when we send the form model to leviathan
    //   // and send the leviathan payload over to VT asp system. There is a few "moving" parts right now 
    //   // hard to figure a good way to make it clean and work in in this disparate system. Once it is solid will consider
    //   // refactoring. It's cludgy but seems to me straight forward. To make this part of the system robust and work in all 
    //   // situation would probably end up in a over complicated disaster. 
    //   // This long comment is probably not helpful and going to be confusing later too. And who is really going to read this
    //   // long winded comment...blah blah blah, chicken chicken chicken.
    //   var state = {
    //     termsLink: model.termsLink,
    //     merchantID: model.merchantID,
    //     // currentPageIndex: 0,
    //     // autoFocus: null,
    //     secondaryColorChecked: true,
    //     primaryColor: model.primaryColor,
    //     secondaryColor: model.secondaryColor,
    //     companyInfo: {
    //       logoUrl: null,
    //       name: model.companyInfoName,
    //       website: model.companyInfoWebsite,
    //       email: model.companyInfoEmail,
    //     },
    //     amount: { value: model.billingAmount, valid: true, autoFocus: false },
    //     // invoice: { value: 'some invoice' },
    //     // paymentType: { value: PaymentType.ONE_TIME },
    //     // frequency: { value: 1, valid: true },
    //     // customFrequency: { value: 'day' },
    //     // customFrequencyCount: { value: '2', valid: true }, // bill every
    //     // duration: { value: 'continual' },
    //     // durationLimit: {value: '1', valid: true },
    //     // payon: { value: null, valid: true, autoFocus: false }, // default to today
    //     // password: { value: "Secret", valid: false },
    //     checkingAccount: { value: model.billingCheckingAccount, autoFocus: false },
    //     routingNumber: { value: "" },
    //     paymentMethod: { value: "CC" },
    //     name: { value: "Bob the Builder", valid: true },
    //     cc: { value: model.billingCC, valid: true, autoFocus: false },
    //     exp: { value: "1/2020", valid: true },
    //     securityCode: { value: "233", valid: true },
    //     address: { value: [model.billingAddress] }, 
    //     city: { value: model.billingCity, valid: true },
    //     state: { value: model.billingState, valid: true },
    //     country: { value: model.billingCountry, valid: true },
    //     zipcode: { value: model.billingZipcode, valid: true },
    //     phone: { value: '9993332222', valid: true },
    //     email: { value: 'me@email.com', valid: true },
    //     allowReturningCustomersFlag: model.allowReturningCustomersFlag,
    //     convenienceFeeAmount: model.convenienceFeeAmount,
    //     convenienceFeeLabel: model.convenienceFeeLabel,
    //     convenienceFeeType: model.convenienceFeeType,
    //     defaultTransactionType: model.defaultTransactionType,
    //     recaptcha: { value: null, valid: false },
    //     agreeToTerms: { value: false },
    //   };
    //   return state;
    // },
  }
})();
