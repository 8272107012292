// Buttons
//
//  desc:
//    a collection of components as named exports to provide a UI buttons.
//    continue button and back button for navigating thru the form pages.
//
//  props:
//    stages:                (required) array of strings representing possible steps of the payment form
//    handleEdit:            (optional) handler method to call to update data model
//
import Styled from 'styled-components';

import React, { Component } from 'react';

export const Button = Styled.button`
  background-color: #B2B7C3;
`;

export class ContinueButton extends Component {
  constructor(props){
    super(props);
    this.state = {
      stage: 0
    }
    this.stages = this.props.stages || ['Continue to Billing', 'Finish']
    this.handleContinue = this.handleContinue.bind(this);
  }
  handleContinue(evt){
    const stage = this.state.stage+1;
    if (stage >= this.stages.length) {
      return;
    }
    this.setState({
      stage: stage
    });
    evt.preventDefault();
  }
  render() {
    return (
      <Button className="btn" onClick={this.handleContinue}>
      {this.stages[this.state.stage]}
      </Button>
    );
  }
}

export class EditButton extends Component {
  constructor(props){
    super(props);
    this.handleEdit = this.handleEdit.bind(this);
  }
  handleEdit(evt){
    if (this.props.handleEdit) {
      this.props.handleEdit(evt);
    }
    evt.preventDefault();
  }
  render() {
    return (
      <Button className="btn" onClick={this.handleEdit}>
      Edit
      </Button>
    );
  }
}

export default Button;
