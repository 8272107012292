// BackDrop
//
//  desc:
//    provides a component that covers the whole screen area to 
//    register click events. For example when click is fired will close
//    the DropDown List menu component. Could be used for modals if we had them.
//
//  props:
//    enabled:              (required) if the backdrop is displayed or not.
//    onclick:              (optional) the handler to call on click event.
//

/*
  backdrop.js

  covers the whole web page and used for 
  registering mouse events.
  Frequency dropdown list uses this to close 
  itself.
  Note: z-index needs to be higher than 1 at least if 
  needs to show when backdrop is enabled.
*/
import React, { Component } from 'react';


export class BackDrop extends Component {
  constructor(props){
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  
  handleClick(e) {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
    e.preventDefault();
  }

  render() {
    /* if we ever need to reuse this we could always */
    /* like if we make our own modal */
    /* refactor it out into its own component */
    const overlay = {
        display: this.props.enabled ? 'block' : 'none',
        opacity: '0',
        zIndex: '2',
        position: 'fixed',
        height: '100%',
        width:'100%',
        left: '0px',
        top: '0px',
    }
    return (
      <div style={ overlay } onClick={ this.handleClick }>
      </div>
    );
  }
}
