import 'url-search-params-polyfill';
import axios from 'axios';


// commsHelper.js
//
//  desc:
//    network functionality provided for retrieving data from an api. 
//    To use, import function 'callToEndpoint' 
//  
//  Note
//  If credentials are include then cookies for customer passed to server automatically.
//  If credentials are same-origin then cookies for customer passed to server only if same origin is true.
//  For anything dealing with customer token, we need that session to be sent to the server to process a payment for that customer.
//  We should check for cross scripting with the CSRF token. TODO

function status(response) 
{
  if (response.status >= 200 && response.status < 300) 
  {
    return Promise.resolve(response)
  } 
  else 
  {
    return Promise.reject(new Error(response.statusText))
  }
}


function json(response) 
{
  return response.json()
}

function getCSRFToken(){
  // getCookie("CSRF-TOKEN")
  // TODO not implemented
  return "";
}

const baseLeviathanAPIConfig = {
  baseURL: process.env.API_URL,
  // url: endpoint.path,
  // method: endpoint.type,
  // data: data,
  // will need to figure out how long is a timeout and what to do when there is a timeout to use below.
  // timeout: process.env.API_TIMEOUT !== undefined ? process.env.API_TIMEOUT : 10000,
  // mode: "cors",                 // no-cors, cors, *same-origin
  redirect: "follow",           // manual, *follow, error
  referrer: "no-referrer",      // no-referrer, *client
  cache: "no-cache",            // *default, no-cache, reload, force-cache, only-if-cached
  //credentials: "include",      // include, *same-origin, omit
  withCredentials: true,
  headers: 
  {
//        "X-CSRF-Token": getCSRFToken(),
    "Content-Type": "application/json",
    "PayTrace-Product": "paymentlink"
  },
};

export const leviathanAPI = axios.create(baseLeviathanAPIConfig);

// callToEndpoint
//
// expects:
//    endpoint: hash that contains the endpoint path and the endpoint type
//              path should be of the type "/index.html" 
//              type should be either "GET" or "POST"
//            example endpoint = {path: '/v1/mid_info', type: "GET"}
//
//    data:     data provided in a json format that will be provided to the servers endpoint
//            example data = {merchant_id: 1234}
//
//    callback: callback function that will be called with a single parameter that is the returned json data from the endpoint call
//    abortcontroller: to allow aborting asnc task if needed.
//
// note: can comment out cors and same-origin and leviathan can allow any and not expect credentials for local development testing.
export function callToEndpoint(endpoint, data)
{

  // should return this api or the api promise and let the caller handle
  // the response without using the callback.
  const api = leviathanAPI;

  var endpointUrl = process.env.API_URL + endpoint.path
  let settings = {}
  // var retVal = [];
  if (endpoint.type == 'POST')
  { 
    // note: its ok to catch any errors here for example network error
    // because it will still return a promise if there is no error.
    // not doing it now though.
    return api.post(endpoint.path, data, settings);
    // .then(data => 
    // {
    //   retVal = data;
    //   callback(data);
    // })
    // .catch(error => console.error('Error in calling endpoint. ', error));
  }
  else if (endpoint.type == 'GET')
  {
    return api.get(endpoint.path, {
      params: data
    })
    // .then(data => 
    // {
    //   retVal = data;
    //   callback(data);
    // })
    // .catch(error => console.error('Error in calling endpoint:', error, endpointUrl));
  }

}


