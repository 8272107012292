// TotalPaymentBox
//
//  desc:
//    total payment component
//
//  props:
//    placeholderText:      (optional) if provided, will display ghosted placeholder text in the input box. Note: there is no placeholder text by default
//    textBoxWidth:         (optional) width
//    totalAmount           (optional) total amount

import React, { Component } from 'react';
import './TotalPaymentBox.css';

class TotalPaymentBox extends Component {
  constructor(props)
  {
    super(props);
  }
  
  getPlaceholderText()
  { //defaults to empty string if no prop provided
    return this.props.placeholderText != null ? this.props.placeholderText : "" ;
  }
  
  getTextBoxWidth()
  { 
    //defaults to 50 if no prop provided
    return this.props.textBoxWidth != null ? this.props.textBoxWidth : "50%" ;
  }
  
  getTotalAmount()
  {
    return this.props.totalAmount != null ? "$" + this.props.totalAmount : ""
  }
  
  getTooltipText()
  { //defaults to empty string if no prop provided
   return this.props.tooltipText != null ? this.props.tooltipText : ""
  }


  render() {
    
    return (
      <div className = "TotalPaymentBox">
        {this.props.children}
        <input 
            type="text"
            value={this.getTotalAmount()}
            placeholder={this.getPlaceholderText()}
            name={this.props.name}
            maxLength={this.props.maxLength}
            title = {this.getTooltipText()}
            disabled
        />
      </div>
    );
  }
}

export default TotalPaymentBox;
