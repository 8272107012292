// DatePicker
//
//  desc:
//    provides date picker control. Uses third party library to render the control.
//
//  props:
//    value:                (optional) date value of the control
//    onDataChange:         (optional) a callback function when date changes to update model
//    onValidate:           (optional) a callback function validating the input
//    isValid:              (optional) if true, will display the date box input in red 
//    id:                   (required) used to update the appropriate data model
//    name:                 (optional) name of the component
//    autoFocus:            (required) to focus on control when edit link on review page navigates back to page with control
//

import React, { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from "react-day-picker";
import { withFormsy } from 'formsy-react';

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import {DateTimeUtility, Change, Validation, Rule} from '../Utility';
import Calendar from '../resources/images/calendar.png';
import Icon from '../Icon';

import 'react-day-picker/lib/style.css';
import './date_picker.css';



  // not sure how to restrict the datepicker input
export const validateDateFormat = function(values, dateString) {
  if (/\d+\/\d+\/\d{4}/.test(dateString)) {
    return true;
  } else { 
    return false;
  }
};


class DatePicker extends Component {
  constructor(props)
  {
    super(props);
    this.state = {};
    this.handleDayChange = this.handleDayChange.bind(this);
    this.validateDateFormat = this.validateDateFormat.bind(this);
  }

  handleDayChange(selectedDay, modifiers, dayPickerInput) {
    const input = dayPickerInput.getInput();
    const value = input.value;
    this.props.setValue(value);

    // const validity = this.validateDateFormat(input.value) && DateTimeUtility.isDate(input.value);
    // 
    // console.log(input.value, validity)
    // TODO name is pretty specific might want to make the name more generic. which would just be a name change for caller.
    if (this.props.onPaymentPayonChange) {
      this.props.onPaymentPayonChange(value);
    }
  }

  // not sure how to restrict the datepicker input
  validateDateFormat(dateString) {
    if (/\d+\/\d+\/\d{4}/.test(dateString)) {
      return true;
    } else { 
      return false;
    }
  };
  
  showErrorMessage() {
    return !this.props.isValid() ? 
      <span className='field-error'>({ this.props.getErrorMessage() })</span> :
      null;
  }

  render() {
    const id = this.props.id;
    const name = this.props.name;
    const autoFocus = this.props.autoFocus;
    const FORMAT = 'MM/DD/YYYY';
    const errorClass = this.props.isValid ? null : "date-input-error";
    const inputClass = `${errorClass}`;
    const today = new Date();
    const validateFn = function() {
      // console.log("validateFn")
    }
    // do not enable the datepicker for onetime payment.
    const requiredColor = this.props.isValid() ? null : { color: 'red' }
    const requiredMark = this.props.required ? <span className='required-mark' style={ requiredColor }>*</span> : null;
    return (
      <div className={ `DatePicker ${inputClass}` }>
          <div>
            <label htmlFor={this.props.id}>
            {this.props.label}
            { requiredMark }
            {this.showErrorMessage()}
            </label>
          </div>
          <DayPickerInput
            onDayChange={this.handleDayChange}
             
            formatDate={formatDate} 
            format={FORMAT}
            
            parseDate={parseDate}
            inputProps={ { id: id, name: name, autoFocus: autoFocus, disabled: false, readOnly: true } }
            dayPickerProps={{ className:"DayPickerPanel", disabledDays: { before: today } }}
            value={ this.props.getValue() }
            placeholder='dd-mm-yyyy'
          />
          <span className="calendar-icon">
            <Icon className="fas fa-calendar-alt" />
          </span>
      </div>
    );
  }
}

export default withFormsy(DatePicker);
