import React, { Component } from 'react';
import { Change, Validation } from '../../Utility';
import './AddressInput.css';

class AddressInput extends Component {
  constructor(props)
  {
    super(props);
    this.state = {
      valid: true
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleValidate = this.handleValidate.bind(this);
  }

  handleChange(event){
    if (this.props.onChange) {
      this.props.onChange(new Change(event));
    }
    event.preventDefault();
  }
  
  handleValidate(event){
    this.setState({
      valid: event.target.validity.valid
    });
    if (this.props.onValidate) {
      this.props.onValidate(new Validation(event));
    }
    event.preventDefault();
  }

  render() {
    const style = {
      border: this.state.valid && this.props.valid ? null : "2px solid red"
    }
    return (
      <div className="AddressInput">
          <input style={style} id={this.props.identifier} type = "text"
              required={true}
              value={this.props.value}
              onChange={this.handleChange}
              onBlur={this.handleValidate}
              placeholder = {this.props.placeholderText}
              maxLength = {this.props.maxLength}/>
      </div>
    );
  }
}

export default AddressInput;
