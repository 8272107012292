/*
  Name:
  Disclaimer

  Description:
  The disclaimer
*/
import React, { Component } from 'react';
import Icon from '../common/react-component-library/Icon';
import ShieldIcon from '../../../resources/images/shield@3x.svg';
import SectionText from '../common/react-component-library/SectionText';
import './disclaimer.css';

class Disclaimer extends Component {
  constructor(props){
    super(props);
    this.title = "This is a secure connection";
    this.content = "All data sent between your device " +
    "and PayTrace (https://www.paytrace.com) " +
    "is encrypted with a 256 bit Secure Socket Layer (SSL) " +
    "certificate issued by Comodo Class 3 Security Services CA."
  }

  render() {
    return (
      <div className='disclaimer'>
        <div>
          <Icon img="shield@3x.svg" width="36" height="36"/>
          <SectionText
            title={this.title}
            content={this.content}/>
        </div>
      </div>
    );
  }
}

export default Disclaimer;
