import React, { Component } from 'react';
import DropDownIcon from '../resources/images/dropdown.png';
import { withFormsy } from 'formsy-react';
import './dropdown.css';

var dropDownStyle = {
  backgroundImage: `url(${DropDownIcon})`
}

function isFunction(functionToCheck) {
 return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

class DropDown extends React.PureComponent {
  constructor(props)
  {
    super(props);
    let defaultValue = "";
    if (this.props.value) {
      defaultValue = this.props.value;
    }
    this.state = {
      value: defaultValue
    }
    this.optionList = isFunction(this.props.options) ? this.props.options() : this.props.options;

    this.handleChange = this.handleChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }
  
  componentWillUnmount() {
    // go update the application state for the form data.
    if (this.props.onChange) {
      // this.props.onChange(this.props.dataKey + " " + this.props.getValue());
      // value is actually a concatentation of select id + space + option value
      this.props.onChange(this.props.getValue());
    }
  }
  
  buildOptions() {
    let options = Object.keys(this.optionList).map((key, index) => 
      <option key={index} value={key}>{this.optionList[key]}</option>
    );
    return options;
  }
  
  showErrorMessage() {
    return this.state.errorMessage ? 
      <span className='field-error'>({ this.state.errorMessage })</span> :
      null;
  }

  buildLabel() {
    const requiredColor = this.props.isValid() ? null : { color: 'red' }
    const requiredMark = this.props.required ? <span className='required-mark' style={ requiredColor }>*</span> : null;
    if (this.props.label) {
      return (
        <div className="label">
          <label htmlFor={this.props.id}>
            {this.props.label}
            {requiredMark}
            {this.showErrorMessage()}
          </label>
        </div>
      )
    }
    return null;
  }
  

  buildSelectDropdown()
  {
    let styles = "DropDown"
    if ( this.props.inError != null && this.props.inError == true ) 
    {
      styles += ' drop-down-list_inputError'
    }
    return <div className={styles}>
            {this.buildLabel()}
            <select
                  id={this.props.id}
                  value={this.props.getValue()}
                  onBlur={this.onBlur}
                  onChange={this.handleChange}
                  style={dropDownStyle}>
              {this.buildOptions()}
            </select>
          </div>
  }


  buildMultiSelect()
  {
    let styles = "MultiSelect"
    if ( this.props.inError != null && this.props.inError == true ) 
    {
      styles += ' drop-down-list_inputError'
    }
    return  <div className={styles}>
              {this.buildLabel()}
              <select multiple
                    value={this.props.getValue() || null}
                    onBlur={this.onBlur}
                    onChange={this.handleChange} >
                {this.buildOptions()}
              </select>
            </div>
  }

  renderSelect()
  {
    if (this.props.isMultiSelect)
    { //render a multiple select box
      return this.buildMultiSelect();// this doesn't work
    }
    else
    {
      return this.buildSelectDropdown();
    }
  }

  handleChange(event) {
    this.props.setValue(event.currentTarget.value);
  }
  
  onBlur(event) {
    const errorMessage = this.props.getErrorMessage();
    this.setState({
      errorMessage: errorMessage,
    });
    if (this.props.isValid()) {
      event.currentTarget.setCustomValidity('');
    } else {
      event.currentTarget.setCustomValidity(errorMessage);
    }
  }
    
  render() {
    return (
      <div>
        {this.renderSelect()}
      </div>
    );
  }
}

export default withFormsy(DropDown);
