// Source @ https://github.com/braintree/credit-card-type

// Looked through the code on github. It uses a hash for known credit
// card types and each type has a list of digits that will match the type
// of card. This library is pretty symplistic and does not use regular expressions.
// It serves the purpose of needing a utility library function for determining
// full or partial credit card number entries to be used to display a
// image representing credit card type. As long as the number is a string
// and matches the patterns it will find the best match possible. This 
// works for use because we will have masked credit card number entries.
import creditCardType from 'credit-card-type';

export function GetCardType(number) {
  var card = creditCardType(number.trim());

  if (number.trim().length <= 0 || card === undefined || card.length <= 0) {
    return "";
  } else {
    return card[0].type.toLowerCase();
  }
}

export function GetCardTypeName(number) {
  var card = creditCardType(number.trim());

  if (number.trim().length <= 0 || card === undefined || card.length <= 0) {
    return "";
  } else {
    return card[0].niceType;
  }
}


export function CardType(number) {
  var card = creditCardType(number.trim());
  if (number.trim().length <= 0 || card === undefined || card.length <= 0) {
    return null;
  } else {
    return card[0];
  }
}
