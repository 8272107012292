// NavigationButtons
//
//  desc:
//    controls to navigate back and forth between the payment steps
//
//  props:
//    currentState
//      currentPageIndex    (required) Current page or step index.
//    primaryColor:         (required) Color styling.
//    itsAllGoodInTheHood:  (required) Determines if the form elements on current step is valid and we can move to the next step.
//    onNavigate:           (optional) Handle moving back or forth between steps.
//    onRecaptcha:          (optional) Handle resetting recaptcha so user has to reenter it if navigating away.
//    onSubmit:             (optional) handle submit payment event
//    onReset:              (optional) Handle moving back to initial step and any resetting needed.

import React, { Component } from 'react';
import Icon from '../../Icon';
import './NavigationButtons.css';


class NavigationButtons extends Component {
  constructor(props){
    super(props);
    this.state = {
      finished: false
    }

    this.goto = this.goto.bind(this);
    this.onBackToPayment = this.onBackToPayment.bind(this);
    this.onBackToBilling = this.onBackToBilling.bind(this);
    this.onContinueToBilling = this.onContinueToBilling.bind(this);
    this.onContinueToReview = this.onContinueToReview.bind(this);
    this.onSubmitPayment = this.onSubmitPayment.bind(this);
    this.onProcessAnother = this.onProcessAnother.bind(this);
  }

  onBackToPayment(evt) {
    this.goto('payment');
  }
  
  onBackToBilling(evt) {
    this.goto('billing');
  }
  
  onContinueToBilling(evt) {
    this.goto('billing');
  }
  
  onContinueToReview(evt) {
    this.goto('review');
  }
  
  goto(page) {
    if (this.props.onNavigate) {
      this.props.onNavigate(page);
    }
    if (this.props.onRecaptcha) {
      this.props.onRecaptcha(page, false);
    }
  }

  onSubmitPayment(evt) {
    if (this.props.onSubmit) {
      this.props.onSubmit(evt);
    }
    this.setState({
      finished: true
    });
  }
  
  onProcessAnother(evt) {
    if (this.props.onReset) {
      this.props.onReset(evt);
    }
    this.setState({
      finished: false
    });
  }
  
  onProcessAnother(evt) {
    if (this.props.onReset) {
      this.props.onReset(evt);
    }
    this.setState({
      finished: false
    });
  }
  
  onPrintReceipt(evt) {
    window.print()
  }
  
  renderBackButton(back) {
    if (back == null) return null;

    const text = back.text;
    const handler = back.fn;
    return (
      <button onClick={handler} className='btn back'>
        <Icon className="fas fa-arrow-left" width="36" height="36"/>
        {text} 
      </button>
    )
  }
  
  renderContinueButton(next, isEnabled) {
    if (next == null) return null;

    const text = next.text;
    const handler = next.fn;
    return (
      <button disabled={!isEnabled} onClick={handler} style={{backgroundColor: this.props.primaryColor}} className='btn'>
        {text}
      </button>
    )
  }
  
  renderProcessAgainButton() {
    const text = 'Process Again';
    const handler = this.onProcessAnother;
    return (
      <button onClick={handler} className='btn back'>
        <Icon className="fas fa-arrow-left" width="36" height="36"/>
        {text} 
      </button>
    )
  }
  
  renderPrintReceiptButton() {
    const text = 'Print';
    const handler = this.onPrintReceipt;
    return (
      <button onClick={handler} style={{backgroundColor: this.props.primaryColor}} className='btn'>
        {text}
      </button>
    )
  }
  
  isFinished() {
    return this.props.currentState.currentPageIndex > 2
      || this.state.finished
  }
  
  navigationItems(idx) {
    // This can be rethought. Just hard coding it for now.
    // Other step 'mappings' other places probably can be consolidated into one place.
    const items = [
        {
          id: 0,
          previous: null,
          next: {
            text: "Continue to Billing", 
            fn: this.onContinueToBilling 
          } 
        },
        {
          id: 1,
          previous: {
            text: "Back to Payment",
            fn: this.onBackToPayment
          },
          next: {
            text: "Continue to Review",
            fn: this.onContinueToReview
          },
        },
        {
          id: 2,
          previous: {
            text: "Back to Billing",
            fn: this.onBackToBilling
          },
          next: {
            text: "Submit Payment",
            fn: this.onSubmitPayment
          }
        },
    ];

    return items[idx];
  }
  
  render() {
    const currentPageIndex = this.props.currentState.currentPageIndex;
    const primaryColor = this.props.primaryColor || null;
    const canMoveNext = this.props.itsAllGoodInTheHood;
    const navigation = this.navigationItems(currentPageIndex) || null;
    return (
      <div className='nav-buttons'>
        <div className='previous-button'>sdfds
          {this.isFinished() 
              ? this.renderProcessAgainButton()
              : this.renderBackButton(navigation.previous)}
        </div>

        <div className='continue-button'>
          {this.isFinished()
              ? this.renderPrintReceiptButton()
              : this.renderContinueButton(navigation.next, canMoveNext)}
        </div>
      </div>
    );
  }
}

export default NavigationButtons;
