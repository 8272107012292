import React, { Component } from 'react';
import Styled, {keyframes, css} from 'styled-components';
import PropTypes from 'prop-types';
import {fadeOut} from '../Styles';
import { scrollToRef } from '../Utility/dom';


// Usage: properties
// animateMessage={this.state.animateMessage}
// onAnimationEnd={this.onMessageAnimationEnd}
const NotificationStyle = Styled.div`
  display: ${props => props.animateMessage ? 'inherit' : 'none'};
  display: 'inherit';
  animation-name: ${fadeOut};
  animation-duration: 2s;
  animation-delay: 3s;
  animation-fill-mode: forwards;
  animation-play-state: running;
  font-size: 1.5em;
  text-align: center;
  color: #6495ed;
`;


class Notification extends Component {
  constructor(props){
    super(props);
    // const notificationRef = React.useRef(null);
    this.notificationRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.children != "") {
      scrollToRef(this.notificationRef);
    }
  }

  render() {
    return (
      <div ref={this.notificationRef}>
        <NotificationStyle
            animateMessage={this.props.animateMessage}
            onAnimationEnd={this.props.onAnimationEnd}>
          {this.props.children}
        </NotificationStyle>
      </div>
    );
  }
}


Notification.propTypes = {
  // the message
  animateMessage: PropTypes.bool.isRequired,
  // event handler
  onAnimationEnd: PropTypes.func,
  children: PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.object
            ]),
}
export default Notification;