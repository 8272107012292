import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Button, Alert as AlertBS } from 'react-bootstrap';
import 'alert.css';


class FlashNotification extends Component {
  constructor(props){
    super(props);
    this.state = {
      showButton: true,
      showMessage: true,
    };
    this.hideMessage = this.hideMessage.bind(this);
  }

  componentDidMount() {
    // if the property to just display message temporarily then clear message
    if (this.props.flashDisplayTime) {
      this.timer = setTimeout(
        this.props.onClose,
        this.props.flashDisplayTime
      );
    }
  }
  
  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  hideMessage(evt) {
    if (this.props.onClose) {
      // clear message and close alert box
      this.props.onClose(evt);
    }
  }

  render() {
    const showButton = this.state.showButton;
    const showMessage = this.state.showMessage;
    const type = this.props.type || 'warning'
    return (
      <div className='flashNotification' style={{padding: '10px'}}>
        <AlertBS
            variant={type}
            dismissible
            onClose={this.hideMessage}>
          <AlertBS.Heading>{this.props.heading}</AlertBS.Heading>
          <div>{this.props.message}</div>
        </AlertBS>
      </div>
    )
  }
}


FlashNotification.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  heading: PropTypes.string,
  type: PropTypes.string,
}
export default FlashNotification;
