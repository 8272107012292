// ZipCodeInput
//
//  desc:
//    provides a title and a zip code input box (with optional placeholder text)          
//
//  props:
//    title:                (optional) if provided, will display title above the input box
//    placeholderText:      (optional) if provided, will display ghosted placeholder text in the input box. Note: there is no placeholder text by default
//    zipCode:              (optional) if provided, will set the the zipcode text for the input box (will be marked out with ***)
//    inError:              if 'true', will display the zipcode input in red 
//    hoverText:            (optional) text to display to the user when the mouse cursor hovers over the input box. By default, there is none
//    onTextInputCallback:  a callback function for when the user has entered input
//

import React from 'react';
import {Change, Validation} from '../Utility';
import { withFormsy } from 'formsy-react';
import './index.css'


export const allowed_zip_pattern = function() {
  return /^\s*?\d{5}(?:[-\s]\d{4,})?\s*?$/;
}

export const validateZipcode = function(zipcode) {
  var re = allowed_zip_pattern();
  return zipcode !== undefined && re.test(zipcode);
}

export const validateZipcodeOutOfCountry = function(zipcode) {
  return /^[a-zA-Z0-9\- ]+$/.test(zipcode);
}

class ZipCodeInput extends React.Component
{
 constructor(props)
  {
    super(props)
    this.state = {};
    this.onTextInput = this.onTextInput.bind(this)
    this.validateZipCode = this.validateZipCode.bind(this)
  }
  
  componentWillUnmount() {
    if (this.props.onTextInputCallback) {
      this.props.onTextInputCallback(this.props.getValue());
    }
  }


  getPlaceholderText()
  { //defaults to empty string if no prop provided
    return this.props.placeholderText != null ? this.props.placeholderText : ""
  }


  getReasonText()
  { //defaults to empty string if no prop provided
    return this.props.reasonText != null ? this.props.reasonText : ""
  }


  getHoverText()
  { //defaults to empty string if no prop provided
    return this.props.hoverText != null ? this.props.hoverText : ""
  }

  getZipCode()
  { //defaults to 25 if no prop provided
    return this.props.zipCode != null ? this.props.zipCode : ""
  }


  getInputClasses()
  {
    var retClass = 'zipCodeInputBox';
    if ( !this.props.isValid || (this.props.inError != null && this.props.inError == true) )
    {
      retClass += ' zipCodeInput_zipCodeError'
    }
    return retClass;
  }
  

  onTextInput(event)
  {
    let inputText = event.target.value;
    this.props.setValue(inputText);
  }


  validateZipCode(event)
  {
    const errorMessage = this.props.getErrorMessage();
    this.setState({
      errorMessage: errorMessage,
    });   
    // look into this.props.showError()
    if (this.props.isValid()) {
      event.currentTarget.setCustomValidity('');
    } else {
      event.currentTarget.setCustomValidity(errorMessage);
    }
  }

  showErrorMessage() {
    return this.state.errorMessage ? 
      <span className='field-error'>({ this.state.errorMessage })</span> :
      null;
  }

  render() {
    const zipcode = this.props.getValue() || ''; // zipCode || '';
    const requiredColor = this.props.isValid() ? null : { color: 'red' }
    const requiredMark = this.props.required ? <span className='required-mark' style={ requiredColor }>*</span> : null;
    return (
      <div className='zipCodeInput'>
        <div className='zipCodeInputTitle'>
           <label htmlFor='zipcode'>{this.props.title}</label>
           {requiredMark}
           {this.showErrorMessage()}
        </div>
        <input id="zipcode"
                //className={this.getInputClasses()} 
                value={ zipcode }
                title={this.getHoverText()}
                onChange={this.onTextInput}
                onBlur={this.validateZipCode}
                placeholder={this.getPlaceholderText()}
                type='text'
                maxLength={ this.props.maxLength }
                //pattern={this.props.pattern}
                //required
        />
     </div>
     );
  }
}

export default withFormsy(ZipCodeInput);
