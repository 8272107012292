// DiscretionaryData
//
//  desc:
//    UI for discretionary data based on the json format produced by the legacy system.        
//
//
//  props:
//    discDataArray:                the json data array for the discretionary data 
//    onDiscretionaryDataChange:    callback handler for change in discretionary data 
//
//  Example JSON of single discretionary data element (as expected from Legacy system)
// {
//     "id": 9274,
//     "title": "Discretionary Data Multiple",
//     "visible": true,
//     "required": false,
//     "input": "multiselect",
//     "stored_value": "" 
//     "options": [
//         {
//             "id": "11199",
//             "display": "Multiple Option 1",
//             "selected": false
//         },
//         {
//             "id": "11200",
//             "display": "Multiple Option 2",
//             "selected": false
//         },
//         {
//             "id": "11201",
//             "display": "Multiple Option 3",
//             "selected": false
//         }
//     ]
// }
//
//  Values input/selected by user will be stored in the "stored_value" JSON element 
//


import React from 'react';
import TextInputBox from '../../TextInputBox';
import DropDown from '../../DropDown';
import RadioButton from '../../RadioButton';
import MultiRadioInput from '../../RadioButton/MultiRadioInput';
import './index.css';


const DISC_DATA_INPUT_TYPE            = 'text';
const DISC_DATA_SELECT_TYPE           = 'select';
const DISC_DATA_MULTI_SELECT_TYPE     = 'multiselect';

const DISC_SELECT_DEFAULT_PLACEHOLDER = 'choose';

class DiscretionaryData extends React.Component
{
 constructor(props)
  {
    super(props)
    this.state = 
    {
      discDataArray: this.props.discDataArray,
      visitedData: {}                             //entries into this dict will indicate that the corresponding discretionary data has already been visited by the user
    };

    this.setupDropdownSelect = this.setupDropdownSelect.bind(this);
    this.setupMultiselect = this.setupMultiselect.bind(this);
    this.setupInput = this.setupInput.bind(this);
    this.renderDiscretionaryData = this.renderDiscretionaryData.bind(this);
    this.onTextInputChange = this.onTextInputChange.bind(this);
    this.onDropdownChange = this.onDropdownChange.bind(this);
    this.onMultiselectChange = this.onMultiselectChange.bind(this);
    this.addVisitedElement = this.addVisitedElement.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onOptionBlur = this.onOptionBlur.bind(this);
    this.getDiscIndex = this.getDiscIndex.bind(this);
  }

  //checks to see if an element has been visited yet, and if not sets it
  //   Does the check first to avoid a necessary state update / render 
  addVisitedElement(id)
  {
    if (!this.state.visitedData[id])
    {
      const state = Object.assign({}, this.state);
      state.visitedData[id] = true;
      this.setState(state);
    }
  }


  getDiscIndex(targetId)
  {
    let retDiscIndex = -1            //defaults to -1 in case it cant find a valid id
    let discDataArray = this.props.discDataArray

    for (let i = 0; i < discDataArray.length; i++)
    {
      if (discDataArray[i].id == targetId)
      {
        retDiscIndex = i         //set the return variable to the found data
        break;
      }
    }
    return retDiscIndex         //returns -1 to indicate that it did not find the correct data
  }


  onInputBlur(event)
  {
    let target = event.target
    let discIndex = this.getDiscIndex(target.name)
    if (discIndex != -1)
    {
      this.addVisitedElement(discIndex)
    }
  }


  onOptionBlur(event)
  {
    let targetVals = event.target.value.split(" ");
    let discId = targetVals[0];

    let discIndex = this.getDiscIndex(discId)
    if (discIndex != -1)
    {
      this.addVisitedElement(discIndex)
    }

  }


  onTextInputChange(target, value)
  {
    let discIndex = this.getDiscIndex(target.name)


    if (discIndex == -1 || value == undefined)
    { //unable to find a valid disc id, so just return
      return;
    }
    let discDataArray = this.props.discDataArray


    // (COMMENTING OUT) REMOVING VALIDATION FROM THIS CALLBACK. THE DISCRETIONARY FIELDS ALREADY RESTRICT TYPING

    // var isValid = true                    //for now, text input defaults to true 
      
    // let type = discDataArray[discIndex].data_type
    // 
    // if (type == "alpha")
    // { //only alpha characters are allowed 
    //   // var pattern = /^[a-z\s@,-\.]*$/i;
    //   var pattern = /^\D*$/i;
    //   isValid = value.match(pattern) 
    // 
    // }
    // else if (type == "alphanumeric")
    // { //only alphanumeric characters are allowed 
    //   // var pattern = /^[a-z0-9\s@,-\.]*$/i;
    //   var pattern = /^.*$/i;
    //   isValid = value.match(pattern);
    // }
    // else if (type == "numeric")
    // { //only alphanumeric characters are allowed 
    //   // var pattern = /^[0-9]*$/i;
    //   var pattern = /^[+-]?[$]?[.]?[0-9]*[.]?[0-9]*$/i;
    //   isValid = value.match(pattern);
    // }
    // else if (type == "date")
    // { //only numeric and date related characters are allowed 
    //   //TODO: due to the way validation is performed on disc data, date data types should probably be convereted to a date picker in the future 
    //   //   validation for this component is performed at the character level, not at the whole input level
    //   //   whereas the paymentform validates all disc data based on whether there is a stored value or not
    //   //   This makes it tricky to perform validation on a whole input vs the single input validation done currently. 
    //   //   The paymentform counts it as valid data if there is anything in stored_value, whether or not that input is a valid date.
    //   //
    //   // At this time, we are only validating that characters entered are *possible* date charactes. Numbers and "/", "-", and "_"
    //   // var pattern = /^[0-9\-\/\s]*$/i;
    //   var pattern = /^[A-Za-z0-9,./ -]*$/i;
    // 
    //   isValid = value.match(pattern);
    // }

    // if (isValid)
    // { //the input text is valid, so now we can store it 
      discDataArray[discIndex].stored_value = value               

      //update the handler with the modified discrectionary data array
      // note only a shallow copy was done and the handler in parent does not do a deep copy
      this.props.onDiscretionaryDataChange(discDataArray)
    // }

  }


  onDropdownChange(value)
  {
    let targetVals = value.split(" ");
    let discId = targetVals[0];
    let optionId = targetVals[1];

    let discIndex = this.getDiscIndex(discId)
    if (discIndex == -1)
    { //unable to find a valid disc id, so just return
      return;
    }

    //find index of discID in discDataArray
    let discDataArray = this.props.discDataArray
    if (optionId == DISC_SELECT_DEFAULT_PLACEHOLDER)
    { //we have just hit the placeholder selection, so clear any stored values 
      //set optionId as "" so that we reset the stored values
      optionId = ""
    }

    discDataArray[discIndex].stored_value = optionId

    //update the handler with the modified discrectionary data array
    // note only a shallow copy was done and the handler in parent does not do a deep copy
    this.props.onDiscretionaryDataChange(discDataArray)

  }

  onMultiselectChange(value, discId)
  {
    //find index of discID in discDataArray
    let discIndex = this.getDiscIndex(discId)
    if (discIndex == -1)
    { //unable to find a valid disc id, so just return
      return;
    }
    let discDataArray = this.props.discDataArray

    discDataArray[discIndex].stored_value = value;//storedArray

    //update the handler with the modified discrectionary data array
    // note only a shallow copy was done and the handler in parent does not do a deep copy
    this.props.onDiscretionaryDataChange(discDataArray)
  }
  
  // beware the value for options has id value pair with a space in the middle.
  setupDropdownSelect(dropdownData, inError)
  {
    let tempId = "disc." + DISC_DATA_SELECT_TYPE + "_" + dropdownData.id

    let options = []
    options[dropdownData.id + " " + DISC_SELECT_DEFAULT_PLACEHOLDER] = "Select an option"

    let selectedValue = dropdownData.stored_value;
    if (selectedValue == null || selectedValue == "")
    { //set the default option as the currently selected option ....  will not count towards 'required' if selected by user, will need to choose a different option
      selectedValue = DISC_SELECT_DEFAULT_PLACEHOLDER
    }

    dropdownData.options.forEach(function(option)
    {
      let tempKey = dropdownData.id + " " + option.id
      // let tempKey = option.id;
      options[tempKey] = option.display    
    });    

    let title = dropdownData.title
    selectedValue = dropdownData.id + " " + selectedValue;
    return  <div key={tempId}>           
              <DropDown
                id={tempId}
                dataKey={dropdownData.id}
                validations={{
                  validateRequired: function(values, value) {
                    if (dropdownData.required) {
                      return !value.endsWith('choose');
                    } else {
                      return true; //not required.
                    }
                  }
                }}
                validationErrors={{
                  'validateRequired': 'This is required',
                  // "isDefaultRequiredValue":"This field is required",
                }}
                name={tempId}
                label={title}
                options={options}
                onChange={this.onDropdownChange}
                value={selectedValue}
                required={dropdownData.required}
                inError={inError} />
            </div>

  }


  setupMultiselect(multiData, inError)
  {
    let tempId = "disc." + DISC_DATA_MULTI_SELECT_TYPE + "_" + multiData.id
  
    let renderedOptions = []
    multiData.options.forEach(function(option) 
    {
      let selected = false 

      if (multiData.stored_value)
      {
        selected = multiData.stored_value.find(function(val)
        {
          if (option.id == val)
          {
            return true;
          }
          return false; 
        });
      }

      let optionValue = multiData.id + " " + option.id

      let tempOption = <RadioButton key={option.id}
                        validations={{
                          'AnyChecked': () => {
                            return false;
                          }
                        }}
                        value={selected} 
                        id={option.id}
                        name={option.display}
                        tooltipText="Retuning Customer login - Enable"
                        labelText={option.display} 
                        required={multiData.required}
                        style="verticalCheckbox"
                        type="checkbox"
                        selected={optionValue}
                        // onBlur={this.onOptionBlur}
                        onChange={this.onMultiselectChange}               
                      />
      renderedOptions.push(tempOption)
    }, this);

    let styles = "" 
    if ( inError == true ) 
    {
      styles += ' discData_inError'
    }

    let title = multiData.title
    if (multiData.required == true )       
    {
      title += " *" 
    }

    return   <div className={styles} key={tempId} style={{textAlign: 'left' }}>
              <MultiRadioInput
                validations={{
                  validateRequired: function(values, value) {
                    if (multiData.required) {
                      return value != null && value.length > 0;
                    } else {
                      return true;
                    }
                  }
                }}
                validationErrors={{
                  'validateRequired': 'This is required',
                }}
                onChange={this.onMultiselectChange}               
                label={multiData.title}
                name={tempId}
                id={multiData.id}
                value={multiData.stored_value}
                options={multiData.options}
                required={multiData.required}
              />
              {
                //renderedOptions
              } 
            </div>

  }


  setupInput(inputData, inError)
  {
    let tempId = "disc." + DISC_DATA_INPUT_TYPE + "_" + inputData.id
 
    let title = inputData.title
    // if (inputData.required == true )      
    // {
    //   title += " *" 
    // }
    
    let maxLength = null;
    let minLength = null;
    if (inputData.max_length) {
      maxLength = "maxLength:" + inputData.max_length
    }
    if (inputData.min_length) {
      minLength = "minLength:" + inputData.min_length
    }

    return <div key={tempId}>
              <TextInputBox
                id={`disc_${inputData.id}`}
                validations={`${minLength},${maxLength}`}
                validationErrors={
                  {
                    "minLength": "minimum length",
                    "maxLength": "maximum length",
                    "isDefaultRequiredValue":"This field is required"
                  }
                }
                required={inputData.required}
                label={ title }
                data_type={ inputData.data_type }
                  showError={ this.props.showError }
                  // inError={inError}
                  placeholderText=""
                  data_id={inputData.id}
                  name={tempId}
                  maxLength={inputData.max_length}
                  value={inputData.stored_value}
                  textBoxWidth="100%"
                  onChangeCallback={this.onTextInputChange}
                  returnTarget={true}
                  onBlur={this.onInputBlur}
              /> 
            </div>
  } 


  renderDiscretionaryData()
  {
    let renderedData = []
    if (this.props.discDataArray && Array.isArray(this.props.discDataArray))
    {
      this.props.discDataArray.forEach(function(dataElement, index)
      { 
        if (dataElement.visible)
        { //only need to display visible elements 

          var element = null

          //determine if the dataElement is in error or not
          let inError = false
          if (dataElement.required == true && this.state.visitedData[index])      
          { //element is required and has been visited, see that it has a stored_value
            
            let storedValue = dataElement.stored_value;
            
            if (storedValue == null || storedValue.length == 0)
            { //has not meant this min reqs for this data element, so return false to indicate that the discretionary data is not yet valid
              inError = true;
            }
            else if(dataElement.input == DISC_DATA_INPUT_TYPE && storedValue != null && storedValue.length < dataElement.min_length){
              inError = true;
            }
          }
          
          //determine type of discretionary data 
          if (dataElement.input == DISC_DATA_INPUT_TYPE)
          { 
            //add a typical input box element
            element = this.setupInput(dataElement, inError)

          }
          else if (dataElement.input == DISC_DATA_SELECT_TYPE)
          { //add a single select dropdown box

            element = this.setupDropdownSelect(dataElement, inError)

          }
          else if (dataElement.input == DISC_DATA_MULTI_SELECT_TYPE)
          { //add a multiselect box 

            element = this.setupMultiselect(dataElement, inError)

          }
          else
          { //unknown element type 

          }

          //push the element if anything has been added to it
          if (element)
          {
            renderedData.push(element);
          }
        }

      }, this);
    }

    return renderedData
  }


  render()
  {
    return(
      <div>
        {this.renderDiscretionaryData()}
      </div>
    );
  }

}

export default DiscretionaryData;