import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";

class ExceptionHandler extends Component {
  constructor(props){
    super(props);
    this.state = {
      merchantID: sessionStorage.getItem('m')
    }
  }
  
  paymentLink() {
    const pathname = this.props.location.pathname;
    return window.location.href.replace(pathname, "/?m=" + this.state.merchantID);
  }

  errorMessage(props) {
    console.log("erro",props)
    return props.location.state != null ? props.location.state.message : "Exception";
  }

  renderNoMerchant(props) {
    return (
      <div className='alert alert-warning'>
        <h2>Invalid Merchant Number</h2>
        <div>{this.errorMessage(props)}. Please try again.</div>
        <div>Please go to the merchant provided link.</div>
        <div><a href={this.paymentLink()}>{this.paymentLink()}</a></div>
      </div>
    )
  }

  renderNetworkError(props) {
    return (
      <div className='alert alert-warning'>
        <h2>Network Error</h2>
        <div>Could not connect to service. Please try again.</div>
        <div>{this.errorMessage(props)}. Please try again.</div>
        <div><a href={this.paymentLink()}>{this.paymentLink()}</a></div>
      </div>
    )
  }
  
  renderTimeout(props) {
    return (
      <div className='alert alert-warning'>
        <h2>Timeout Error</h2>
        <div>{this.errorMessage(props)}. Please try again.</div>
        <div><a href={this.paymentLink()}>{this.paymentLink()}</a></div>
      </div>
    )
  }
  
  renderError(props) {
    return (
      <div className='alert alert-warning'>
        <h2>Error</h2>
        <div>Please contact administrator or</div>
        <div>{this.errorMessage(props)}. Please try again.</div>
        <div><a href={this.paymentLink()}>{this.paymentLink()}</a></div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <Route path="/Exception/NoMerchant" render={(props) => (
          this.renderNoMerchant(props)
        )} />
        <Route path="/Exception/NetworkError" render={(props) => (
          this.renderNetworkError(props)
        )} />
        <Route path="/Exception/Timeout" render={(props) => (
          this.renderTimeout(props)
        )} />
        <Route exact path="/Exception" render={(props) => (
          this.renderError(props)
        )} />
      </div>
    );
  }
}

export default ExceptionHandler;
