// HeaderLabel
//
//  desc:
//    header UI for sections
//
//  props:
//    text:             (optional) text or value of the control.
//    size:             (optional) font size of text
//    {chidren}         (optional)
//

import React, { Component } from 'react';
import './header_label.css';

export default class HeaderLabel extends Component {
  constructor(props){
    super(props);
  }

  render() {
    const text = this.props.label.text || null;
    const textSize = this.props.label.size || null;
    return (

        <div className="header-label">
          {this.props.children}
          <span className="label" style={{fontSize: textSize}}>{text}</span>
        </div>

    );
  }
}
