import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';

// NOTE: Getting things to work in IE11. Specifically IE was blowing up with 
// Object method "find" undefined. Not sure specifically where that is
// being called because it seems like it is anonymous function being evaluated
// somewhere in a external library. I believe though the call is were we parse
// the query parameters in the component did load method in the PaymentLink Component.
// Babel polyfill fixes other things specific to IE too so the other polyfill 
// libraries that were added earlier to fix other IE
// might be redundant but instead of trying to figure it out just
// noting it here. To much trouble to figure it out.

// https://github.com/babel/babel-preset-env#usebuiltins
// Warning! Only use require("babel-polyfill"); 
// once in your whole app. Multiple imports or requires of 
// babel-polyfill will throw an error since it can cause global collisions.
import 'babel-polyfill';

ReactDOM.render(<App />, document.getElementById('comus_root'));
