import React, { Component } from 'react';
import { withFormsy } from 'formsy-react';


// really not sure why we cant use PureComponents
class SpecialInstructionInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onChange = this.onChange.bind(this);
  }
  
  componentWillUnmount() {
    if (this.props.onChange) {
     this.props.onChange(this.props.getValue());
    }
  }

  onChange(event) {
    this.props.setValue(event.currentTarget.value);
  }

  render() {
    return (
      <div>
        <div>
          <label htmlFor={this.props.id}>{this.props.label}</label>
        </div>
        <textarea value={ this.props.getValue() || '' }
          col="50"
          row="5"
          maxLength="250"
          name={this.props.name}
          id={this.props.id}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

export default withFormsy(SpecialInstructionInput);
