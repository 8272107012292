// ContactFields
//
//  desc:
//    groups of fields related to contact info on payment form
//
//  props:
//    formdata:      (required) the form data
//      zipcode:        (optional) data
//      state:          (optional) data
//      phone:          (optional) data
//      country:        (optional) data
//      city:           (optional) data
//      email:          (optional) data
//      address:        (optional) data

//    onDataChange:   (optional) a callback function to update data model
//    onValidate:     (optional) a callback function to validate data model
//

import React, { Component } from 'react';
import Country from '../../Country';
import Address from '../../Address';
import ZipCodeInput, {
  validateZipcode,
  validateZipcodeOutOfCountry,
} from '../../ZipCodeInput';
import DynamicFormInput from "../../DynamicFormInput";
import FormInput from '../../FormInput';
import SectionText from '../../SectionText';
import { PropTypes } from 'prop-types';


class ContactFields extends Component {
  constructor(props){
    super(props);
    this.state = {
      validateAddressVisited: false,
      validateZipcodeVisited: false,
      validateCityVisited: false,
      validateStateVisited: false,
      validateEmailVisited: false,
    }

    this.onBillingCountryChange = this.onBillingCountryChange.bind(this);
    this.onBillingAddressChange = this.onBillingAddressChange.bind(this);
    this.onBillingZipcodeChange = this.onBillingZipcodeChange.bind(this);
    this.onBillingCityChange = this.onBillingCityChange.bind(this);
    this.onBillingStateChange = this.onBillingStateChange.bind(this);
    this.onBillingPhoneChange = this.onBillingPhoneChange.bind(this);
    this.onBillingEmailChange = this.onBillingEmailChange.bind(this);
    
    this.onValidateAddress = this.onValidateAddress.bind(this);
    this.onValidateZipcode = this.onValidateZipcode.bind(this);
    this.onValidateCity = this.onValidateCity.bind(this);
    this.onValidateState = this.onValidateState.bind(this);
    this.onValidateEmail = this.onValidateEmail.bind(this);
  }
  
  onBillingCountryChange(value) {
    if (this.props.onBillingCountryChange) {
      this.props.onBillingCountryChange(value);
    }
  }
  
  onBillingAddressChange(address) {
    if (this.props.onBillingAddressChange) {
      this.props.onBillingAddressChange(address);
    }
  }

  onBillingZipcodeChange(value) {
    if (this.props.onBillingZipcodeChange) {
      this.props.onBillingZipcodeChange(value);
    }
  }

  onBillingCityChange(value) {
    if (this.props.onBillingCityChange) {
      this.props.onBillingCityChange(value);
    }
  }
  
  onBillingStateChange(value) {
    if (this.props.onBillingStateChange) {
      this.props.onBillingStateChange(value);
    }
  }

  onBillingPhoneChange(phone) {
    if (this.props.onBillingPhoneChange) {
      this.props.onBillingPhoneChange(phone);
    }
  }
  
  onBillingEmailChange(value) {
    if (this.props.onBillingEmailChange) {
      this.props.onBillingEmailChange(value);
    }
  }
  
  // just for UI purposes to set invalid state on blur
  onValidateAddress(evt) {
    this.setState({
      validateAddressVisited: true
    });
  }
  
  onValidateZipcode(evt) {
    this.setState({
      validateZipcodeVisited: true
    });
  }
  
  onValidateCity(evt) {
    this.setState({
      validateCityVisited: true
    });
  }
  
  onValidateState(evt) {
    this.setState({
      validateStateVisited: true
    });
  }
  
  onValidateEmail(evt) {
    this.setState({
      validateEmailVisited: true
    });
  }

  render() {
    const phoneSubText = 'We will only contact you if there are issues with your payment';
    const addressSubText = 'We send a verification email with your receipt after your payment is approved';

    const zipcode = this.props.formdata.zipcode;
    const state = this.props.formdata.state;
    const phone = this.props.formdata.phone;
    const country = this.props.formdata.country;
    const city = this.props.formdata.city;

    const paymentMethodAch = this.props.formdata.paymentMethod === "ACH";
    const hasTraceACH = this.props.formdata.hasTraceACH;
    const traceACHSelected = paymentMethodAch && hasTraceACH 

    const addresses = [];
    // Need to think about if we are going to ever pull over addresses for the customer
    // and if they have 2 addresses and is an array how we will convert the array in the mapping
    // in the paymentlink component. for now just commenting it out because to soon to deal with it.
    if (this.props.formdata.address !== undefined) {
      this.props.formdata.address.map((street) => { 
        addresses.push( {value:street, placeholder: 'Enter Address'} );
      });
    }
    const isBillingContactCityValid = true;//validation.valid('BillingContactCity');
    const isBillingContactStateValid = true;//validation.valid('BillingContactState');
    const isBillingContactPhoneValid = true;//validation.valid('BillingContactPhone');
    const isBillingContactEmailValid = true;//validation.valid('BillingContactEmail');
    return (
      <div className='contact-fields'>

        <div>
          <Address
                name='address'
                validations={{
                  validateRequired: function(values, value) {
                    return Array.isArray(value) && value[0] != '';
                  }
                }}
                validationErrors={{
                  'validateRequired': 'Address required'
                }}
                required={true}
                maxLength={50}
                //onValidate={ this.onValidateAddress }
                //isValid={ this.props.validateAddress || !this.state.validateAddressVisited }
                value={ this.props.formdata.address || [] }
                id="address"
                label='Address'
                onChange={ this.onBillingAddressChange } />
        </div>

        <div className='side-by-side'>
          <div className='control'>
            <FormInput
              validations='minLength:1'
              validationErrors={ {
                'minLength': 'Invalid value',
                "isDefaultRequiredValue":"This field is required"
              } }
              required
              maxLength={50}
              name='city'
              id="city"
              onChange={ this.onBillingCityChange }
              value={ city || '' }
              //onBlur={ this.onValidateCity }
              //isValid={ this.props.validateCity || !this.state.validateCityVisited }>
              label='City'
            />
          </div>

          <div className='control'>
            <DynamicFormInput
              validations='minLength:2,maxLength:2'
              validationErrors={ {
                'maxLength': 'Invalid state (Enter 2 letter abbreviation)',
                'minLength': 'Invalid state (Enter 2 letter abbreviation)',
                "isDefaultRequiredValue":"This field is required"
              } }
              id="state"
              name='state'
              required
              onChange={ this.onBillingStateChange }
              value={ state || '' }
              //onBlur={ this.onValidateState }
              maxLength="2"
              placeholder="Two Letter State Abbreviation"
              label='State'
            />
          </div>
        </div>

        <ZipCodeInput
          name='zip'
          validations={ {
            'isValidZip': function(values, value) {
              if (values.country && values.country.toUpperCase() == 'US') {
                return validateZipcode(value);
              } else {
                return validateZipcodeOutOfCountry(value);
              }
            }.bind(this)
          } }
          validationErrors={{
            'isValidZip': 'Invalid zip code',
            'isDefaultRequiredValue': 'This field is required'
          }}
          required
          maxLength={9}
              id="zip"
              title="Zip Code"
              //isValid={ this.props.validateZipcode || !this.state.validateZipcodeVisited }
              //onValidate={ this.onValidateZipcode }
              onTextInputCallback={ this.onBillingZipcodeChange }
              hoverText="Numbers only please and no more than 10"
              //zipCode={ zipcode }
              value={ zipcode }
        />

        <Country
          id='country'
          name='country'
          validations="minLength:1"
          validationErrors={ {
            "isDefaultRequiredValue":"This field is required"
          } }
          disabled = {traceACHSelected}
          required
          innerRef={c=>{this.countryInput = c}}
          onChange={this.onBillingCountryChange}
          key={traceACHSelected ? 'reset-country' : 'country'}
          value={traceACHSelected ? 'us' :  country || 'us'}
        />

        <FormInput
          id="phone"
          maxLength={19}
          name='phone'
              value={ phone }
              onChange={ this.onBillingPhoneChange }
              inputType='tel'
              label={ <SectionText title='Phone Number' content={phoneSubText} /> }
          //isValid={ isBillingContactPhoneValid }>
        />
      </div>
    );
  }
}

ContactFields.propTypes = {
  formdata: PropTypes.object.isRequired,
}
export default ContactFields;
