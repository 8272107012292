// transform and map data to customer profile component
// used in the update profile component because the request data schema is different
// for updating ach fields.
const CustomerBillingContactFields = function(data) {

  const addresses = [''];
  let city = null;
  let country = 'us';
  let name = null;
  let state = null;
  let zipcode = null;
  let email = null;
  let phone = null;

  if (data.customer) {
    addresses[0] = data.customer.BADDRESS;
    addresses.push(data.customer.BADDRESS2);
    city = data.customer.BCITY || '';
    country = data.customer.BCOUNTRY || 'us';
    name = data.customer.BNAME || '';
    state = data.customer.BSTATE || '';
    // note that the form data the element is named zip but we send it in request as zipcode.
    zipcode = data.customer.BZIP || '';
    email = data.customer.EMAIL || '';
    phone = data.customer.PHONE || '';
  }
  

  return {
    address: addresses,
    city: city,
    country: country.toLowerCase(),
    name: name,
    state: state,
    zipcode: zipcode,
    email: email,
    phone: phone,
  }
}


export default CustomerBillingContactFields;