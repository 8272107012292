// SectionText
//
//  desc:
//    section text component
//
//  props:
//    title:          (optional) the header
//    content:        (optional) the content
//

import React, { Component } from 'react';
import './section_text.css';

class SectionText extends Component {
  constructor(props)
  {
    super(props);
  }

  render() {
    const title = this.props.title;
    const content = this.props.content;
    return (
      <div className="SectionText">
        <div className="title">{title}</div>
        <div className="content">
          {content}
        </div>
      </div>
    );
  }
}

export default SectionText;
