import React, { Component } from 'react';
import classNames from 'classnames';

import './round_checkbox.css';


class RoundCheckbox extends Component {
  constructor(props)
  {
    super(props);
  }
  
  getColor() {
    return ""
  }

  render() {
    const label = this.props.label;
    const selected = this.props.visited;
    const active = this.props.active;
    
    const foreground = (active) ? this.props.color : "";
    const border = (selected || active) ? this.props.color : "";
    const background = (selected) ? this.props.color : "";
    return (
      <div className="RoundCheckbox">
        <div>
          <label style={{color: foreground}} className={classNames({ active: active })}>{label}</label>
        </div>
        <div>
          <label style={{borderColor: border, backgroundColor: background}} className={classNames({ checkmark: true, selected: selected, active: active })}></label>
        </div>
      </div>
    );
  }
}

export default RoundCheckbox;
