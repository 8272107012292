// TextInputBox
//
//  desc:
//    an inputb box with callback handlers, styling, placeholder text, etc         
//
//
//  props:
//    value:            text be displayed in the input textbox (not placeholder text!)
//    placeholderText:  placeholder text that is displayed in lieu of actual text being available
//    name:             name of the input text box
//    maxLength:        max amount of characters that can be entered into the input text box   (default value of 128)
//    textBoxWidth:     sets the width of the textbox
//    inError:          (optional) if true indicates that the value of the textinput box is in error (an error css class will be attached to the input)
//    children:         any child components that should be displayed along with the inputbox
//    onChangeCallback: the callback handler to be called from an onchange event
//    returnTarget:     (optional) if provided, will return the target, and not just the value, to the callback function
//    onBlur:           (optional) a callback function for handling change of focus


import React, { Component } from 'react';
import { withFormsy } from 'formsy-react';
import './TextInputBox.css';


/*
Note: if this component is reused in other places note that passing 
in the property for type (alpha, aphanumeric, date, numeric) will result in
an opinionated restriction of allowed characters to be typed into the field
*/
class TextInputBox extends React.PureComponent {
  constructor(props)
  {
    super(props);
    this.state = { 
      value: this.props.value 
    }
    this.handleChange = this.handleChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  onBlur(event) {
    // This is implemented in all formsy fields and just displays the UI error.
    // todo put this in common place
    const errorMessage = this.props.getErrorMessage();
    this.setState({
      errorMessage: errorMessage,
    });   
    if (this.props.isValid()) {
      event.currentTarget.setCustomValidity('');
    } else {
      event.currentTarget.setCustomValidity(errorMessage);
    }
  }
  
  handleChange(event) 
  {
    let isValid = true;
    const type = this.props.data_type;
    const value = event.currentTarget.value;
    if (type == "alpha")
    { //only alpha characters are allowed 
      var pattern = /^[a-z\s@,-\.&]*$/i;
      //var pattern = /^\D*$/i; // this is what metis is allowing but we do not sanitize disc in leviathan
      isValid = value.match(pattern) 

    }
    else if (type == "alphanumeric")
    { //only alphanumeric characters are allowed 
      var pattern = /^[a-zA-Z0-9\s@,_!#$%.+=&]*[-]?[a-zA-Z0-9\s@,_!#$%.+=&]*$/i;  
      // allowing one - to be used 
      // var pattern =; // this is what metis is allowing but we do not sanitize disc in leviathan
      isValid = value.match(pattern);
    }
    else if (type == "numeric")
    { //only alphanumeric characters are allowed 
      // var pattern = /^[0-9]*$/i;
      var pattern = /^[+-]?[$]?[.]?[0-9]*[.,]?[0-9]*$/i;
      isValid = value.match(pattern);
    }
    else if (type == "date")
    { //only numeric and date related characters are allowed 
      //TODO: due to the way validation is performed on disc data, date data types should probably be convereted to a date picker in the future 
      //   validation for this component is performed at the character level, not at the whole input level
      //   whereas the paymentform validates all disc data based on whether there is a stored value or not
      //   This makes it tricky to perform validation on a whole input vs the single input validation done currently. 
      //   The paymentform counts it as valid data if there is anything in stored_value, whether or not that input is a valid date.
      //
      // At this time, we are only validating that characters entered are *possible* date charactes. Numbers and "/", "-", and "_"
      var pattern = /^[0-9\-\/\s]*$/i;
      // var pattern = /^[A-Za-z0-9,./ -]*$/i; //metis regular expression
      isValid = value.match(pattern);
    }

    if (isValid)
    { 
      // required. sets the value stored in formsy field. State for the entire 
      // form model is still located top level component in payment link which is
      // updated when this component unmounts. Not great but its because how the app
      // is implemented.
      this.props.setValue(value);
    }
  }
  
  componentWillUnmount() {
    if (this.props.onChangeCallback)
    {
      if (this.props.returnTarget)
      { //the target was requested as well as the value for the callback
        const target = {name: this.props.data_id};
        this.props.onChangeCallback(target, this.props.getValue());
      }
      else
      {
        this.props.onChangeCallback(this.props.getValue());
      }
    }
  }

  getInputClasses()
  {
    var retClass = 'TextInputBox_input';
    if ( this.props.inError != null && this.props.inError == true ) 
    {
      retClass += ' TextInputBox_inputError'
    }
    return retClass;
  }

  // Method in all field components with validation. Part of the 
  // confusing hacky feature to show validation errors when button 
  // click fires from top level Component level event also shows 
  // error message from on blur event.
  // todo put this in a common place.
  showErrorMessage() {
    let error = null;
    if (this.props.showError && !this.props.isValid()) {
      return "(" + this.props.getErrorMessage() + ")";
    } else if (this.state.errorMessage) {
      return "(" + this.state.errorMessage + ")";
    } else {
      return null;
    }
  }

  render() {
    const pText = this.props.placeholderText != null ? this.props.placeholderText : "" ;
    const tWidth = this.props.textBoxWidth != null ? this.props.textBoxWidth : "50%" ;
    const maxLength = this.props.maxLength ? this.props.maxLength : 128;                  //default the max length to 128 if no maxlength is available 
    const value = this.props.getValue() || ''; //this.props.value ? this.props.value : "";
    const requiredColor = this.props.isValid() ? null : { color: 'red' }
    const requiredMark = this.props.required ? <span className='required-mark' style={ requiredColor }>*</span> : null;
    return (
      <div className="TextInputBox">
        <div>
          <label htmlFor={this.props.id}>
            {this.props.label} 
            {requiredMark}
            <span className='field-error'>{ this.showErrorMessage() }</span>
          </label>
        </div>
        <input style ={ { width: tWidth } }
              id={this.props.id}
              data-disc-type={`disc_text_${this.props.data_type}`}
              className={this.getInputClasses()}               
              type="text"
              value={value}
              placeholder={pText}
              onChange={this.handleChange}
              name={this.props.name}
              maxLength={maxLength}
              onBlur={this.onBlur}
        />
        
      </div>
    );
  }
}

export default withFormsy(TextInputBox);
