// RadioButton
//
//  desc:
//    radio button
//
//  props:
//    value:          (optional) value for radio box
//    placeholder:    (optional) if provided, will display ghosted placeholder text in the input box. Note: there is no placeholder text by default
//    tooltip:        (optional) tooltip
//    selected:       (optional) is radio box checked 
//    id:             (optional) used to update the model
//    onChange:       (optional) a callback function for when the user has entered input
//    style:          (optional) style classes that can be added to the radio button
//    type:           (optional) default type is "radio".  Can be changed to "checkbox".  This affects how the onchange handler is notified 
//    onBlur:         (optional) a callback function for handling change of focus
//    inError:          (optional) if true indicates that the value of the textinput box is in error (an error css class will be attached to the input)

import React, { Component } from 'react';
import MultipleRadioInput from './MultiRadioInput';
import { withFormsy } from 'formsy-react';
import './RadioButton.css';

// TODO refactor AmountInputBox and DatePicker to use this component
class RadioButton extends Component {
  constructor(props){
    super(props);
    this.state = {};
    this.onChange = this.onChange.bind(this);
  }

  componentWillUnmount() {
    if (this.props.onChange) {
      this.props.onChange(this.props.id);
    }
  }

  onChange(event)
  {
    if (event.currentTarget.checked) {
      this.props.setValue(event.currentTarget.id);
    } else {
      this.props.setValue(null);
    }
  }


  getStyleClasses()
  {
    var retClass = 'radio-button'
    

   //For radio button as checkbox apperence
    if(this.props.style == "checkbox")
    {
      retClass += " checkbox-Apperence";
    }
    else if (this.props.style == "verticalCheckbox")
    {
      retClass = "verticalCheckbox checkbox-Apperence";
    }

    if ( this.props.inError != null && this.props.inError == true ) 
    {
      retClass += ' radio-button_inputError'
    }

    return retClass;
  }

  
  render() {
    const placeholder = this.props.placeholder
    const value = this.props.getValue();
    const tooltip = this.props.tooltipText;
    const selected = this.props.getValue() ? true : false;
    const type = this.props.type ? this.props.type : "radio"
    const id = this.props.id;
    // const name = this.props.name;
    let styleClassName = this.getStyleClasses();
    return (
      <div className={styleClassName}>
        <label>
          <input 
              id={id}
              // name={name}
              type={type}      
              value={value}
              title={tooltip}
              checked={selected}
              onBlur={this.onBlur}
              onChange={this.onChange}
          /> {this.props.labelText}
        </label>
      </div>
    );
  }
}

export default withFormsy(RadioButton);
