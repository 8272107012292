// PaymentSteps
//
//  desc:
//    that breadcrumbs widget for navigating thru steps
//
//  props:
//    initial:  (required) the initial step to set too.
//    steps:    (required) availabe steps in array
//    color:    (optional) color styling
//

/*
* PaymentSteps
*
* should be renamed to breadcrumbs
*/
import React, { Component } from 'react';
import RoundCheckbox from '../RoundCheckbox';
import './payment_steps.css';
import {DEFAULT_PRIMARY, DEFAULT_SECONDARY} from '../DefaultProperties';

// using pure components here because each step has state
// and we don't want to rerender every step when something changes.
// for just a few steps the performance difference is not very noticeable.
class Step extends React.PureComponent {
  constructor(props)
  {
    super(props);
  }

  render() {
    const { label, visited, active } = this.props;
    // console.log(`RENDER STEP list item :: ${label}`);
    return (
      <li className=''>
        <RoundCheckbox color={this.props.color} label={label} visited={visited} active={active}/>
      </li>
    )
  }
}


class PaymentSteps extends Component {
  constructor(props) {
    super(props);
  }

  findIndex(arr, val) {
    for (var i = 0, j = arr.length; i < j; i++) {
      if (arr[i] === val) { 
        return i;
      }
    }
    return -1;
  }
  
  getColor(){
    let lColor = this.props.color.toLowerCase();
    let lPrimaryColor = this.props.primaryColor.toLowerCase();
    
    if(lColor == "#ffffff"  && lPrimaryColor == "#ffffff"){
      return DEFAULT_SECONDARY
    }
    
    if(lColor == "#ffffff"){
      return lPrimaryColor
    } 
    
    return lColor 

  }
  
  createSteps() {
    const items = [];
    const path = this.props.path.replace('/','');
    const idx = this.findIndex(this.props.steps, path);
    
    // MM-194 - For first step-Active value, hard coded value as "PAYMENT" to match with path 
    // value as steps[0] can be a different value if custom title is available.
    
    return (
      <div>
        <Step 
          color={this.getColor()} 
          label={this.props.steps[0]} 
          visited={idx > 0} 
          active={path.toUpperCase() === "PAYMENT"} />
        <Step 
          color={this.getColor()} 
          label={this.props.steps[1]} 
          visited={idx > 1} 
          active={path == this.props.steps[1]} />
        <Step 
          color={this.getColor()} 
          label={this.props.steps[2]} 
          visited={idx > 2} 
          active={path == this.props.steps[2]} />
        <Step 
          color={this.getColor()} 
          label={this.props.steps[3]} 
          visited={idx > 3} 
          active={path == this.props.steps[3]} />
      </div>
    )
  }
  
  render() {
    // todo no-print not implemented right but ok for now.
    return (
      <div className="PaymentSteps no-print">
        <ul className="horizontalList">
          {this.createSteps()}
        </ul>
      </div>
    );
  }
}

export default PaymentSteps;
