// transform and map data to customer profile component
// used in the update profile component because the request data schema is different
// for updating ach fields.
const CustomerCreditCardFields = function(data) {
  let cc = ''; // cant be null because somewhere down the line (card types) trims it.
  let exp = '';

  return {
    cc: cc,
    exp: exp,
  }
}


export default CustomerCreditCardFields;