import React, { Component } from 'react';
import {FrequencyList} from '../../RecurringPaymentFields';
import './RecurringSummary.css';

class RecurringSummary extends Component {
  constructor(props){
    super(props);
  }
  
  componentWillUnmount() {
    if (this.props.setMessages) {
      this.props.setMessages('');
    }
  }
  
  componentDidMount() {
    const unblock = this.props.history.block('Are you sure you want to leave this page?');
  }

  totalAmount(amt, convFee, surchargeAmt) {
    const totalAmount = (amt + convFee + surchargeAmt).toFixed(2);

    return totalAmount;
  }

  render() {
    const recurring_id = this.props.location.state ? this.props.location.state.recurring_id : null;
    const { amount, convenienceFee, surchargeAmount } = this.props.formdata;
    const amt = Number(amount) || 0;
    const convFee = Number(convenienceFee) || 0;
    const surchargeAmt = Number(surchargeAmount) || 0;

    return (
      <div className='recurring-summary'>
        <table className='table'>
          <thead style={{backgroundColor: 'gainsboro'}}>
            <tr>
              <th colSpan="2">Recurring Payment Created</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Recurring ID</td><td>{ recurring_id }</td>
            </tr>
            <tr>
              <td>Number Of Payments</td><td>{ this.props.formdata.duration == 'continual' ? 'continual' : this.props.formdata.numberOfPayments }</td>
            </tr>
            <tr>
              <td>Method</td><td>{ this.props.formdata.paymentMethod == 'CC' ? 'Credit Card' : 'Check' }</td>
            </tr>
            <tr>
              <td>Pay On</td><td>{ this.props.formdata.payon }</td>
            </tr>
            <tr>
              <td>Frequency</td><td>{ FrequencyList[this.props.formdata.frequency] }</td>
            </tr>

            

            {surchargeAmt ? (
              <React.Fragment>
                <tr>
                  <td>Transaction Amount</td><td>{ amt.toFixed(2) }</td>
                </tr>
                <tr>
                  <td>Surcharge Amount</td><td> { surchargeAmt.toFixed(2) }</td>
                </tr>
                <tr>
                  <td>Total</td><td>{ this.totalAmount(amt, convFee, surchargeAmt) }</td>
                </tr>
              </React.Fragment>
            ) : (
              <tr>
                <td>Amount</td><td>$ { this.totalAmount(amt, convFee, surchargeAmt) }</td>
              </tr>
            )}

          </tbody>
        </table>
      </div>
    );
  }
}

export default RecurringSummary;
