// Review
//
//  desc:
//    review page
//
//  props:
//    IAmNotARobot - handler for recaptcha change
//    data.paymentMethod - to determine to show cc or ach
//    data.checkingAccount - fill review table
//    data.cc - fill review table
//    data.paymentType - fill review table
//    data.payon - fill review table
//    data.amount - fill review table
//    storedCustomer:       contains any storeCustomer info for a logged in user 
//
//

import React, { Component } from 'react';
import PaymentSummary from '../../../PaymentSummary';
import './review.css';
import './review.small.css';
import {GetCardType, CardType, GetCardTypeName} from '../../../CreditCard/CardTypes';


const getAchComplianceText = (merchant_name, merchant_email) => `. In addition, by submitting this transaction, 
the customer authorizes ${merchant_name} to convert this transaction into an 
Electronic Funds Transfer (EFT) via ACH transaction and to debit this bank account 
for the amount specified. Additionally, in the event this EFT is returned unpaid, 
a service fee, as allowable by law, will be charged to this account via EFT or ACH. 
In the event you choose to revoke this authorization, 
please do so by contacting the ${merchant_name} directly at ${merchant_email}. 
Please note that processing times may not allow for revocation of this authorization.`


class Review extends Component {
  constructor(props){
    super(props);
    this.onRecaptchaChange = this.onRecaptchaChange.bind(this);
    this.onAgreementCheckboxChange = this.onAgreementCheckboxChange.bind(this);
    
    const totalAmount = this.props.formdata.amount;
    const payon = this.props.formdata.payon;
  }
  
  componentWillUnmount() {
    if (this.props.setMessages) {
      this.props.setMessages('');
    }
  }
  

  hasStoredInfo(paymentType)
  {
    let hasInfo = false;
    if (this.props.storedCustomer.token)
    { //we have a logged in customer
      //check to see if they have data for the piece in question
      // warning: don't confuse with the naming for sales, auth etc. we submit the field tranxtype for that on the paymentlink module.
      if (paymentType.toLowerCase() == 'cc')
      { //check for credit card info
        if (this.props.storedCustomer.customer.Last4CC && this.props.storedCustomer.customer.ExpMnth && this.props.storedCustomer.customer.ExpYr)
        { //they have the proper credit card info stored in the customer profile
          hasInfo = true;
        }
      }
      else  //currently, the else is if we are checking for ACH stored info 
      {
        if (this.props.storedCustomer.customer.DDA && this.props.storedCustomer.customer.TR)
        { //they have the proper ACH info stored in the customer profile
          hasInfo = true;
        }
      }
    }

    return hasInfo;
  }


  reviewProps() 
  {
    let paymentInfo = "";

    if (this.hasStoredInfo(this.props.formdata.paymentMethod))
    { //the logged in customer has stored info for the chosen payment type, so we want to show data for the correct type
      if (this.props.formdata.paymentMethod.toLowerCase() == 'cc')
      { //get stored info for CC payment type
        let last4 = this.props.storedCustomer.customer.Last4CC                    
        paymentInfo = '****' + last4;
      }
      else
      { //get stored info for ACH type
        let checkingAccount = this.props.storedCustomer.customer.DDA                    
        let routingNumber = this.props.storedCustomer.customer.TR       
        paymentInfo = 'Checking ' + checkingAccount
      }
    }
    else if (this.props.formdata.paymentMethod && this.props.formdata.paymentMethod.toLowerCase() == 'cc')  //dealing with a non-logged in customer or logged in but no stored info for given payment type
    {
      const cc = this.props.formdata.cc;
      const type = GetCardTypeName(cc)
      paymentInfo = `${type} *${cc.substr(cc.length - 4)}`;
    } 
    else 
    {
      const checkNum = this.props.formdata.checkingAccount;
      if (checkNum !== undefined) 
      {
        paymentInfo = `Checking *${checkNum.substr(checkNum.length - 4)}`;
      } 
      else 
      {
        paymentInfo = '';
      }
    }
    return {
      "totalAmount": this.props.formdata.amount,
      "convenienceFee": this.props.formdata.convenienceFee,
      "surchargeAmount": this.props.formdata.surchargeAmount,
      "payon":  this.props.formdata.payon,
      "paymentType": this.props.formdata.tranxType,
      "paymentInfo": paymentInfo,
      "paymentMethod" : this.props.formdata.paymentMethod,
    }
  }
  

  onRecaptchaChange(token) {
    if (this.props.onRecaptchaChange) {
      this.props.onRecaptchaChange(token, true);
    }
  }
  
  onAgreementCheckboxChange(evt) {
    if (this.props.onAgreementCheckboxChange) {
      this.props.onAgreementCheckboxChange(evt.target.checked)
    }
  }

  componentWillUnmount() {
    if (this.props.onAgreementCheckboxChange) {
      this.props.onAgreementCheckboxChange(false)
    }
    if (this.props.onRecaptchaChange) {
      this.props.onRecaptchaChange(null, false);
    }
  }
  
  getTermsLink(){
    let tempTermsLink = this.props.termsLink || '';
    let hasACH = this.props.formdata.hasACH
    let paymentMethodAch = this.props.formdata.paymentMethod === "ACH";
    let merchant_name = this.props.contactInfo.name
    let merchant_email = this.props.contactInfo.email

    const achComplianceText = hasACH && paymentMethodAch ? getAchComplianceText(merchant_name, merchant_email) : '';

    if(tempTermsLink){
      return <label>I agree to the <a href={tempTermsLink} target="_blank">terms and conditions</a>{achComplianceText}</label>
    }  
    else{
      return <label>I agree to the terms and conditions{achComplianceText}</label>
    }
  }

  
  render() {
    const lTermsLink = this.getTermsLink();

    return (
      <div className='review'>
        <PaymentSummary {...this.reviewProps()} />
        
        <div className="termsAgree">
          <input onChange={this.onAgreementCheckboxChange} checked={this.props.formdata.agreeToTermsChecked || ''} type='checkbox' className='checkbox-round'/>
          {lTermsLink}
        </div>
      </div>
    );
  }
}

export default Review;
