import React, { Component } from 'react';
import RecurringPaymentFields from '../components/common/react-component-library/PaymentForm/RecurringPaymentFields';

class ScratchPad extends Component {
  constructor(props){
    super(props);
    this.state = {
      frequency: null,
      duration: "continual",
      numberOfPayments: null,
    }
    this.onFrequencyChange = this.onFrequencyChange.bind(this);
    this.onDurationChange = this.onDurationChange.bind(this);
    this.onNumberOfPaymentsChange = this.onNumberOfPaymentsChange.bind(this);
  }
  
  onFrequencyChange(value) {
    this.setState({
      frequency: value
    })
  }

  onDurationChange(value) {
    this.setState({
      duration: value
    });
  }
  
  onNumberOfPaymentsChange(value) {
    this.setState({
      numberOfPayments: value
    });
  }

  render() {
    return (
      <div>

        <RecurringPaymentFields 
          data={
            {
              frequency: this.state.frequency,
              duration: this.state.duration,
              numberOfPayments: this.state.numberOfPayments,
            }
          }
          onNumberOfPaymentsChange={this.onNumberOfPaymentsChange}
          onFrequencyChange={this.onFrequencyChange}
          onDurationChange={this.onDurationChange}
        />
      </div>
    );
  }
}

export default ScratchPad;
