// AchInput
//
//  desc:
//    inputbox control for ACH processing 
//
//  props:
//    onChange:             (optional) event handler for on change event mainly for updating control state
//    onValidate:           (optional) event handler for validting input field
//    icon:                 (optional) if provided, will display icon in inputbox
//    placeholder:          (optional) if provided, will display ghosted placeholder text in the input box. Note: there is no placeholder text by default
//    width:                (optional) if provided, will set the the width of textbox
//    isValid:              (optional) if 'true', will display the input box outlined in red 
//    value:                (optional) controlled value for input box
//    tooltipText           (optional) tooltip
//    name                  (optional) name attribute of the form input field
//    maxLength             (optional) max length of input
//    id                    (required) required unless name is given. used so input changes can update the data model.

import React, { Component } from 'react';
import {Validation, Change} from '../Utility';

import { withFormsy } from 'formsy-react';
import './AchInput.css';


export const validateCheckingAccount = function(checkingAccount) {
  return /^#*\d+$/.test(checkingAccount) && checkingAccount !== undefined && checkingAccount != '';
}

export const validateRoutingNumber = function(routingNumber) {
  return /^\d{9,}$/.test(routingNumber) && routingNumber !== undefined && routingNumber != '';
}


class AchInput extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {
      inError: false
    }
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }
  
  componentWillUnmount() {
    if (this.props.onChange) {
      this.props.onChange(this.props.getValue());
    }
  }

  onChange(event) {  
    const number = event.currentTarget.value;
    const maxLength = this.props.maxLength;
    let regexStr = '^[0-9]';
    if (maxLength) {
      regexStr += '{0,' + maxLength + '}';
    }
    regexStr += '$'
    if (new RegExp(regexStr).test(number)) {
      this.props.setValue(number);
    }
  }
  
  onBlur(event) {
    const errorMessage = this.props.getErrorMessage();
    this.setState({
      errorMessage: errorMessage,
    });   
    if (this.props.isValid()) {
      event.currentTarget.setCustomValidity('');
    } else {
      event.currentTarget.setCustomValidity(errorMessage);
    }
  }
  
  createIconInside() {
    if (this.props.icon) {
      return (
        <span>
          {this.props.icon}
        </span>
      )
    }
  }
  
  showErrorMessage() {
    return this.state.errorMessage ? 
      <span className='field-error'>({ this.state.errorMessage })</span> :
      null;
  }

  render() {
    const placeholder = this.props.placeholder
    const style = {};
    if (this.props.width) {
      style.width = this.props.width;
    }
    
    if (this.props.isValid !== undefined && !this.props.isValid) {
      style.border = "2px solid red";
    }
    
    const value = this.props.getValue() || '';
    const tooltip = this.props.tooltipText;
    const name = this.props.name;
    const maxLength = this.props.maxLength;
    const id = this.props.id;
    const autoFocus = this.props.autoFocus;

    const requiredColor = this.props.isValid() ? null : { color: 'red' }
    const requiredMark = this.props.required ? <span className='required-mark' style={ requiredColor }>*</span> : null;
    return (
      <div className="AchInput">
        {this.props.label}
        { requiredMark }
        {this.showErrorMessage()}
        {this.createIconInside()}
        <input 
            id={id}
            autoFocus={ autoFocus }
            type="text"
            value={value}
            placeholder={placeholder}
            onChange={this.onChange}
            maxLength={maxLength}
            title = {tooltip}
            onBlur = {this.onBlur}
        />
      </div>

    );
  }
}

export default withFormsy(AchInput);
