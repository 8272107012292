// Utility
//
//  desc:
//    dumping ground of course.


// Utility.js
// utility is becoming a dumping ground like utilities usually do.
// don't put anything else here without moving things out that don't belong here.
import React, { Component } from 'react';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import { DateUtils } from "react-day-picker";


// Note values are not cloned so changes will change
// this object. Todo. get rid of utility.
class EventComponent {
  constructor(props) {
    this.properties = {};
    Object.assign( this.properties, props )
    if (!this.properties.target) {
      throw "Opps! Target Missing!";
    }
  }
  target(){
    return this.properties.target;
  }
  property(){
    return this.properties;
  }
  value(){
    return this.properties.target.value;
  }
  id(){
    return this.properties.target.id;
  }
  name(){
    return this.properties.target.name;
  }
}


export class Change extends EventComponent {
  constructor(props) {
    super(props);
  }
}

//Assumption that function passed in evaluates to true or false
export class Rule {
  constructor(fn) {
    this.fn = fn;
  }
  valid(value) {
    const result = this.fn(value);
    if (typeof result !=  "boolean") {
      throw "Opps! Something Wrong with a Rule!";
    }
    return result;
  }
}


export class Validation extends EventComponent  {
  constructor(props) {
    super(props);
    this.rules = [];
  }
  // todo rules engine? uggg...kinda want to but kinda don't
  _rulesCheck(validity){
    if (!this.truthy(validity.validity)) {
      // no point in checking anything else. we know it bad.
      return false;
    }
    return true;
  }
  
  valid(){
    let isValid = this.properties.target.validity ? 
                    this.properties.target.validity.valid : true;
    for(var i=0; i < this.rules.length; i++){
      isValid = isValid && this.rules[i].valid(this.properties.target.value);
    }
    return isValid;
  }
  
  addRule(fn){
    this.rules.push(fn);
  }
}


export const DateTimeUtility = (function(){
  return {
    now: function(n) {

      var today = new Date();
      var numberOfDaysToAdd = n || 0;
      today.setDate(today.getDate() + numberOfDaysToAdd); 
      return formatDate(today);
    },
    isDate: function(date) {
      // typeof date.getMonth === 'function'
      if (date instanceof Date) {
        return DateUtils.isDate(date);
      } else if (typeof date === 'string' || date instanceof String) {
        return DateUtils.isDate(new Date(date))
      } else {
        return false;
      }
    },
    isDayBeforeToday: function(date) {
      const today = new Date();
      let isAfter = false;

      if (date instanceof Date) {
        isAfter = DateUtils.isDayBefore(date, today);
      } else if (typeof date === 'string' || date instanceof String) {
        const d = new Date(date);
        isAfter = DateUtils.isDayBefore(d, today);
      }
      return isAfter;
    }
  }
})();
